import { Map, List } from 'immutable';

import * as types from 'Elmosfire/actions/action-types';

export const defaultState = Map({
  prefVersion: 0.1,
  firstColumn: List([
    {
      id: 5,
      type: 'Overlays',
      sortOrder: 1,
    },
    {
      id: 1,
      type: 'WordCloud',
      sortOrder: 2,
    },
  ]),
  secondColumn: List([
    {
      id: 1,
      type: 'TSNE',
      sortOrder: 1,
    },
    {
      id: 4,
      type: 'SelectedDocuments',
      sortOrder: 2,
    },
  ]),
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.ADD_WIDGET:
      return state.set(
        action.column,
        state.get(action.column).push(
          Object.assign({}, action.widget, {
            sortOrder: state.get(action.column).size + 1,
          })
        )
      );
    case types.REMOVE_WIDGET:
      return state.set(
        action.column,
        state
          .get(action.column)
          .filter((widget) => widget.sortOrder !== action.sortOrder)
          .map((widget, i) => Object.assign({}, widget, { sortOrder: i + 1 }))
      );
    case types.MOVE_WIDGET_UP:
      return state.set(
        action.column,
        state.get(action.column).map((widget) => {
          if (widget.sortOrder === action.sortOrder) {
            return Object.assign({}, widget, {
              sortOrder: widget.sortOrder - 1,
            });
          } else if (widget.sortOrder === action.sortOrder - 1) {
            return Object.assign({}, widget, {
              sortOrder: widget.sortOrder + 1,
            });
          } else {
            return widget;
          }
        })
      );
    case types.MOVE_WIDGET_DOWN:
      return state.set(
        action.column,
        state.get(action.column).map((widget) => {
          if (widget.sortOrder === action.sortOrder) {
            return Object.assign({}, widget, {
              sortOrder: widget.sortOrder + 1,
            });
          } else if (widget.sortOrder === action.sortOrder + 1) {
            return Object.assign({}, widget, {
              sortOrder: widget.sortOrder - 1,
            });
          } else {
            return widget;
          }
        })
      );
    case types.LOAD_USER_LAYOUT:
      return state
        .set(
          'firstColumn',
          action.layout.firstColumn
            ? List(action.layout.firstColumn)
            : defaultState.get('firstColumn')
        )
        .set(
          'secondColumn',
          action.layout.secondColumn
            ? List(action.layout.secondColumn)
            : defaultState.get('secondColumn')
        );

    default:
      return state;
  }
};

export const getFirstColumnWidgets = (state) => state.get('firstColumn');
export const getSecondColumnWidgets = (state) => state.get('secondColumn');
