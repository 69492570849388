// App
import { toast } from 'root/actions/app-actions';
import { selectTSNEOpenMenu } from 'root/reducers/index-reducer';

// Elmosfire
import * as types from 'Elmosfire/actions/action-types';
import { getTSNE } from 'Elmosfire/providers/tsne-provider';
import * as TSNE_STATUS from 'Elmosfire/constants/tsne-status';

/**** tSne Status Polling and Data Fetching  Actions****/

export const clearTSNEDisplay = () => ({ type: types.CLEAR_TSNE_CLUSTER });

export const pollTSNEReady = (datasetId, clusterId) => (dispatch) => {
  dispatch(cancelTSNEReadyPolling());
  dispatch({ type: types.GET_TSNE_READY_POLL_START, datasetId, clusterId });
};

export const cancelTSNEReadyPolling = () => ({ type: types.GET_TSNE_READY_POLL_STOP });

export const resolveTSNEReady = (status, datasetId, clusterId) => (dispatch) => {
  if (status === TSNE_STATUS.COMPLETE) {
    dispatch(cancelTSNEReadyPolling());
    dispatch(fetchTSNEData(datasetId, clusterId));
  }
  return dispatch({ type: types.GET_TSNE_STATUS, status });
};

export const failTSNEReady = () => (dispatch) => {
  dispatch(cancelTSNEReadyPolling());
  dispatch(toast('There was an error loading the tsne. Please try again.', 'error')); // Notify the user
};

export const fetchTSNEData = (datasetId, clusterId) => (dispatch) => {
  return getTSNE(datasetId, clusterId).then((cluster) => {
    dispatch({
      type: types.GET_TSNE_CLUSTER,
      id: datasetId,
      cluster,
    });
  });
};

/**** tSne UI Actions ****/

export const changeTSNEQuery = (query) => ({ type: types.CHANGE_QUERY, query });

export const resetTSNEColor = () => (dispatch) => {
  dispatch({ type: types.RESET_TSNE_COLOR });
  dispatch({ type: types.CLEAR_TSNE_HIGHLIGHTED });
};

export const changeTSNESelections = (selections = []) => (dispatch) => {
  if (!selections.length) {
    dispatch({ type: types.CLEAR_ITEMS_SELECTED });
  } else {
    dispatch({ type: types.CHANGE_ITEMS_SELECTED, selections });
  }
};

export const toggleTSNELabelMenu = () => (dispatch, getState) => {
  dispatch({ type: types.TOGGLE_TSNE_LABEL_MENU, open: !selectTSNEOpenMenu(getState()) });
};
