import React, { Component } from 'react';
import { COLORS, Card, CardBody } from '@indico-data/permafrost';

import { StyledLoginFailed } from './LoginFailed.styles';

class LoginFailed extends Component {
  render() {
    return (
      <StyledLoginFailed>
        <Card>
          <CardBody>
            <svg
              className="shrug"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xlinkHref="http://www.w3.org/1999/xlink"
              viewBox="0 0 250 66"
              style={{ opacity: '0.5' }}
            >
              <path
                fill={COLORS.iron}
                d="M2.1,4.7C0.7,4.7,0,3.6,0,2.3C0,0.9,0.7,0,2.1,0h15.8c1.4,0,2,0.9,2,2.3c0,1.4-0.6,2.4-2,2.4H2.1z M49.7,62.4
          c0.2,0.4,0.3,0.8,0.3,1.2c0,1-0.7,1.8-1.9,2.2c-0.4,0.1-0.9,0.2-1.2,0.2c-1.1,0-1.9-0.5-2.4-1.6L21.7,3.7c-0.1-0.4-0.2-0.8-0.2-1.1
          c0-1,0.7-1.8,1.9-2.3c0.4-0.1,0.8-0.2,1.1-0.2c1.1,0,2,0.5,2.4,1.7L49.7,62.4z M53.8,66c-1.4,0-2.1-1-2.1-2.4c0-1.4,0.7-2.3,2.1-2.3
          h23.8c1.4,0,2,0.9,2,2.3c0,1.4-0.6,2.4-2,2.4H53.8z M232.1,4.7c-1.4,0-2-1-2-2.4c0-1.4,0.6-2.3,2-2.3l15.8,0c1.4,0,2.1,0.9,2.1,2.3
          c0,1.4-0.7,2.4-2.1,2.4H232.1z M223.1,1.9c0.4-1.2,1.2-1.7,2.4-1.7c0.3,0,0.7,0.1,1.1,0.2c1.2,0.4,1.9,1.2,1.9,2.3
          c0,0.3-0.1,0.7-0.2,1.1l-22.7,60.6c-0.5,1.1-1.3,1.6-2.4,1.6c-0.3,0-0.8-0.1-1.2-0.2c-1.1-0.4-1.9-1.2-1.9-2.2
          c0-0.3,0.1-0.8,0.3-1.2L223.1,1.9z M171.4,66c-1.4,0-2-1-2-2.4c0-1.4,0.6-2.3,2-2.3h24.8c1.4,0,2.1,0.9,2.1,2.3
          c0,1.4-0.7,2.4-2.1,2.4H171.4z M90.8,3.5c0,0.5-0.2,1-0.6,1.6c-6.1,8.2-9.7,16.3-9.7,28.1c0,11.9,3.6,20.1,9.6,28.2
          c0.4,0.6,0.6,1.2,0.6,1.6c0,1-0.6,1.7-1.4,2.3c-0.6,0.5-1.3,0.7-2,0.7c-0.8,0-1.6-0.4-2.3-1.3c-6.9-9.5-10.5-18.6-10.5-31.5
          c0-12.9,3.6-21.9,10.6-31.3c0.7-0.9,1.6-1.3,2.4-1.3c0.7,0,1.3,0.2,1.8,0.6C90.1,1.7,90.8,2.5,90.8,3.5z M101.4,4.9
          c1,0,1.8,0.5,2.4,1.4c2.8,5.2,5.5,12.5,7.2,18.1c0.1,0.5,0.2,0.9,0.2,1.2c0,1.2-0.7,2.1-2,2.5c-0.4,0.1-0.8,0.2-1.2,0.2
          c-1.2,0-2.2-0.8-2.6-2c-1.5-5.3-4.5-12.7-6.8-17.5c-0.2-0.5-0.3-0.9-0.3-1.3c0-1.1,0.6-2,1.8-2.4C100.5,5,100.9,4.9,101.4,4.9z
          M151.3,6.2c0,0.1,0,0.3,0,0.4c-1.1,11.5-5,22.9-10.7,30.8c-6.7,9-17.2,15.9-28.2,18.4c-0.3,0.1-0.7,0.1-1,0.1
          c-1.3,0-2.2-0.6-2.6-2.1c-0.1-0.3-0.1-0.7-0.1-1c0-1.4,0.9-2.3,2.3-2.7c10-2.9,19.2-8.4,25-16.4c5.2-7.1,8.5-17.3,9.5-28
          c0.1-1.8,1.4-2.5,2.8-2.5c0.3,0,0.5,0,0.7,0.1C150.3,3.5,151.3,4.6,151.3,6.2z M119.2,1.5c1.1,0,2.2,0.5,2.8,1.7
          c2.3,5.1,4.9,11.7,6.5,17.5c0.1,0.3,0.1,0.5,0.1,0.8c0,1.4-0.9,2.5-2.2,2.8c-0.3,0.1-0.7,0.1-1.1,0.1c-1.2,0-2.2-0.6-2.6-1.9
          c-1.4-5.5-4.1-12.5-6.3-17.1c-0.2-0.5-0.3-1-0.3-1.4c0-1.1,0.7-1.9,1.9-2.3C118.4,1.5,118.8,1.5,119.2,1.5z M162,0.5
          c0.9,0,1.8,0.4,2.4,1.3c7,9.5,10.7,18.4,10.7,31.3c0,12.9-3.7,22-10.6,31.5c-0.6,0.9-1.4,1.3-2.3,1.3c-0.6,0-1.3-0.3-1.9-0.7
          c-0.8-0.5-1.4-1.4-1.4-2.3c0-0.5,0.2-1.1,0.6-1.6c5.9-8.1,9.6-16.3,9.6-28.2c0-11.8-3.6-19.9-9.7-28.1c-0.4-0.5-0.6-1.1-0.6-1.6
          c0-0.9,0.5-1.7,1.4-2.3C160.7,0.7,161.4,0.5,162,0.5z"
              />
            </svg>
            <p className="t-subhead-large">Looks like our system failed to verify you.</p>
            <button
              className="blue cta"
              onClick={() => {
                window.location.reload();
              }}
            >
              Refresh
            </button>
          </CardBody>
        </Card>
      </StyledLoginFailed>
    );
  }
}

export default LoginFailed;
