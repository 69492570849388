import { Map } from 'immutable';

import * as types from 'Elmosfire/actions/action-types';

export const defaultState = Map({
  datasetID: null,
  name: '',
  multiple: false,
  columnID: null,
  subsetID: null,
  savingModel: false,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.CHANGE_NEW_MODEL_NAME:
      return state.set('name', action.value);

    case types.CHANGE_NEW_MODEL_LABELING_TYPE:
      return state.set('multiple', action.value === 'multiple');

    case types.CHANGE_NEW_MODEL_COLUMN_ID:
      return state.set('columnID', action.value);

    case types.CHANGE_NEW_MODEL_SUBSET_ID:
      return state.set('subsetID', action.value);

    case types.TOGGLE_SAVING_MODEL:
      return state.set('savingModel', !state.get('savingModel'));

    case types.RESET_NEW_MODEL_FORM:
    case types.SAVED_MODEL:
      return state.merge(defaultState);

    default:
      return state;
  }
};

export const getNewModelName = (state) => state.get('name');
export const getNewModelLabelingType = (state) => (state.get('multiple') ? 'multiple' : 'single');
export const getNewModelColumnID = (state) => state.get('columnID');
export const getNewModelSaving = (state) => state.get('savingModel');
