import { Map } from 'immutable';

// Elmosfire
import * as types from 'Elmosfire/actions/action-types';

export const defaultState = Map({
  name: '',
  selectedDataset: '',
  selectedColumn: '',
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.CHANGE_NEW_FIREGROUP_NAME:
      return state.set('name', action.value);

    case types.CHANGE_SELECTED_ELMOSFIRE_DATASET:
      return state
        .set('selectedDataset', action.dataset)
        .set('selectedColumn', defaultState.get('selectedColumn'));

    case types.CHANGE_SELECTED_ELMOSFIRE_COLUMN:
      return state.set('selectedColumn', action.column);

    case types.RESET_NEW_FIREGROUP_FORM:
      return state.merge(defaultState);

    default:
      return state;
  }
};

export const getNewFireGroupName = (state) => state.get('name');
export const getSelectedDataset = (state) => state.get('selectedDataset');
export const getSelectedColumn = (state) => state.get('selectedColumn');
