// App
import { fetchUtils } from '@indico-data/utils';

// Elmosfire
import * as endpoints from 'Elmosfire/constants/endpoints';

/**
 * Post to initiate a tSne build
 * @param {int} datasetID - ID of dataset to build fire from
 * @param {int} labelsetID - ID of the labelset
 * @param {String[]} filters
 * @returns {promise}
 */
export const putInitFire = (fireGroupId, filters = []) => {
  return fetchUtils
    .putElmosfireJSON(`${endpoints.FIRE_GROUPS_ENDPOINT}/${fireGroupId}/fires`, {
      filters: filters,
    })
    .then((json) => {
      return json;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

  /** response:
     {
         "subset_id": 1080,
         "id": 145,
         "updated_by": null,
         "cluster_id": 892,
         "datacolumn_id": 8297,
         "vectorizer_id": 892,
         "featurecolumn_id": 431,
         "created_by": 4028,
         "updated_at": 1528278816.089782,
         "created_at": 1528278816.089782,
         "dataset_id": 1993,
         "filters": "None"
     }
     */
};

export const getFireGroup = (fireGroupId) => {
  return fetchUtils
    .getElmosfireJSON(`${endpoints.FIRE_GROUPS_ENDPOINT}/${fireGroupId}`)
    .then((json) => {
      return json;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

/**
 * Gets the fire parameters for the current dataset and given fire
 * @param {int} datasetID - ID of dataset for target fire
 * @param {int} fireID - ID of the target fire
 * @returns {promise}
 */
export const getFire = (datasetID, fireID) => {
  return fetchUtils
    .getElmosfireJSON(`${endpoints.DATASET_ENDPOINT}/${datasetID}/fires/${fireID}`)
    .then((json) => {
      return json;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

/**
 * Performs a similarity query for a given dataset and fire combination
 * @param {int} datasetID - ID of dataset for target fire
 * @param {int} fireID - ID of the target fire
 * @param {string} query
 * @returns {promise}
 */
export const getFireSimilarity = (datasetID, fireID, query) => {
  return fetchUtils
    .getElmosfireJSON(
      `${
        endpoints.DATASET_ENDPOINT
      }/${datasetID}/fires/${fireID}/similarity?query=${encodeURIComponent(query)}`
    )
    .then((json) => {
      return json;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
