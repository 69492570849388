import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from 'root/reducers/index-reducer';
import rootEpic from 'Elmosfire/epics/index-epic';

import { createEpicMiddleware } from 'redux-observable';

import { envUtils } from '@indico-data/utils';

const LOG_STATE = false;

const loggerMiddleware = (store) => (next) => (action) => {
  if (LOG_STATE) {
    console.log('%cprev-state', 'color: green; font-weight: bold', store.getState()); // eslint-disable-line no-console
  }
  // console.log('%caction', 'color: dodgerblue; font-weight: bold', action); // eslint-disable-line no-console
  const result = next(action);
  window.state = store.getState();
  return result;
};

const epicMiddleware = createEpicMiddleware(rootEpic);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = envUtils.isDevEnv()
  ? compose(composeEnhancers(applyMiddleware(thunk, loggerMiddleware, epicMiddleware)))
  : applyMiddleware(thunk, epicMiddleware);

const configureStore = () => {
  const store = createStore(rootReducer, middleware);

  // if (envUtils.isDevEnv() && module.hot) {
  //   // Enable Webpack hot module replacement for reducers
  //   module.hot.accept('../reducers/index-reducer', () => {
  //     const nextRootReducer = require('../reducers/index-reducer').default;
  //     store.replaceReducer(nextRootReducer);
  //   });
  // }

  return store;
};

export default configureStore;
