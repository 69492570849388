import * as types from 'Auth/actions/action-types';

type State = {
  nameInput: string;
  companyNameInput: string;
  emailInput: string;
  passwordInput: string;
  agreeTerms: boolean;
  agreeSubscribe: boolean;
  loading: boolean;
  registerComplete: boolean;
  blacklistMessage: boolean;
};

const defaultState: State = {
  nameInput: '',
  emailInput: '',
  companyNameInput: '',
  passwordInput: '',
  agreeTerms: false,
  agreeSubscribe: false,
  loading: false,
  registerComplete: false,
  blacklistMessage: false,
};

export default (
  state = defaultState,
  action: { type: keyof typeof types; value: string; message: string }
): State => {
  switch (action.type) {
    case types.CHANGE_REGISTER_NAME:
      return { ...state, nameInput: action.value };

    case types.CHANGE_REGISTER_COMPANY_NAME:
      return { ...state, companyNameInput: action.value };

    case types.SET_BLACKLIST_MESSAGE:
      return { ...state, blacklistMessage: true };

    case types.CHANGE_REGISTER_EMAIL:
      return { ...state, emailInput: action.value };

    case types.CHANGE_REGISTER_PASSWORD:
      return { ...state, passwordInput: action.value };

    case types.TOGGLE_REGISTER_TERMS:
      return { ...state, agreeTerms: !state.agreeTerms };

    case types.TOGGLE_REGISTER_SUBSCRIBE:
      return { ...state, agreeSubscribe: !state.agreeSubscribe };

    case types.ATTEMPT_REGISTER:
      return { ...state, loading: true };

    case types.RESOLVE_REGISTER:
      return {
        ...state,
        ...defaultState,
        registerComplete: true,
        blacklistMessage: state.blacklistMessage,
      };

    case types.REJECT_REGISTER:
      return { ...state, loading: false };

    case types.RESET_REGISTER:
      return { ...state, ...defaultState, blacklistMessage: state.blacklistMessage };

    default:
      return state;
  }
};

export const getNewUserBlacklistMessage = (state: State) => state.blacklistMessage;
export const getNewUserName = (state: State) => state.nameInput;
export const getNewUserCompanyName = (state: State) => state.companyNameInput;
export const getNewUserEmail = (state: State) => state.emailInput;
export const getNewUserPassword = (state: State) => state.passwordInput;
export const getNewUserAgreeTerms = (state: State) => state.agreeTerms;
export const getNewUserAgreeSubscribe = (state: State) => state.agreeSubscribe;
export const getRegistrationLoading = (state: State) => state.loading;
export const getRegistrationComplete = (state: State) => state.registerComplete;
export const getNewUserModel = (state: State) => {
  return {
    name: state.nameInput,
    company: state.companyNameInput, //ignored by BE unless for TrialEdition account
    email: state.emailInput,
    password: state.passwordInput,
    accept_terms: state.agreeTerms ? 'y' : 'n',
    indico_news: state.agreeSubscribe ? 'y' : 'n', //ignored by BE unless for TrialEdition account
  };
};
