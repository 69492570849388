export const INCREMENT_LABELERS = 'INCREMENT_LABELERS';
export const DECREMENT_LABELERS = 'DECREMENT_LABELERS';
export const CHANGE_LABELERS = 'CHANGE_LABELERS';
export const CHANGE_NEW_QUESTIONNAIRE_NAME = 'CHANGE_NEW_QUESTIONNAIRE_NAME';
export const CHANGE_NEW_QUESTIONNAIRE_TYPE = 'CHANGE_NEW_QUESTIONNAIRE_TYPE';
export const CHANGE_QUESTION_NAME = 'CHANGE_QUESTION_NAME';
export const CHANGE_QUESTION_LABELING_TYPE = 'CHANGE_QUESTION_LABELING_TYPE';
export const CHANGE_OTHER_QUESTIONS_LABELING_TYPE = 'CHANGE_OTHER_QUESTIONS_LABELING_TYPE';
export const CHANGE_SELECTED_DATASET = 'CHANGE_SELECTED_DATASET';
export const CHANGE_SELECTED_COLUMN = 'CHANGE_SELECTED_COLUMN';
export const SHOW_CHANGE_FIRST_QUESTION_DIALOG = 'SHOW_CHANGE_FIRST_QUESTION_DIALOG';
export const HIDE_CHANGE_FIRST_QUESTION_DIALOG = 'HIDE_CHANGE_FIRST_QUESTION_DIALOG';
export const ADD_ANOTHER_QUESTION = 'ADD_ANOTHER_QUESTION';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const ADD_CLASSIFICATION_ANSWER = 'ADD_CLASSIFICATION_ANSWER';
export const CHANGE_CLASSIFICATION_ANSWER = 'CHANGE_CLASSIFICATION_ANSWER';
export const DELETE_CLASSIFICATION_ANSWER = 'DELETE_ANSWER';
export const CHANGE_QUESTION_KEYWORDS = 'CHANGE_QUESTION_KEYWORDS';
export const RESET_NEW_QUESTIONNAIRE_FORM = 'RESET_NEW_QUESTIONNAIRE_FORM';

export const GET_QUESTIONNAIRE = 'GET_QUESTIONNAIRE';
export const INCREMENT_USER_LABELED_EXAMPLES = 'INCREMENT_USER_LABELED_EXAMPLES';
export const CHANGE_USER_QUESTION_KEYWORDS_ON_QUESTIONNAIRE =
  'CHANGE_USER_QUESTION_KEYWORDS_ON_QUESTIONNAIRE';
export const UPDATE_QUESTION_WITH_ADDED_LABEL = 'UPDATE_QUESTION_WITH_ADDED_LABEL';

export const ADD_QUEUED_EXAMPLES = 'ADD_QUEUED_EXAMPLES';
export const PURGE_EXAMPLE_FROM_QUEUE = 'PURGE_EXAMPLE_FROM_QUEUE';
export const CLEAR_COMPLETED_QUEUED_EXAMPLES = 'CLEAR_COMPLETED_QUEUED_EXAMPLES';
export const INITIALIZE_WITH_CLASSIFICATION_LABELING = 'INITIALIZE_WITH_CLASSIFICATION_LABELING';
export const INITIALIZE_WITH_EXTRACTION_LABELING = 'INITIALIZE_WITH_EXTRACTION_LABELING';
export const INITIALIZE_WITH_CLASSIFICATION_EXAMPLES = 'INITIALIZE_WITH_CLASSIFICATION_EXAMPLES';
export const INITIALIZE_WITH_EXTRACTION_EXAMPLES = 'INITIALIZE_WITH_EXTRACTION_EXAMPLES';
export const LOAD_QUESTION_WITH_ADDED_LABEL = 'LOAD_QUESTION_WITH_ADDED_LABEL';
export const CHANGE_SELECTED_CLASSIFICATION_LABEL = 'CHANGE_SELECTED_CLASSIFICATION_LABEL';
export const CHANGE_NEW_LABEL = 'CHANGE_NEW_LABEL';
export const TOGGLE_SAVING_NEW_LABEL = 'TOGGLE_SAVING_NEW_LABEL';
export const SAVE_LAST_USED_LABEL = 'SAVE_LAST_USED_LABEL';
export const FIRST_QUESTION = 'FIRST_QUESTION';
export const NEXT_QUESTION = 'NEXT_QUESTION';
export const RESET_EXAMPLES = 'RESET_EXAMPLES';
export const RESET_EXAMPLES_LABELS = 'RESET_EXAMPLES_LABELS';
export const SAVING_EXAMPLES = 'SAVING_EXAMPLES';
export const RECORD_SEEN_EXAMPLES = 'RECORD_SEEN_EXAMPLES';
export const TOGGLE_FETCHING_EXAMPLES = 'TOGGLE_FETCHING_EXAMPLES';
export const LABEL_CM_EXAMPLE = 'LABEL_CM_EXAMPLE';
export const LABEL_CLASSIFICATION_EXAMPLE = 'LABEL_CLASSIFICATION_EXAMPLE';
export const LABEL_EXTRACTION_EXAMPLE = 'LABEL_EXTRACTION_EXAMPLE';
export const ACCEPT_ALL_PREDICTIONS = 'ACCEPT_ALL_PREDICTIONS';
export const ACCEPT_CLASSIFICATION_PREDICTION = 'ACCEPT_CLASSIFICATION_PREDICTION';
export const ACCEPT_CM_PREDICTION = 'ACCEPT_CM_PREDICTION';
export const ACCEPT_EXTRACTION_PREDICTION = 'ACCEPT_EXTRACTION_PREDICTION';
export const ACCEPT_ALL_EXTRACTION_PREDICTIONS = 'ACCEPT_ALL_EXTRACTION_PREDICTIONS';
export const REJECT_PREDICTION = 'REJECT_PREDICTION';
export const REJECT_EXAMPLE = 'REJECT_EXAMPLE';
export const TOGGLE_REJECT_EXAMPLE = 'TOGGLE_REJECT_EXAMPLE';
export const TOGGLE_LABELING_SWATCH = 'TOGGLE_LABELING_SWATCH';
export const CHANGE_USER_QUESTION_KEYWORDS = 'CHANGE_USER_QUESTION_KEYWORDS';
export const LOAD_LABELING_SETTINGS = 'LOAD_LABELING_SETTINGS';
export const SET_EXAMPLES_QUANTITY = 'SET_EXAMPLES_QUANTITY';
export const CHANGE_SELECTED_EXAMPLE_INDEX = 'CHANGE_SELECTED_EXAMPLE_INDEX';
export const TOGGLE_USER_PREDICTIONS_SETTING = 'TOGGLE_USER_PREDICTIONS_SETTING';
export const TOGGLE_USER_TEXT_WRAP_SETTING = 'TOGGLE_USER_TEXT_WRAP_SETTING';
export const TOGGLE_USER_KEYWORDS_SETTING = 'TOGGLE_USER_KEYWORDS_SETTING';
export const CHANGE_USER_TEXT_SIZE_SETTING = 'CHANGE_USER_TEXT_SIZE_SETTING';
export const CHANGE_USER_EXTRACTION_THEME = 'CHANGE_USER_EXTRACTION_THEME';
export const CHANGE_USER_IMAGE_QUANTITY = 'CHANGE_USER_IMAGE_QUANTITY';
export const CHANGE_USER_EXTRACTION_QUANTITY = 'CHANGE_USER_EXTRACTION_QUANTITY';
export const CHANGE_USER_TEXT_QUANTITY = 'CHANGE_USER_TEXT_QUANTITY';
export const PUSH_ACTION_TO_HISTORY = 'PUSH_ACTION_TO_HISTORY';
export const UNDO_ACTION_FROM_HISTORY = 'POP_ACTION_FROM_HISTORY';
export const CLEAR_UNDO_HISTORY = 'CLEAR_UNDO_HISTORY';
export const ADD_NEW_LABEL = 'ADD_NEW_LABEL';
export const REMOVE_NEW_LABEL = 'REMOVE_NEW_LABEL';
export const RECALCULATE_EXAMPLE_LABEL_COLORS = 'RECALCULATE_EXAMPLE_LABEL_COLORS';
export const RESET_NEW_LABELS = 'RESET_NEW_LABELS';
export const TOGGLE_SETTINGS_OPEN = 'TOGGLE_SETTINGS_OPEN';
