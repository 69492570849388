import * as types from 'Auth/actions/action-types';

type State = {
  userEmail: string;
  loading: boolean;
  isComplete: boolean;
};

const defaultState: State = {
  userEmail: '',
  loading: false,
  isComplete: false,
};

export default (
  state = defaultState,
  action: { type: keyof typeof types; value: string }
): State => {
  switch (action.type) {
    case types.CHANGE_FORGOT_EMAIL:
      return { ...state, userEmail: action.value };

    case types.REQUEST_PASSWORD_RESET:
      return { ...state, loading: true, isComplete: false };

    case types.RESOLVE_PASSWORD_RESET:
      return { ...state, loading: false, isComplete: true, userEmail: defaultState.userEmail };

    case types.REJECT_PASSWORD_RESET:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export const getUserEmail = (state: State) => state.userEmail;
export const getLoading = (state: State) => state.loading;
export const getIsComplete = (state: State) => state.isComplete;
