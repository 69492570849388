import find from 'lodash/find';

// App
import { toast } from 'root/actions/app-actions';
import {
  selectFireID,
  selectOverlayCountsLabels,
  selectTSNEQuery,
  selectCurrentSelectedOverlay,
  selectOutOfTheBoxOverlays,
  selectFireFilters,
} from 'root/reducers/index-reducer';

// Elmosfire
import * as types from 'Elmosfire/actions/action-types';
import { clearDatasetOverlay } from 'Elmosfire/actions/datasets-actions';
import * as OVERLAY_TYPES from 'Elmosfire/constants/overlay-types';
import * as tsneOverlayTypes from 'Elmosfire/constants/tsne-overlay-types';
import * as FIRE_FILTER_TYPES from 'Elmosfire/constants/fire-filter-types';
import { getFireSimilarity, putInitFire } from 'Elmosfire/providers/fire-provider';

const resolveLabelsetIds = (overlay, state) => {
  let prediction_labelset_id = null,
    target_labelset_id = null;
  switch (overlay.type) {
    case OVERLAY_TYPES.LOCAL:
      prediction_labelset_id = overlay.predictionLabelsetId;
      target_labelset_id = overlay.id;
      break;
    case OVERLAY_TYPES.BUILT_IN:
      const labelsetID = selectOutOfTheBoxOverlays(state)[overlay.id].labelsetId;
      prediction_labelset_id = labelsetID;
      // There is no labeled data
      break;
    case OVERLAY_TYPES.CATEGORICAL:
      target_labelset_id = overlay.id;
      // There is no predicted data
      break;
    default:
  }
  return { prediction_labelset_id, target_labelset_id };
};

export const updateVizExcludeLabel = (datasetId, label, fireGroupId) => (dispatch, getState) => {
  const remainingLabels = selectOverlayCountsLabels(getState())
    .filter((l) => l.text !== label)
    .map((l) => l.text);
  const { prediction_labelset_id, target_labelset_id } = resolveLabelsetIds(
    selectCurrentSelectedOverlay(getState(), datasetId),
    getState()
  );
  const affectedLabel = find(selectOverlayCountsLabels(getState()), { text: label });

  dispatch({
    type: types.ADD_FIRE_FILTER,
    filter: {
      type: FIRE_FILTER_TYPES.EXCLUDE,
      label: affectedLabel,
      prediction_labelset_id,
      target_labelset_id,
      labels: remainingLabels,
    },
  });
  dispatch({ type: types.REMOVE_COLOR_FROM_LABEL_COLORS, color: affectedLabel.color });

  dispatch(startFire(datasetId, fireGroupId));
};

export const updateVizShowSingleLabel = (datasetId, label, fireGroupId) => (dispatch, getState) => {
  const { prediction_labelset_id, target_labelset_id } = resolveLabelsetIds(
    selectCurrentSelectedOverlay(getState(), datasetId),
    getState()
  );
  const affectedLabel = find(selectOverlayCountsLabels(getState()), { text: label });

  dispatch({
    type: types.ADD_FIRE_FILTER,
    filter: {
      type: FIRE_FILTER_TYPES.SHOW_ONLY,
      label: affectedLabel,
      prediction_labelset_id,
      target_labelset_id,
      labels: [affectedLabel.text],
    },
  });
  dispatch({ type: types.REMOVE_COLOR_FROM_LABEL_COLORS, color: affectedLabel.color });

  dispatch(startFire(datasetId, fireGroupId));
};

export const removeFireFilter = (datasetId, filterLabel, fireGroupId) => (dispatch, getState) => {
  const filterToRemove = find(selectFireFilters(getState()), { label: filterLabel });
  dispatch({
    type: types.CLEAR_FIRE_FILTER,
    filter: filterToRemove,
  });
  dispatch(startFire(datasetId, fireGroupId));
};

export const startFire = (datasetId, fireGroupId) => (dispatch, getState) => {
  dispatch({ type: types.LOADING_FIRE, loading: true });
  dispatch(clearDatasetOverlay(datasetId)); // Clear selected overlay
  dispatch({ type: types.RESET_TSNE_COLOR });

  const filters = selectFireFilters(getState()).map((f) => ({
    prediction_labelset_id: f.prediction_labelset_id,
    target_labelset_id: f.target_labelset_id,
    labels: f.labels,
  }));

  return putInitFire(fireGroupId, filters)
    .then((r) => {
      dispatch(setFire(r));
      dispatch({ type: types.LOADING_FIRE, loading: false });
    })
    .catch(() => {
      dispatch({ type: types.LOADING_FIRE, loading: false });
      dispatch(toast('Failed to apply exclusion filter', 'error'));
      return false;
    });
};

export const fetchSimilarity = (datasetId) => (dispatch, getState) => {
  dispatch({ type: types.TOGGLE_FETCHING_OVERLAY_DATA });
  return getFireSimilarity(datasetId, selectFireID(getState()), selectTSNEQuery(getState()))
    .then((d) => {
      dispatch({ type: types.TOGGLE_FETCHING_OVERLAY_DATA });
      dispatch({
        type: types.GET_QUERIED_SIMILARITY,
        overlayType: tsneOverlayTypes.SIMILARITY,
        data: d.results,
      });
    })
    .catch(() => {
      dispatch(toast('There was an error loading the query data.', 'error'));
      return false;
    });
};

export const setFire = (fire) => ({
  fire,
  type: types.SET_FIRE,
});
