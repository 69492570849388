import { AuthorizedUser } from 'Auth/types';
import * as types from 'Auth/actions/action-types';

type State = {
  currentUser: AuthorizedUser | null;
  userEmail: string;
  userPassword: string;
  rememberMe: boolean;
  loading: boolean;
  userMessage: string | null;
  redirectUrl: string | null;
  expired: boolean;
};

const defaultState: State = {
  currentUser: null,
  userEmail: '',
  userPassword: '',
  rememberMe: false,
  loading: false,
  userMessage: null,
  redirectUrl: null,
  expired: false,
};

export default (
  state = defaultState,
  action: {
    type: keyof typeof types;
    value: string;
    user: AuthorizedUser;
    userEmail: string;
    expired: boolean;
  }
): State => {
  switch (action.type) {
    case types.CHANGE_LOGIN_EMAIL:
      return { ...state, userEmail: action.value };

    case types.CHANGE_LOGIN_PASSWORD:
      return { ...state, userPassword: action.value };

    case types.TOGGLE_LOGIN_REMEMBER_ME:
      return { ...state, rememberMe: !state.rememberMe };

    case types.ATTEMPT_LOGIN:
      return { ...state, loading: true, userMessage: null };

    case types.RESOLVE_LOGIN:
      return state;

    case types.REJECT_LOGIN:
      return { ...state, loading: false };

    case types.CHECK_CURRENT_USER:
      return { ...state, loading: true };

    case types.RESOLVE_CURRENT_USER:
      return { ...state, loading: false, currentUser: action.user };

    case types.CLEAR_CURRENT_USER:
      return { ...state, loading: false, currentUser: null };

    case types.ATTEMPT_LOGOUT:
      return { ...state, loading: true };

    case types.RESOLVE_LOGOUT:
      return { ...state, currentUser: null, loading: false, userPassword: '', userEmail: '' };

    case types.REJECT_LOGOUT:
      return { ...state, loading: false };

    case types.LOAD_SAVED_USER_DETAILS:
      return { ...state, userEmail: action.userEmail, rememberMe: action.userEmail.length > 0 };

    case types.EXPIRED_ERROR:
      return { ...state, expired: action.expired };

    default:
      return state;
  }
};

export const getCurrentUser = (state: State) => state.currentUser;
export const getUserEmail = (state: State) => state.userEmail;
export const getUserPassword = (state: State) => state.userPassword;
export const getUserRememberMe = (state: State) => state.rememberMe;
export const getUserAuthLoading = (state: State) => state.loading;
export const getRedirectURL = (state: State) => state.redirectUrl;
export const getAuthModel = (state: State) => {
  return {
    email: state.userEmail,
    password: state.userPassword,
  };
};
export const getAuthExpired = (state: State) => state.expired;
