import { Map } from 'immutable';
import isNull from 'lodash/isNull';
import sortBy from 'lodash/sortBy';

import * as types from 'Elmosfire/actions/action-types';
import * as OVERLAY_TYPES from 'Elmosfire/constants/overlay-types';
import * as sorts from 'Elmosfire/constants/sort-directions';

export const defaultState = Map({
  fetchingDatasets: false,
  sortDirection: 'up',
  sortColumn: '',
  datasets: Map({}),
  sortedDatasetKeys: [],
});

export const sortDatasets = (datasets, header, sortColumn, sortDirection, forceDirection) => {
  // Create an array of sorted datasets
  const toBeSortedDatasets = datasets
    .toArray()
    // // Map header to dataset data
    .map((d) => ({
      id: d.id,
      Name: d.name,
      'Last Modified': d.updated_at,
      Status: d.status,
      'Data Points': d.row_count,
    }));

  const sortedDatasets = sortBy(toBeSortedDatasets, [
    function (o) {
      return o[header];
    },
  ]);
  const reversedDatasets = sortedDatasets.slice().reverse();

  if (forceDirection === sorts.ASCENDING) {
    return reversedDatasets.map((d) => `${d.id}`);
  } else if (forceDirection === sorts.DESCENDING) {
    return sortedDatasets.map((d) => `${d.id}`);
  }

  if (header === sortColumn) {
    return sortDirection === sorts.ASCENDING
      ? sortedDatasets.map((d) => `${d.id}`)
      : reversedDatasets.map((d) => `${d.id}`);
  } else {
    return sortedDatasets.map((d) => `${d.id}`);
  }
};

export const calculateSortDirection = (header, sortColumn, sortDirection, forceDirection) => {
  if (forceDirection === sorts.ASCENDING) {
    return sorts.ASCENDING;
  } else if (forceDirection === sorts.DESCENDING) {
    return sorts.DESCENDING;
  }

  if (sortColumn) {
    if (header === sortColumn) {
      return sortDirection === sorts.ASCENDING ? sorts.DESCENDING : sorts.ASCENDING;
    } else {
      return sorts.DESCENDING;
    }
  } else {
    return sorts.DESCENDING;
  }
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_ELMOSFIRE_DATASETS:
      return state
        .set('datasets', Map(action.datasets))
        .set('sortedDatasetKeys', Object.keys(action.datasets));

    case types.GET_ELMOSFIRE_DATASET:
      return state
        .setIn(
          ['datasets', `${action.dataset.id}`],
          Object.assign({}, state.getIn(['datasets', `${action.dataset.id}`]), action.dataset)
        )
        .set(
          'sortedDatasetKeys',
          state.get('sortedDatasetKeys').indexOf(`${action.dataset.id}`) > -1
            ? state.get('sortedDatasetKeys')
            : [].concat(state.get('sortedDatasetKeys'), `${action.dataset.id}`)
        );

    case types.CHANGE_ELMOSFIRE_DATASET_NAME:
      return state.setIn(
        ['datasets', `${action.datasetID}`],
        Object.assign({}, state.getIn(['datasets', `${action.datasetID}`]), {
          name: action.value,
        })
      );

    case types.TOGGLE_UPDATING_ELMOSFIRE_DATASET:
      return state.setIn(
        ['datasets', `${action.datasetID}`],
        Object.assign({}, state.getIn(['datasets', `${action.datasetID}`]), {
          saving: !state.getIn(['datasets', `${action.datasetID}`]).saving,
        })
      );

    case types.ADD_NEW_MODEL_GROUP:
      // ADDING THE RESULTS OF A CREATE MODEL GROUP RESPONSE - Merge it into existing
      return state.setIn(
        ['datasets', `${action.datasetID}`],
        Object.assign({}, state.getIn(['datasets', `${action.datasetID}`]), {
          models: Object.assign({}, state.getIn(['datasets', `${action.datasetID}`]).models, {
            [action.modelGroup.id]: Object.assign(
              {},
              state.getIn(['datasets', `${action.datasetID}`]).models[action.modelGroup.id],
              action.modelGroup
            ),
          }),
        })
      );

    case types.GET_MODEL_GROUPS:
      // Replacing the list of model groups with the entirety of the server response
      // Done when fetching the list the first time or after an exclude operation
      return state.setIn(
        ['datasets', `${action.datasetID}`],
        Object.assign({}, state.getIn(['datasets', `${action.datasetID}`]), {
          models: action.modelGroups,
        })
      );

    case types.TOGGLE_FETCHING_MODEL_GROUPS:
      return state.setIn(
        ['datasets', `${action.datasetID}`],
        Object.assign({}, state.getIn(['datasets', `${action.datasetID}`]), {
          fetchingModels: action.saving,
        })
      );

    case types.RETRAIN_MODEL_GROUP_POLL_START:
      return state.setIn(
        ['datasets', `${action.datasetID}`],
        Object.assign({}, state.getIn(['datasets', `${action.datasetID}`]), {
          models: Object.assign({}, state.getIn(['datasets', `${action.datasetID}`]).models, {
            [action.modelID]: Object.assign(
              {},
              state.getIn(['datasets', `${action.datasetID}`]).models[action.modelID],
              {
                status: 'creating',
              }
            ),
          }),
        })
      );

    case types.REMOVE_ELMOSFIRE_DATASET:
      return state.set('datasets', state.get('datasets').remove(`${action.datasetID}`)).set(
        'sortedDatasetKeys',
        state.get('sortedDatasetKeys').filter((key) => key !== `${action.datasetID}`)
      );

    case types.SET_OVERLAY:
      return state.setIn(
        ['datasets', `${action.datasetID}`],
        Object.assign({}, state.getIn(['datasets', `${action.datasetID}`]), {
          selectedOverlay: isNull(action.overlayItem) ? null : action.overlayItem,
        })
      );

    case types.CLEAR_OVERLAY:
      return state.setIn(
        ['datasets', `${action.datasetId}`],
        Object.assign({}, state.getIn(['datasets', `${action.datasetId}`]), {
          selectedOverlay: null,
        })
      );

    case types.SORT_ELMOSFIRE_DATASETS:
      return state
        .set(
          'sortDirection',
          calculateSortDirection(
            action.header,
            state.get('sortColumn'),
            state.get('sortDirection'),
            action.forceDirection
          )
        )
        .set('sortColumn', action.header)
        .set(
          'sortedDatasetKeys',
          sortDatasets(
            state.get('datasets'),
            action.header,
            state.get('sortColumn'),
            state.get('sortDirection'),
            action.forceDirection
          )
        );

    case types.TOGGLE_FETCHING_ELMOSFIRE_DATASETS:
      return state.set('fetchingDatasets', action.fetching);

    default:
      return state;
  }
};

export const getDatasets = (state) => state.get('datasets');
export const getDatasetById = (state, datasetID) => state.getIn(['datasets', `${datasetID}`]);
export const getSelectedOverlay = (state, datasetID) =>
  state.getIn(['datasets', `${datasetID}`]) &&
  state.getIn(['datasets', `${datasetID}`]).selectedOverlay;

export const getSelectedUserOverlay = (state, datasetID) => {
  const modelId = state.getIn(['datasets', `${datasetID}`]).selectedOverlay.id;
  return state.getIn(['datasets', `${datasetID}`]).models[modelId];
};

export const getCanTrainSelectedOverlay = (state, datasetID) => {
  const selectedOverlayType =
    state.getIn(['datasets', `${datasetID}`]) &&
    state.getIn(['datasets', `${datasetID}`]).selectedOverlay &&
    state.getIn(['datasets', `${datasetID}`]).selectedOverlay.type;
  return selectedOverlayType === OVERLAY_TYPES.LOCAL;
};

export const getDatasetsSortDirection = (state) => state.get('sortDirection');
export const getDatasetsSortColumn = (state) => state.get('sortColumn');
export const getDatasetSortKeys = (state) => state.get('sortedDatasetKeys');
export const getAppFetchingDatasets = (state) => state.get('fetchingDatasets');
