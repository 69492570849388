import { Map } from 'immutable';
import find from 'lodash/find';

import * as types from 'Elmosfire/actions/action-types';

export const defaultState = Map({
  countsLoading: false,
  predictionsLoading: false,
  labelCounts: null,
  predictedCounts: null,
  highlighted: [],
  labels: [],
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_OVERLAY_LABEL_COUNT_POLL_START:
      return state.set('countsLoading', true).set('labelCounts', null);

    case types.GET_OVERLAY_LABEL_COUNT_POLL_STOP:
      return state.set('countsLoading', false);

    case types.GET_OVERLAY_PREDICTED_LABEL_COUNT_POLL_START:
      return state.set('predictionsLoading', true).set('predictedCounts', null);

    case types.GET_OVERLAY_PREDICTED_LABEL_COUNT_POLL_STOP:
      return state.set('predictionsLoading', false);

    case types.GET_OVERLAY_LABEL_COUNTS:
      return state.set('countsLoading', false).set('labelCounts', action.labelCounts);

    case types.GET_OVERLAY_PREDICTED_LABEL_COUNTS:
      return state.set('predictionsLoading', false).set('predictedCounts', action.labelCounts);

    case types.CLEAR_TSNE_HIGHLIGHTED_AND_FILTERS:
    case types.CLEAR_TSNE_HIGHLIGHTED:
      return state.set('highlighted', defaultState.get('highlighted'));

    case types.INITIALIZE_TSNE_MENU:
      return state.set('labels', action.labels);

    case types.ADD_LABEL_HIGHLIGHT:
      return state.set(
        'highlighted',
        [...state.get('highlighted'), action.label].reduce(function (a, b) {
          if (!find(a, { text: b.text })) {
            a.push(b);
          }
          return a;
        }, [])
      );

    case types.REMOVE_LABEL_HIGHLIGHT:
      return state.set(
        'highlighted',
        state.get('highlighted').reduce(function (a, b) {
          if (b.text !== action.label) {
            a.push(b);
          }
          return a;
        }, [])
      );

    case types.CLEAR_OVERLAY:
      return defaultState;

    default:
      return state;
  }
};

export const getOverlayCountsLoading = (state) =>
  state.get('countsLoading') || state.get('predictionsLoading');
export const getOverlayCounts = (state) => state.get('labelCounts');
export const getOverlayPredictedCounts = (state) => state.get('predictedCounts');
export const getHighlighted = (state) => state.get('highlighted');
export const getLabels = (state) => state.get('labels');
