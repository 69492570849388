import { Map } from 'immutable';

// Elmosfire
import * as types from 'Elmosfire/actions/action-types';
import * as overlayTypes from 'Elmosfire/constants/tsne-overlay-types';
import TSnePoint from 'Elmosfire/models/TSnePoint';

export const defaultState = Map({
  status: null,
  fetchingOverlayData: false,
  selectedRows: [],
  openMenu: true,
  cluster: {
    id: null,
    created_at: null,
    updated_at: null,
    dataset_id: null,
    data: null,
    status: null,
    column_id: null,
    points: null,
  },
  selectedColumn: null,
  selectedOverlayType: null,
  selectedOverlayData: [],
  selectedUserOverlayData: {},
  query: '',
  tsnePoints: [],
});

const createTSNEPoints = (rawPoints) => {
  const convertedPoints = [];
  for (let i = 0; i < rawPoints.length; i++) {
    convertedPoints.push(new TSnePoint(rawPoints[i].row, rawPoints[i].x, rawPoints[i].y));
  }
  return convertedPoints;
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_TSNE_STATUS:
      return state.set('status', action.status);
    case types.GET_TSNE_CLUSTER:
      return state
        .set('cluster', action.cluster)
        .set('tsnePoints', createTSNEPoints(action.cluster.points));
    case types.CLEAR_ITEMS_SELECTED:
      return state.set('selectedRows', []);
    case types.CHANGE_ITEMS_SELECTED:
      return state.set('selectedRows', action.selections);
    case types.ADD_TSNE_OVERLAY_DATA:
      return state
        .set(
          'selectedOverlayData',
          [].concat(
            state.get('selectedOverlayData'),
            Object.keys(action.labelData).map((key) => ({
              row_index: key,
              value: action.labelData[key][0],
            }))
          )
        )
        .set('selectedUserOverlayData', action.labelData);
    case types.COLOR_TSNE_BASED_ON_COLLECTION:
      return state
        .set('selectedColumn', action.columnName)
        .set('selectedOverlayType', overlayTypes.COLLECTION)
        .set(
          'selectedOverlayData',
          [].concat(
            state.get('selectedOverlayData'),
            Object.keys(action.labelData).map((key) => ({
              row_index: key,
              value: action.labelData[key][0],
            }))
          )
        );
    case types.SET_TSNE_OVERLAY_NAME:
      return state
        .set('selectedColumn', action.selectedColumn)
        .set('selectedOverlayType', overlayTypes.BLANK)
        .set('selectedOverlayData', defaultState.get('selectedOverlayData'));
    case types.GET_QUERIED_SIMILARITY:
      return state
        .set('selectedColumn', state.get('query'))
        .set('selectedOverlayType', action.overlayType)
        .set('selectedOverlayData', action.data);
    case types.TOGGLE_FETCHING_OVERLAY_DATA:
      return state.set('fetchingOverlayData', !state.get('fetchingOverlayData'));
    case types.CHANGE_QUERY:
      return state.set('query', action.query);
    case types.RESET_TSNE_COLOR:
      return state
        .set('selectedColumn', null)
        .set('selectedOverlayType', overlayTypes.BLANK)
        .set('selectedOverlayData', []);
    case types.TOGGLE_TSNE_LABEL_MENU:
      return state.set('openMenu', action.open);
    case types.CLEAR_TSNE:
      return state.merge(defaultState);
    case types.CLEAR_TSNE_CLUSTER:
      return state
        .set('cluster', defaultState.get('cluster'))
        .set('tsnePoints', defaultState.get('tsnePoints'));
    default:
      return state;
  }
};

export const getTSNEStatus = (state) => state.get('status');
export const getTSNEPoints = (state) => state.get('tsnePoints') || [];
export const getTSNESize = (state) => (state.get('tsnePoints') || []).length;
export const getSelectedRows = (state) => state.get('selectedRows');
export const getFetchingOverlayData = (state) => state.get('fetchingOverlayData');
export const getSelectedColumn = (state) => state.get('selectedColumn');
export const getSelectedOverlayType = (state) => state.get('selectedOverlayType');
export const getSelectedOverlayData = (state) => state.get('selectedOverlayData');
export const getSelectedUserOverlayData = (state) => state.get('selectedUserOverlayData');
export const getQuery = (state) => state.get('query');
export const getOpenMenu = (state) => state.get('openMenu');
