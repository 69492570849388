import { combineReducers } from 'redux-immutable';

import app, * as appSelectors from './app-reducer';

// Auth
import auth, * as AuthSelectors from 'Auth/reducers/auth-reducer';
import updatePassword, * as UpdatePasswordSelectors from 'Auth/reducers/update-password-reducer';
import confirm, * as ConfirmSelectors from 'Auth/reducers/confirm-reducer';
import forgotPassword, * as ForgotPasswordSelectors from 'Auth/reducers/forgot-password-reducer';
import register, * as RegisterSelectors from 'Auth/reducers/register-reducer';

// Elmosfire
import selectedDocuments, * as selectedDocumentsSelectors from 'Elmosfire/reducers/selected-documents-reducer';
import tsne, * as tsneSelectors from 'Elmosfire/reducers/tsne-reducer';
import wordCloud, * as wordCloudSelectors from 'Elmosfire/reducers/wordcloud-reducer';
import rows, * as rowsSelectors from 'Elmosfire/reducers/rows-reducer';
import fire, * as fireSelectors from 'Elmosfire/reducers/fire-reducer';
import overlayCounts, * as overlayCountsSelectors from 'Elmosfire/reducers/overlay-counts-reducer';
import fireGroups, * as fireGroupsSelectors from 'Elmosfire/reducers/datasets-reducer';
import overlays, * as overlaysSelectors from 'Elmosfire/reducers/overlays-reducer';
import newFireGroup, * as newFireGroupSelectors from 'Elmosfire/reducers/new-firegroup-reducer';
import widgetLayout, * as widgetSelectors from 'Elmosfire/reducers/widget-reducer';
import newModel, * as newModelSelectors from 'Elmosfire/reducers/new-model-reducer';

// Crowdlabel
import newQuestionnaire, * as newQuestionnaireSelectors from 'Crowdlabel/reducers/new-questionnaire-reducer';
import questionnaires, * as questionnairesSelectors from 'Crowdlabel/reducers/questionnaires-reducer';
import labeling, * as labelingSelectors from 'Crowdlabel/reducers/labeling-reducer';

const rootReducer = combineReducers({
  app,
  auth,
  forgotPassword,
  register,
  updatePassword,
  confirm,
  selectedDocuments,
  tsne,
  wordCloud,
  rows,
  fire,
  overlayCounts,
  fireGroups,
  overlays,
  newFireGroup,
  widgetLayout,
  newModel,
  newQuestionnaire,
  questionnaires,
  labeling,
});

export default rootReducer;

// App
export const selectAppToast = (state) => appSelectors.getAppToast(state.get('app'));
export const selectAppSettings = (state) => appSelectors.getAppSettings(state.get('app'));
export const selectAppSidebarOpen = (state) => appSelectors.getAppSidebarOpen(state.get('app'));
export const selectXSRFToken = (state) => appSelectors.getXSRFToken(state.get('app'));

// Auth
export const selectCurrentUser = (state) => AuthSelectors.getCurrentUser(state.get('auth'));
export const selectUserEmail = (state) => AuthSelectors.getUserEmail(state.get('auth'));
export const selectUserPassword = (state) => AuthSelectors.getUserPassword(state.get('auth'));
export const selectUserRememberMe = (state) => AuthSelectors.getUserRememberMe(state.get('auth'));
export const selectAuthLoading = (state) => AuthSelectors.getUserAuthLoading(state.get('auth'));
export const selectRedirectURL = (state) => AuthSelectors.getRedirectURL(state.get('auth'));
export const selectAuthLoginModel = (state) => AuthSelectors.getAuthModel(state.get('auth'));
export const selectAuthExpiredAccount = (state) => AuthSelectors.getAuthExpired(state.get('auth'));

// Forgot
export const selectForgotUserEmail = (state) =>
  ForgotPasswordSelectors.getUserEmail(state.get('forgotPassword'));
export const selectForgotComplete = (state) =>
  ForgotPasswordSelectors.getIsComplete(state.get('forgotPassword'));
export const selectForgotLoading = (state) =>
  ForgotPasswordSelectors.getLoading(state.get('forgotPassword'));

// Password Update
export const selectCurrentPasswordInput = (state) =>
  UpdatePasswordSelectors.getCurrentPasswordInput(state.get('updatePassword'));
export const selectNewPasswordInput = (state) =>
  UpdatePasswordSelectors.getNewPasswordInput(state.get('updatePassword'));
export const selectNewPasswordRepeatInput = (state) =>
  UpdatePasswordSelectors.getNewPasswordRepeatInput(state.get('updatePassword'));
export const selectPasswordChangeLoading = (state) =>
  UpdatePasswordSelectors.getPasswordChangeLoading(state.get('updatePassword'));
export const selectPasswordChangeComplete = (state) =>
  UpdatePasswordSelectors.getPasswordChangeComplete(state.get('updatePassword'));
export const selectResetTokenParsed = (state) =>
  UpdatePasswordSelectors.getTokenParsed(state.get('updatePassword'));
export const selectResetToken = (state) =>
  UpdatePasswordSelectors.getResetToken(state.get('updatePassword'));

// Registration
export const selectNewUserName = (state) => RegisterSelectors.getNewUserName(state.get('register'));
export const selectNewUserCompanyName = (state) =>
  RegisterSelectors.getNewUserCompanyName(state.get('register'));
export const selectNewUserEmail = (state) =>
  RegisterSelectors.getNewUserEmail(state.get('register'));
export const selectNewUserPassword = (state) =>
  RegisterSelectors.getNewUserPassword(state.get('register'));
export const selectNewUserAgreeTerms = (state) =>
  RegisterSelectors.getNewUserAgreeTerms(state.get('register'));
export const selectNewUserAgreeSubscribe = (state) =>
  RegisterSelectors.getNewUserAgreeSubscribe(state.get('register'));
export const selectRegistrationLoading = (state) =>
  RegisterSelectors.getRegistrationLoading(state.get('register'));
export const selectRegistrationComplete = (state) =>
  RegisterSelectors.getRegistrationComplete(state.get('register'));
export const selectNewUserModel = (state) =>
  RegisterSelectors.getNewUserModel(state.get('register'));
export const selectRegisterBlacklistMessage = (state) =>
  RegisterSelectors.getNewUserBlacklistMessage(state.get('register'));

// Confirm
export const selectConfirmTokenParsed = (state) =>
  ConfirmSelectors.getConfirmTokenParsed(state.get('confirm'));
export const selectConfirmToken = (state) => ConfirmSelectors.getConfirmToken(state.get('confirm'));
export const selectConfirmLoading = (state) =>
  ConfirmSelectors.getConfirmLoading(state.get('confirm'));
export const selectConfirmComplete = (state) =>
  ConfirmSelectors.getConfirmComplete(state.get('confirm'));
export const selectConfirmResult = (state) =>
  ConfirmSelectors.getConfirmResult(state.get('confirm'));

// Elmosfire

// Selected Documents
export const selectSDCurrentLabels = (state) =>
  selectedDocumentsSelectors.getCurrentLabels(state.get('selectedDocuments'));
export const selectSDNewLabel = (state) =>
  selectedDocumentsSelectors.getNewLabel(state.get('selectedDocuments'));
export const selectSDSavingLabels = (state) =>
  selectedDocumentsSelectors.getSavingLabels(state.get('selectedDocuments'));
export const selectSDSelectedLabel = (state) =>
  selectedDocumentsSelectors.getSelectedLabel(state.get('selectedDocuments'));
export const selectSDMenu = (state) =>
  selectedDocumentsSelectors.getMenu(state.get('selectedDocuments'));
export const selectHideCursorSwatch = (state) =>
  selectedDocumentsSelectors.getHideCursorSwatch(state.get('selectedDocuments'));
export const selectSDDisplayAddLabel = (state) =>
  selectedDocumentsSelectors.getAddLabelDisplayInput(state.get('selectedDocuments'));
export const selectSDDisplayLabeledConfirmation = (state) =>
  selectedDocumentsSelectors.getDisplayLabeledConfirmation(state.get('selectedDocuments'));

// TSNE
export const selectDatasetPoints = (state) => tsneSelectors.getTSNEPoints(state.get('tsne'));
export const selectTSNESize = (state) => tsneSelectors.getTSNESize(state.get('tsne'));
export const selectDatasetTSNEStatus = (state) => tsneSelectors.getTSNEStatus(state.get('tsne'));
export const selectTSNESelectedRows = (state) => tsneSelectors.getSelectedRows(state.get('tsne'));
export const selectTSNEFetchingOverlayData = (state) =>
  tsneSelectors.getFetchingOverlayData(state.get('tsne'));
export const selectTSNESelectedColumn = (state) =>
  tsneSelectors.getSelectedColumn(state.get('tsne'));
export const selectTSNESelectedOverlayType = (state) =>
  tsneSelectors.getSelectedOverlayType(state.get('tsne'));
export const selectTSNESelectedOverlayData = (state) =>
  tsneSelectors.getSelectedOverlayData(state.get('tsne'));
export const selectTSNEUserSelectedOverlayData = (state) =>
  tsneSelectors.getSelectedUserOverlayData(state.get('tsne'));
export const selectTSNEQuery = (state) => tsneSelectors.getQuery(state.get('tsne'));
export const selectTSNEOpenMenu = (state) => tsneSelectors.getOpenMenu(state.get('tsne'));

// WordCloud
export const selectWordCloudReady = (state) =>
  wordCloudSelectors.getWordCloudReady(state.get('wordCloud'));
export const selectWordCloudStatus = (state) =>
  wordCloudSelectors.getWordCloudStatus(state.get('wordCloud'));
export const selectWordCloudLoading = (state) =>
  wordCloudSelectors.getWordCloudLoading(state.get('wordCloud'));
export const selectWordCloud = (state) => wordCloudSelectors.getWordCloud(state.get('wordCloud'));

// Rows
export const selectRowsFetching = (state) => rowsSelectors.getRowsFetching(state.get('rows'));
export const selectRows = (state) => rowsSelectors.getRows(state.get('rows'));

// Overlay Counts
export const selectOverlayCountsLoading = (state) =>
  overlayCountsSelectors.getOverlayCountsLoading(state.get('overlayCounts'));
export const selectOverlayCounts = (state) =>
  overlayCountsSelectors.getOverlayCounts(state.get('overlayCounts'));
export const selectOverlayPredictedCounts = (state) =>
  overlayCountsSelectors.getOverlayPredictedCounts(state.get('overlayCounts'));
export const selectOverlayCountsHighlighted = (state) =>
  overlayCountsSelectors.getHighlighted(state.get('overlayCounts'));
export const selectOverlayCountsLabels = (state) =>
  overlayCountsSelectors.getLabels(state.get('overlayCounts'));

// Overlays
export const selectOutOfTheBoxOverlays = (state) =>
  overlaysSelectors.getOutOfTheBoxOverlays(state.get('overlays'));
export const selectOverlayCanRetrain = (state) =>
  overlaysSelectors.getCanRetrain(state.get('overlays'));
export const selectOverlaysInputFilter = (state) =>
  overlaysSelectors.getInputFilter(state.get('overlays'));
export const selectOverlaysRetraining = (state) =>
  overlaysSelectors.getRetraining(state.get('overlays'));
export const selectOverlaysRetrainProgress = (state) =>
  overlaysSelectors.getRetrainProgress(state.get('overlays'));

// Fire
export const selectFireID = (state) => fireSelectors.getFireID(state.get('fire'));
export const selectFireLoading = (state) => fireSelectors.getFireLoading(state.get('fire'));
export const selectFireClusterID = (state) => fireSelectors.getClusterID(state.get('fire'));
export const selectFireVectorizerID = (state) => fireSelectors.getVectorizerID(state.get('fire'));
export const selectFireSubsetID = (state) => fireSelectors.getSubsetID(state.get('fire'));
export const selectFireDatasetID = (state) => fireSelectors.getDatasetID(state.get('fire'));
export const selectFireFilters = (state) => fireSelectors.getFilters(state.get('fire'));
export const selectFireDataColumnID = (state) => fireSelectors.getDataColumnID(state.get('fire'));

// Elmosfire FireGroups - DATASETS
export const selectFireGroups = (state) => fireGroupsSelectors.getDatasets(state.get('fireGroups'));
export const selectFireGroupById = (state, datasetID) =>
  fireGroupsSelectors.getDatasetById(state.get('fireGroups'), datasetID);

export const selectCurrentSelectedOverlay = (state, datasetID) =>
  fireGroupsSelectors.getSelectedOverlay(state.get('fireGroups'), datasetID);

export const selectCanTrainSelectedOverlay = (state, datasetID) =>
  fireGroupsSelectors.getCanTrainSelectedOverlay(state.get('fireGroups'), datasetID);
export const selectFireGroupsSortDirection = (state) =>
  fireGroupsSelectors.getDatasetsSortDirection(state.get('fireGroups'));
export const selectFireGroupsSortColumn = (state) =>
  fireGroupsSelectors.getDatasetsSortColumn(state.get('fireGroups'));
export const selectFireGroupsSortKeys = (state) =>
  fireGroupsSelectors.getDatasetSortKeys(state.get('fireGroups'));
export const selectAppFetchingFireGroups = (state) =>
  fireGroupsSelectors.getAppFetchingDatasets(state.get('fireGroups'));

// New Fire Group
export const selectNewFireGroup = (state) => state.get('newFireGroup');
export const selectNewFireGroupName = (state) =>
  newFireGroupSelectors.getNewFireGroupName(state.get('newFireGroup'));
export const selectNewFireGroupSelectedDataset = (state) =>
  newFireGroupSelectors.getSelectedDataset(state.get('newFireGroup'));
export const selectNewFireGroupSelectedColumn = (state) =>
  newFireGroupSelectors.getSelectedColumn(state.get('newFireGroup'));

// Widget Layout
export const selectFirstColumnWidgets = (state) =>
  widgetSelectors.getFirstColumnWidgets(state.get('widgetLayout'));
export const selectSecondColumnWidgets = (state) =>
  widgetSelectors.getSecondColumnWidgets(state.get('widgetLayout'));

// New Model
export const selectNewModel = (state) => state.get('newModel');
export const selectNewModelName = (state) =>
  newModelSelectors.getNewModelName(state.get('newModel'));
export const selectNewModelLabelingType = (state) =>
  newModelSelectors.getNewModelLabelingType(state.get('newModel'));
export const selectNewModelColumnID = (state) =>
  newModelSelectors.getNewModelColumnID(state.get('newModel'));
export const selectNewModelSaving = (state) =>
  newModelSelectors.getNewModelSaving(state.get('newModel'));

// Crowdlabel

// New Questionnaire
export const selectNewQuestionnaireName = (state) =>
  newQuestionnaireSelectors.getNewQuestionnaireName(state.get('newQuestionnaire'));
export const selectNewQuestionnaireType = (state) =>
  newQuestionnaireSelectors.getNewQuestionnaireType(state.get('newQuestionnaire'));
export const selectNewQuestionnaireLabelersPerTask = (state) =>
  newQuestionnaireSelectors.getNewQuestionnaireLabelersPerTask(state.get('newQuestionnaire'));
export const selectNewQuestionnaireQuestions = (state) =>
  newQuestionnaireSelectors.getNewQuestionnaireQuestions(state.get('newQuestionnaire'));
export const selectNewQuestionnaireAnswers = (state) =>
  newQuestionnaireSelectors.getNewQuestionnaireAnswers(state.get('newQuestionnaire'));
export const selectNewQuestionnaireDisplayChangeFirstQuestionDialog = (state) =>
  newQuestionnaireSelectors.getDisplayChangeFirstQuestionDialog(state.get('newQuestionnaire'));
export const selectNewQuestionnairePotentialFirstQuestionValue = (state) =>
  newQuestionnaireSelectors.getPotentialNewFirstQuestionValue(state.get('newQuestionnaire'));
export const selectNewQuestionnaireSelectedDataset = (state) =>
  newQuestionnaireSelectors.getSelectedDataset(state.get('newQuestionnaire'));
export const selectNewQuestionnaireSelectedColumn = (state) =>
  newQuestionnaireSelectors.getSelectedColumn(state.get('newQuestionnaire'));

// Questionnaires
export const selectQuestionnaireById = (state, id) =>
  questionnairesSelectors.getQuestionnaireById(state.get('questionnaires'), id);
export const selectQuestionnaireQuestions = (state, id) =>
  questionnairesSelectors.getQuestionnaireQuestions(state.get('questionnaires'), id);

export const selectLabelingCurrentQuestionnaireId = (state) =>
  labelingSelectors.getCurrentQuestionnaireId(state.get('labeling'));
export const selectLabelingCurrentQuestion = (state) =>
  labelingSelectors.getCurrentQuestion(state.get('labeling'));
export const selectLabelingCurrentLabels = (state) =>
  labelingSelectors.getCurrentLabels(state.get('labeling'));
export const selectLabelingNewLabels = (state) =>
  labelingSelectors.getNewLabels(state.get('labeling'));
export const selectLabelingCurrentAndNewLabels = (state) =>
  labelingSelectors.getCurrentAndNewLabels(state.get('labeling'));
export const selectLabelingLabelColors = (state) =>
  labelingSelectors.getLabelColors(state.get('labeling'));
export const selectLabelingQueuedExamples = (state) =>
  labelingSelectors.getQueuedExamples(state.get('labeling'));
export const selectLabelingExamples = (state) =>
  labelingSelectors.getExamples(state.get('labeling'));
export const selectLabelingSavingExamples = (state) =>
  labelingSelectors.getSavingExamples(state.get('labeling'));
export const selectLabelingSelectedLabel = (state) =>
  labelingSelectors.getSelectedLabel(state.get('labeling'));
export const selectLabelingSelectedExampleIndex = (state) =>
  labelingSelectors.getSelectedExampleIndex(state.get('labeling'));
export const selectLabelingExamplesQuantity = (state) =>
  labelingSelectors.getExamplesQuantity(state.get('labeling'));
export const selectLabelingFetchingExamples = (state) =>
  labelingSelectors.getFetchingExamples(state.get('labeling'));
export const selectLabelingNewLabel = (state) =>
  labelingSelectors.getNewLabel(state.get('labeling'));
export const selectLabelingSavingNewLabel = (state) =>
  labelingSelectors.getSavingNewLabel(state.get('labeling'));
export const selectCrowdlabelHideCursorSwatch = (state) =>
  labelingSelectors.getHideCursorSwatch(state.get('labeling'));
export const selectCompletedExamples = (state) =>
  labelingSelectors.getCompletedExamples(state.get('labeling'));
export const selectLabelingSettingsOpen = (state) =>
  labelingSelectors.getSettingsOpen(state.get('labeling'));

// User Labeling Settings
export const selectUserShowPredictions = (state) =>
  labelingSelectors.getUserShowPredictions(state.get('labeling'));
export const selectUserToggleTextWrap = (state) =>
  labelingSelectors.getUserToggleTextWrap(state.get('labeling'));
export const selectUserShowKeywords = (state) =>
  labelingSelectors.getUserShowKeywords(state.get('labeling'));
export const selectUserTextSize = (state) =>
  labelingSelectors.getUserTextSize(state.get('labeling'));
export const selectUserExtractionTheme = (state) =>
  labelingSelectors.getUserExtractionTheme(state.get('labeling'));
export const selectUserImageQuantity = (state) =>
  labelingSelectors.getUserImageQuantity(state.get('labeling'));
export const selectUserExtractionQuantity = (state) =>
  labelingSelectors.getUserExtractionQuantity(state.get('labeling'));
export const selectUserTextQuantity = (state) =>
  labelingSelectors.getUserTextQuantity(state.get('labeling'));

// Undo History
export const selectLabelingHistory = (state) =>
  labelingSelectors.getLabelingHistory(state.get('labeling'));
