import styled from 'styled-components';
import { MEDIA_QUERIES } from '@indico-data/permafrost';

export const StyledLoginFailed = styled.div`
  padding: 20px 10px;
  text-align: center;

  @media ${MEDIA_QUERIES.mediumScreen} {
    padding: 60px;
  }

  .CardBody {
    padding: 20px;
  }

  .shrug {
    width: 100%;
    max-width: 260px;
    margin-bottom: 26px;
  }

  p {
    margin-bottom: 35px;
  }
`;
