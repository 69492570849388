import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import union from 'lodash/union';
import { Map } from 'immutable';

import { COLORS, LABEL_COLORS } from '@indico-data/permafrost';

import * as types from 'Crowdlabel/actions/action-types';
import * as labelingTypes from 'Crowdlabel/constants/redux-labeling-types';
import * as datasetTypes from 'Crowdlabel/constants/redux-dataset-types';
import * as settingsKeys from 'Crowdlabel/constants/user-settings';
import {
  USER_EXTRACTION_THEME_DARK,
  USER_TEXT_SIZE_SMALL,
} from 'Crowdlabel/constants/user-settings';
import { DEFAULT_LABEL_COLOR } from 'Crowdlabel/constants/redux-labeling-types';
import {
  checkLabelsOverlap,
  resolveAddExtraction,
  resolveRemoveExtraction,
} from 'Crowdlabel/utils/labeling';

export const defaultState = Map({
  hideCursorSwatch: false,
  showPredictions: true,
  textWrap: true,
  showKeywords: true,
  settingsOpen: false,
  textSize: USER_TEXT_SIZE_SMALL,
  extractionTheme: USER_EXTRACTION_THEME_DARK,
  userExtractionQuantity: 1,
  userImageQuantity: 6,
  userTextQuantity: 3,
  savingNewLabel: false,
  newLabel: '',
  savingExamples: false,
  fetchingExamples: false,
  completedExamples: [], // Collection of seen & completed example hashes
  queuedExamples: {},
  currentExamples: null,
  currentQuestionnaireId: '',
  currentQuestionnaireType: datasetTypes.IMAGE,
  currentQuestion: {},
  currentLabels: [],
  newLabels: [],
  selectedLabel: { color: DEFAULT_LABEL_COLOR },
  selectedExampleIndex: 0,
  lastSelectedLabels: {}, // Last used label value for each question
  examplesQuantity: 6,
  labelColors: LABEL_COLORS,
  labelingHistory: [],
});

const removeLabel = { text: 'Remove Label', color: COLORS.darkGray, removeLabel: true };

const setExampleQuantity = (
  datasetType,
  questionType,
  userExtractionQuantity,
  userImageQuantity,
  userTextQuantity
) => {
  // console.log('Dataset Type:', datasetType);
  // console.log('Question Type:', questionType);
  if (questionType === labelingTypes.EXTRACTION) {
    return userExtractionQuantity;
  } else if (datasetType === datasetTypes.IMAGE) {
    return userImageQuantity;
  } else if (datasetType === datasetTypes.TEXT) {
    return userTextQuantity;
  }
};

const setCMLabels = (labels, selectedLabel) => {
  if (selectedLabel.removeLabel) {
    return [];
  } else {
    const labelIndex = findIndex(labels, (o) => o.text === selectedLabel.text);
    return labelIndex > -1
      ? [].concat(labels.slice(0, labelIndex), labels.slice(labelIndex + 1))
      : [].concat(labels, selectedLabel);
  }
};

const calculateRegressionMidPoint = (max, min) => {
  return parseInt((max - min) / 2 + min, 10);
};

const addExtraction = (selections, startOffset, endOffset, selectedLabel) => {
  const removingLabel = selectedLabel.hasOwnProperty('removeLabel') && selectedLabel.removeLabel;
  return removingLabel
    ? resolveRemoveExtraction(selections, startOffset, endOffset)
    : resolveAddExtraction(selections, startOffset, endOffset, selectedLabel);
};

const batchAddExtractions = (previousExtractions, newExtractions) => {
  for (const label of newExtractions) {
    previousExtractions = addExtraction(
      previousExtractions,
      label.startOffset,
      label.endOffset,
      label.labelObj
    );
  }
  return previousExtractions;
};

const resolveSelectedLabel = (question, savedLabel, labelColor) => {
  if (savedLabel) {
    return savedLabel;
  } else {
    return question.targets[0]
      ? { text: question.targets[0], color: labelColor }
      : calculateRegressionMidPoint(question.max, question.min);
  }
};

const generateLabels = (targets, state, index) => {
  return targets.map((t, i) => ({
    text: t,
    color: getLabelColorForIndex(index ? index : i, state.get('labelColors')),
  }));
};

const getLabelColorForIndex = (i, colors) => {
  return colors[i % colors.length];
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.ADD_QUEUED_EXAMPLES:
      return state.set(
        'queuedExamples',
        Object.assign({}, state.get('queuedExamples'), action.examples)
      );

    case types.PURGE_EXAMPLE_FROM_QUEUE:
      return state.set(
        'queuedExamples',
        Object.keys(state.get('queuedExamples'))
          .filter((e) => e !== action.example)
          .reduce((prev, key) => {
            return Object.assign({}, prev, {
              [key]: state.get('queuedExamples')[key],
            });
          }, {})
      );

    case types.CLEAR_COMPLETED_QUEUED_EXAMPLES:
      return state.set(
        'queuedExamples',
        Object.keys(state.get('queuedExamples'))
          .filter((e) => {
            return action.completedExamples.indexOf(parseInt(e, 10)) < 0;
          })
          .reduce((prev, key) => {
            return Object.assign({}, prev, {
              [key]: state.get('queuedExamples')[key],
            });
          }, {})
      );

    case types.INITIALIZE_WITH_CLASSIFICATION_LABELING:
      return state
        .set('currentQuestionnaireId', action.questionnaire.id)
        .set('currentQuestionnaireType', action.questionnaire.data_type || datasetTypes.IMAGE)
        .set('currentQuestion', action.questionnaire.questions[0])
        .set(
          'currentLabels',
          [].concat(removeLabel, generateLabels(action.questionnaire.questions[0].targets, state))
        )
        .set('selectedLabel', {
          text: action.questionnaire.questions[0].targets[0],
          color: state.get('labelColors')[0],
        })
        .set(
          'examplesQuantity',
          setExampleQuantity(
            action.questionnaire.data_type || datasetTypes.IMAGE,
            action.questionnaire.questions[0].type,
            state.get('userExtractionQuantity'),
            state.get('userImageQuantity'),
            state.get('userTextQuantity')
          )
        );

    // case types.INITIALIZE_WITH_REGRESSION_LABELING:
    //     return state
    //         .set('currentDatasetId', action.dataset.id)
    //         .set('currentDatasetType', action.dataset.data_type || datasetTypes.IMAGE)
    //         .set('currentQuestion', action.dataset.questions[0])
    //         .set('currentLabels', [])
    //         .set(
    //             'selectedLabel',
    //             calculateRegressionMidPoint(
    //                 action.dataset.questions[0].max,
    //                 action.dataset.questions[0].min
    //             )
    //         )
    //         .set(
    //             'examplesQuantity',
    //             setExampleQuantity(
    //                 action.dataset.data_type || datasetTypes.IMAGE,
    //                 action.dataset.questions[0].type,
    //                 state.get('userExtractionQuantity'),
    //                 state.get('userImageQuantity'),
    //                 state.get('userTextQuantity')
    //             )
    //         );

    case types.INITIALIZE_WITH_EXTRACTION_LABELING: {
      return state
        .set('currentQuestionnaireId', action.questionnaire.id)
        .set('currentQuestionnaireType', action.questionnaire.data_type)
        .set('currentQuestion', action.questionnaire.questions[0])
        .set(
          'currentLabels',
          [].concat(removeLabel, generateLabels(action.questionnaire.questions[0].targets, state))
        )
        .set('selectedLabel', {
          text: action.questionnaire.questions[0].targets[0],
          color: state.get('labelColors')[0],
        })
        .set(
          'examplesQuantity',
          setExampleQuantity(
            action.questionnaire.data_type || datasetTypes.IMAGE,
            action.questionnaire.questions[0].type,
            state.get('userExtractionQuantity'),
            state.get('userImageQuantity'),
            state.get('userTextQuantity')
          )
        );
    }

    case types.INITIALIZE_WITH_CLASSIFICATION_EXAMPLES:
      return state.set(
        'currentExamples',
        Object.keys(action.examples).reduce((prev, key) => {
          return Object.assign({}, prev, {
            [key]: Object.assign({}, action.examples[key], {
              labels: action.examples[key].labels.map((l) => {
                return state.get('currentLabels').filter((o) => o.text === l)[0];
              }),
            }),
          });
        }, {})
      );

    // case types.INITIALIZE_WITH_REGRESSION_EXAMPLES:
    //     return state.set('currentExamples', action.examples);

    case types.INITIALIZE_WITH_EXTRACTION_EXAMPLES:
      return state.set(
        'currentExamples',
        Object.keys(action.examples).reduce((prev, key) => {
          return Object.assign({}, prev, {
            [key]: Object.assign({}, action.examples[key], {
              labels: action.examples[key].labels.map((l) => {
                return state.get('currentLabels').filter((o) => o.text === l)[0];
              }),
            }),
          });
        }, {})
      );

    case types.LOAD_QUESTION_WITH_ADDED_LABEL:
      return state
        .set('newLabel', defaultState.get('newLabel'))
        .set('currentQuestion', action.question)
        .set(
          'currentLabels',
          [].concat(removeLabel, generateLabels(action.question.targets, state))
        );

    case types.CHANGE_SELECTED_CLASSIFICATION_LABEL:
      return state.set(
        'selectedLabel',
        []
          .concat(state.get('currentLabels'), state.get('newLabels'))
          .filter((o) => o.text === action.label)[0]
      );

    // case types.CHANGE_SELECTED_REGRESSION_LABEL:
    //     return state.set('selectedLabel', action.value);

    case types.CHANGE_NEW_LABEL:
      return state.set('newLabel', action.value);

    case types.TOGGLE_SAVING_NEW_LABEL:
      return state.set('savingNewLabel', action.saving);

    case types.SAVE_LAST_USED_LABEL:
      return state.set(
        'lastSelectedLabels',
        Object.assign({}, state.get('lastSelectedLabels'), {
          [action.questionIndex]: state.get('selectedLabel'),
        })
      );

    case types.FIRST_QUESTION: {
      return state
        .set('currentQuestion', action.questions[0])
        .set(
          'currentLabels',
          action.questions[0].targets.length !== 0
            ? [].concat(removeLabel, generateLabels(action.questions[0].targets, state))
            : [removeLabel]
        )
        .set(
          'selectedLabel',
          resolveSelectedLabel(
            action.questions[0],
            state.get('lastSelectedLabels')[0],
            state.get('labelColors')[0]
          )
        );
    }

    case types.NEXT_QUESTION: {
      return state
        .set('currentQuestion', action.questions[action.questionIndex + 1])
        .set(
          'currentLabels',
          action.questions[action.questionIndex + 1].targets.length !== 0
            ? [].concat(
                removeLabel,
                generateLabels(action.questions[action.questionIndex + 1].targets, state)
              )
            : [removeLabel]
        )
        .set(
          'selectedLabel',
          resolveSelectedLabel(
            action.questions[action.questionIndex + 1],
            state.get('lastSelectedLabels')[action.questionIndex + 1],
            state.get('labelColors')[0]
          )
        );
    }

    case types.RESET_EXAMPLES:
      return state
        .set('currentExamples', defaultState.get('currentExamples'))
        .set('queuedExamples', defaultState.get('queuedExamples'));

    case types.SAVING_EXAMPLES:
      return state.set('savingExamples', action.saving);

    case types.RESET_EXAMPLES_LABELS:
      return state.set(
        'currentExamples',
        Object.keys(state.get('currentExamples')).reduce((prev, key) => {
          return Object.assign({}, prev, {
            [key]: Object.assign({}, state.get('currentExamples')[key], { labels: [] }),
          });
        }, {})
      );

    case types.RECORD_SEEN_EXAMPLES:
      return state.set(
        'completedExamples',
        union(state.get('completedExamples'), action.completedExamples)
      );

    case types.TOGGLE_FETCHING_EXAMPLES:
      return state.set('fetchingExamples', action.fetching);

    case types.LABEL_CLASSIFICATION_EXAMPLE:
      return state.set(
        'currentExamples',
        Object.assign({}, state.get('currentExamples'), {
          [action.exampleId]: Object.assign({}, state.get('currentExamples')[action.exampleId], {
            predictions: Object.assign(
              {},
              state.get('currentExamples')[action.exampleId].predictions,
              {
                [state.get('currentQuestion').id]: [],
              }
            ),
            labels: state.get('selectedLabel').removeLabel ? [] : [state.get('selectedLabel')],
          }),
        })
      );

    case types.LABEL_CM_EXAMPLE:
      // prettier-ignore
      return state.set(
                'currentExamples',
                Object.assign({}, state.get('currentExamples'), {
                    [action.exampleId]: Object.assign(
                        {},
                        state.get('currentExamples')[action.exampleId],
                        {
                            predictions: Object.assign({}, state.get('currentExamples')[action.exampleId].predictions, {
                                [state.get('currentQuestion').id]: state
                                    .get('currentExamples')[action.exampleId]
                                    .predictions[state.get('currentQuestion').id].filter(
                                    p => p.label !== state.get('selectedLabel').text
                                ),
                            }),
                            labels: setCMLabels(
                                state.get('currentExamples')[action.exampleId].labels,
                                state.get('selectedLabel')
                            )
                        }
                    )
                })
            );

    case types.LABEL_EXTRACTION_EXAMPLE: {
      const exampleLabels = addExtraction(
        state.get('currentExamples')[action.exampleId].labels,
        action.startOffset,
        action.endOffset,
        state.get('selectedLabel')
      );

      // prettier-ignore
      return state.set(
                'currentExamples',
                Object.assign({}, state.get('currentExamples'), {
                    [action.exampleId]: Object.assign(
                        {},
                        state.get('currentExamples')[action.exampleId],
                        {
                            labels: exampleLabels,
                            predictions: Object.assign({}, state.get('currentExamples')[action.exampleId].predictions, {
                                [state.get('currentQuestion').id]: state
                                    .get('currentExamples')[action.exampleId]
                                    .predictions[state.get('currentQuestion').id].filter(p => {
                                        return !checkLabelsOverlap(
                                            {
                                                startOffset: action.startOffset,
                                                endOffset: action.endOffset
                                            },
                                            p
                                        );
                                    })
                            })
                        }
                    )
                })
            );
    }

    case types.ACCEPT_CLASSIFICATION_PREDICTION:
      // prettier-ignore
      return state.set(
                'currentExamples',
                Object.assign({}, state.get('currentExamples'), {
                    [action.exampleId]: Object.assign(
                        {},
                        state.get('currentExamples')[action.exampleId],
                        {
                            predictions: Object.assign(
                                {},
                                state.get('currentExamples')[action.exampleId].predictions,
                                {
                                    [state.get('currentQuestion').id]: state
                                        .get('currentExamples')[action.exampleId]
                                        .predictions[state.get('currentQuestion').id].filter(
                                            p => p.label !== action.labelObj.text
                                        )
                                }
                            ),
                            labels: [action.labelObj]
                        }
                    )
                })
            );

    case types.ACCEPT_CM_PREDICTION:
      // prettier-ignore
      return state.set(
                'currentExamples',
                Object.assign({}, state.get('currentExamples'), {
                    [action.exampleId]: Object.assign(
                        {},
                        state.get('currentExamples')[action.exampleId],
                        {
                            predictions: Object.assign({}, state.get('currentExamples')[action.exampleId].predictions, {
                                [state.get('currentQuestion').id]: state
                                    .get('currentExamples')[action.exampleId]
                                    .predictions[state.get('currentQuestion').id].filter(
                                        p => p.label !== action.labelObj.text
                                    )
                            }),
                            labels: setCMLabels(
                                state.get('currentExamples')[action.exampleId].labels,
                                action.labelObj
                            )
                        }
                    )
                })
            );

    case types.ACCEPT_EXTRACTION_PREDICTION:
      const exampleLabels = addExtraction(
        state.get('currentExamples')[action.exampleId].labels,
        action.startOffset,
        action.endOffset,
        action.labelObj
      );
      // prettier-ignore
      return state.set(
                'currentExamples',
                Object.assign({}, state.get('currentExamples'), {
                    [action.exampleId]: Object.assign(
                        {},
                        state.get('currentExamples')[action.exampleId],
                        {
                            labels: exampleLabels,
                            predictions: Object.assign({}, state.get('currentExamples')[action.exampleId].predictions, {
                                [state.get('currentQuestion').id]: state
                                .get('currentExamples')[action.exampleId].predictions[state.get('currentQuestion').id].filter(
                                    p => p.startOffset !== action.startOffset
                                )
                            })
                        }
                    )
                })
            );

    case types.ACCEPT_ALL_EXTRACTION_PREDICTIONS:
      // prettier-ignore
      return state.set(
                'currentExamples',
                Object.assign({}, state.get('currentExamples'), {
                    [action.exampleId]: Object.assign(
                        {},
                        state.get('currentExamples')[action.exampleId],
                        {
                            labels: batchAddExtractions(
                                state.get('currentExamples')[action.exampleId].labels,
                                action.convertedLabels
                            ),
                            predictions: Object.keys(state
                                .get('currentExamples')[action.exampleId].predictions).reduce((prev, cur) => {
                                    if (parseInt(cur) === state.get('currentQuestion').id) {
                                        return Object.assign({}, prev, {
                                            [cur]: []
                                        });
                                    } else {
                                        return Object.assign({}, prev, {
                                            [cur]: state.get('currentExamples')[action.exampleId].predictions[cur]
                                        });
                                    }
                                }, {})
                        }
                    )
                })
            );

    case types.REJECT_PREDICTION:
      // prettier-ignore
      return state.set(
                'currentExamples',
                Object.assign({}, state.get('currentExamples'), {
                    [action.exampleId]: Object.assign(
                        {},
                        state.get('currentExamples')[action.exampleId],
                        {
                            predictions: Object.assign({}, state.get('currentExamples')[action.exampleId].predictions, {
                                [state.get('currentQuestion').id]: state
                                    .get('currentExamples')[action.exampleId]
                                    .predictions[state.get('currentQuestion').id].filter(p => p.label !== action.label)
                            })
                        }
                    )
                })
            );

    case types.REJECT_EXAMPLE:
      return state.set(
        'currentExamples',
        Object.assign({}, state.get('currentExamples'), {
          [action.exampleId]: Object.assign({}, state.get('currentExamples')[action.exampleId], {
            rejected: true,
          }),
        })
      );

    case types.TOGGLE_REJECT_EXAMPLE:
      return state.set(
        'currentExamples',
        Object.assign({}, state.get('currentExamples'), {
          [action.exampleId]: Object.assign({}, state.get('currentExamples')[action.exampleId], {
            rejected: !state.get('currentExamples')[action.exampleId].rejected,
          }),
        })
      );

    case types.TOGGLE_LABELING_SWATCH:
      return state.set('hideCursorSwatch', action.hideSwatch);

    case types.CHANGE_USER_QUESTION_KEYWORDS:
      return state.set(
        'currentQuestion',
        Object.assign({}, state.get('currentQuestion'), {
          user_keywords: action.keywords,
        })
      );

    case types.LOAD_LABELING_SETTINGS:
      return state
        .set(
          'showPredictions',
          action.settings.hasOwnProperty(settingsKeys.USER_SETTING_SHOW_PREDICTIONS)
            ? action.settings[settingsKeys.USER_SETTING_SHOW_PREDICTIONS]
            : defaultState.get('showPredictions')
        )
        .set(
          'showKeywords',
          action.settings.hasOwnProperty(settingsKeys.USER_SETTING_SHOW_KEYWORDS)
            ? action.settings[settingsKeys.USER_SETTING_SHOW_KEYWORDS]
            : defaultState.get('showKeywords')
        )
        .set(
          'textSize',
          action.settings[settingsKeys.USER_SETTING_TEXT_SIZE] || defaultState.get('textSize')
        )
        .set(
          'extractionTheme',
          action.settings[settingsKeys.USER_SETTING_EXTRACTION_THEME] ||
            defaultState.get('extractionTheme')
        )
        .set(
          'userExtractionQuantity',
          action.settings[settingsKeys.USER_SETTING_EXTRACTION_QUANTITY] ||
            defaultState.get('userExtractionQuantity')
        )
        .set(
          'userImageQuantity',
          action.settings[settingsKeys.USER_SETTING_IMAGE_QUANTITY] ||
            defaultState.get('userImageQuantity')
        )
        .set(
          'userTextQuantity',
          action.settings[settingsKeys.USER_SETTING_TEXT_QUANTITY] ||
            defaultState.get('userTextQuantity')
        );

    case types.SET_EXAMPLES_QUANTITY:
      return state.set(
        'examplesQuantity',
        setExampleQuantity(
          state.get('currentQuestionnaireType'),
          state.get('currentQuestion').type,
          state.get('userExtractionQuantity'),
          state.get('userImageQuantity'),
          state.get('userTextQuantity')
        )
      );

    case types.CHANGE_SELECTED_EXAMPLE_INDEX:
      return state.set('selectedExampleIndex', action.exampleIndex);

    case types.TOGGLE_USER_PREDICTIONS_SETTING:
      return state.set('showPredictions', !state.get('showPredictions'));

    case types.TOGGLE_USER_TEXT_WRAP_SETTING:
      return state.set('textWrap', !state.get('textWrap'));

    case types.TOGGLE_USER_KEYWORDS_SETTING:
      return state.set('showKeywords', !state.get('showKeywords'));

    case types.CHANGE_USER_TEXT_SIZE_SETTING:
      return state.set('textSize', action.size);

    case types.CHANGE_USER_EXTRACTION_THEME:
      return state.set('extractionTheme', action.theme);

    case types.CHANGE_USER_IMAGE_QUANTITY:
      return state
        .set('userImageQuantity', action.quantity)
        .set('examplesQuantity', action.quantity);

    case types.CHANGE_USER_EXTRACTION_QUANTITY:
      return state
        .set('userExtractionQuantity', action.quantity)
        .set('examplesQuantity', action.quantity);

    case types.CHANGE_USER_TEXT_QUANTITY:
      return state
        .set('userTextQuantity', action.quantity)
        .set('examplesQuantity', action.quantity);

    case types.PUSH_ACTION_TO_HISTORY:
      return state.set('labelingHistory', [...state.get('labelingHistory'), action.historyItem]);

    case types.UNDO_ACTION_FROM_HISTORY:
      const labelingHistory = state.get('labelingHistory');
      const actionToUndo = labelingHistory[labelingHistory.length - 1];
      return state
        .set(
          'currentExamples',
          Object.assign({}, state.get('currentExamples'), {
            // We only want to recover the labels and prediction states, ignoring things like rejection status
            [actionToUndo.definition.exampleId]: Object.assign(
              {},
              state.get('currentExamples')[actionToUndo.definition.exampleId],
              {
                labels: actionToUndo.undoState.labels,
                predictions: actionToUndo.undoState.predictions,
              }
            ),
          })
        )
        .set('labelingHistory', labelingHistory.slice(0, -1));

    case types.CLEAR_UNDO_HISTORY:
      return state.set('labelingHistory', defaultState.get('labelingHistory'));

    case types.ADD_NEW_LABEL:
      return state
        .set(
          'newLabels',
          [].concat(
            state.get('newLabels'),
            generateLabels(
              [action.text],
              state,
              state.get('currentLabels').length + state.get('newLabels').length - 1
            )
          )
        )
        .set('newLabel', defaultState.get('newLabel'));

    case types.REMOVE_NEW_LABEL:
      return state
        .set(
          'newLabels',
          state
            .get('newLabels')
            .filter((label) => label.text !== action.text)
            .reduce((prev, cur, i) => {
              return [].concat(
                prev,
                generateLabels([cur.text], state, state.get('currentLabels').length + (i - 1))
              );
            }, [])
        )
        .set(
          'currentExamples',
          Object.keys(state.get('currentExamples'))
            .map((key) => state.get('currentExamples')[key])
            // Filters out removed label from examples
            .map((example) => {
              return Object.assign({}, example, {
                labels:
                  example.labels[0] && example.labels[0].endOffset
                    ? example.labels.filter((l) => l.label.text !== action.text)
                    : example.labels.filter((l) => l.text !== action.text),
              });
            })
            // Return same shape as received
            .reduce((prev, cur) => {
              return Object.assign({}, prev, {
                [cur.id]: cur,
              });
            }, {})
        )
        .set(
          'selectedLabel',
          action.text === state.get('selectedLabel').text ? {} : state.get('selectedLabel')
        );

    case types.RECALCULATE_EXAMPLE_LABEL_COLORS:
      return state.set(
        'currentExamples',
        Object.keys(state.get('currentExamples'))
          .map((key) => state.get('currentExamples')[key])
          // Reset the colors of existing example labels in case they have shifted from removal
          .map((example) => {
            return Object.assign({}, example, {
              labels: example.labels.map((label) => {
                if (label.label) {
                  return Object.assign({}, label, {
                    label: find(
                      [].concat(state.get('currentLabels'), state.get('newLabels')),
                      (o) => o.text === label.label.text
                    ),
                  });
                } else {
                  return Object.assign({}, label, {
                    color: find(
                      [].concat(state.get('currentLabels'), state.get('newLabels')),
                      (o) => o.text === label.text
                    ).color,
                  });
                }
              }),
            });
          })
          // Return same shape as received
          .reduce((prev, cur) => {
            return Object.assign({}, prev, {
              [cur.id]: cur,
            });
          }, {})
      );

    case types.RESET_NEW_LABELS:
      return state.set('newLabels', defaultState.get('newLabels'));

    case types.TOGGLE_SETTINGS_OPEN:
      return state.set('settingsOpen', !state.get('settingsOpen'));

    default:
      return state;
  }
};

export const getCurrentQuestionnaireId = (state) => state.get('currentQuestionnaireId');
export const getCurrentQuestion = (state) => state.get('currentQuestion');
export const getCurrentLabels = (state) => state.get('currentLabels');
export const getNewLabels = (state) => state.get('newLabels');
export const getCurrentAndNewLabels = (state) => [
  ...state.get('currentLabels'),
  ...state.get('newLabels'),
];
export const getLabelColors = (state) => state.get('labelColors');
export const getQueuedExamples = (state) => state.get('queuedExamples');
export const getExamples = (state) => state.get('currentExamples');
export const getSavingExamples = (state) => state.get('savingExamples');
export const getSelectedLabel = (state) => state.get('selectedLabel');
export const getSelectedExampleIndex = (state) => state.get('selectedExampleIndex');
export const getExamplesQuantity = (state) => state.get('examplesQuantity');
export const getUserImageQuantity = (state) => state.get('userImageQuantity');
export const getUserExtractionQuantity = (state) => state.get('userExtractionQuantity');
export const getUserTextQuantity = (state) => state.get('userTextQuantity');
export const getFetchingExamples = (state) => state.get('fetchingExamples');
export const getUserShowPredictions = (state) => state.get('showPredictions');
export const getUserToggleTextWrap = (state) => state.get('textWrap');
export const getUserShowKeywords = (state) => state.get('showKeywords');
export const getUserTextSize = (state) => state.get('textSize');
export const getUserExtractionTheme = (state) => state.get('extractionTheme');
export const getNewLabel = (state) => state.get('newLabel');
export const getSavingNewLabel = (state) => state.get('savingNewLabel');
export const getHideCursorSwatch = (state) => state.get('hideCursorSwatch');
export const getLabelingHistory = (state) => state.get('labelingHistory');
export const getCompletedExamples = (state) => state.get('completedExamples');
export const getSettingsOpen = (state) => state.get('settingsOpen');
