import { fetchUtils } from '@indico-data/utils';
import * as endpoints from 'root/constants/endpoints';
import * as settingsKeys from 'root/constants/user-settings';

export const getUserIndicoAppSettingsAPI = () => {
  return fetchUtils.getAuthJSON(`${endpoints.USER_PREF_ENDPOINT}/indicoAppSettings`);
};

export const saveUserSidebarOpenSettingAPI = (sidebarOpen) => {
  return fetchUtils.putAuthJSON(
    `${endpoints.USER_PREF_ENDPOINT}/indicoAppSettings/${settingsKeys.USER_SETTING_SIDEBAR_OPEN}`,
    sidebarOpen
  );
};

export const getXSRFTokenAPI = () => {
  return fetchUtils
    .getAuthJSON(endpoints.XSRF_ENDPOINT)
    .then((json) => {
      return json._xsrf;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
