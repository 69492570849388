import keyBy from 'lodash/keyBy';
import { Observable } from 'rxjs/Observable';

// App
import { fetchUtils } from '@indico-data/utils';
// import * as endpoints from '../constants/endpoints';

export const getModelGroupsAPI = (datasetID, subsetID, observable = false) => {
  const api = fetchUtils
    .getMoonbowJSON(
      // `${endpoints.DATASET_ENDPOINT}/${datasetID}/model_groups?subset_id=${subsetID}`
      `/api/model_groups?filters={"subset_id": ${subsetID} }`
    )
    .then((json) => {
      const modifiedModels = json.model_groups;
      return keyBy(modifiedModels, 'id');
    })
    .catch((err) => {
      return Promise.reject(err);
    });

  return observable ? Observable.from(api) : api;
};

export const postNewModel = (dataset, newModel) => {
  const toJSNewModel = newModel.toJS();
  const { name, columnID, subsetID, multiple } = toJSNewModel;

  return fetchUtils
    .postMoonbowJSON(
      // `${endpoints.DATASET_ENDPOINT}/${datasetID}/model_groups`
      `/api/model_groups`,
      {
        dataset_id: dataset.id,
        name: name,
        source_column_id: columnID,
        subset_id: subsetID,
        multilabel: multiple,
        labelset_options: {},
        make_predictions: true,
        // docbot_id: dataset.fireGroup.id
      }
    )
    .then((json) => {
      return json;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const putRetrainModel = (datasetID, modelGroupId) => {
  return fetchUtils
    .putMoonbowJSON(
      // `${endpoints.DATASET_ENDPOINT}/${datasetID}/model_groups/${modelGroupID}/retrain`,
      `/api/model_groups/${modelGroupId}/retrain`,
      {}
    )
    .then((json) => {
      return json;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
