import Color from 'color';

import { easingUtils } from '@indico-data/utils';
import { COLORS } from '@indico-data/permafrost';

export const DEFAULT_POINT_COLOR = COLORS.white;
export const HL_STATES = { NORMAL: 'NORMAL', HIGHLIGHTED: 'HIGHLIGHTED', FADED: 'FADED' };
export const DEFAULT_TWEEN_TIME = 500;

class TSnePoint {
  // Public Properties
  rowID = null;
  selected = false;

  // Private Properties
  _currentXPosition = 0;
  _currentYPosition = 0;
  _currentColor = Color(DEFAULT_POINT_COLOR);
  _highlighted = HL_STATES.NORMAL;

  _tweenPosStartTime = -1;
  _tweenPosDuration = 0;
  _tweenPosStartValue = {};
  _tweenPosTargetValue = {};
  _tweenPosEasing = easingUtils.Quadratic.InOut;

  _tweenColorStartTime = -1;
  _tweenColorDuration = 0;
  _tweenColorStartValue = '';
  _tweenColorTargetValue = '';
  _tweenColorEasing = easingUtils.Quadratic.InOut;

  constructor(id, xPos, yPos) {
    this.rowID = id;
    this._currentXPosition = xPos;
    this._currentYPosition = yPos;
  }

  get currentColor() {
    return this._currentColor.rgb().string();
  }

  get currentPosition() {
    return {
      x: this._currentXPosition,
      y: this._currentYPosition,
    };
  }

  get highlightState() {
    return this._highlighted;
  }

  selectPoint() {
    this.selected = true;
  }

  deselectPoint() {
    this.selected = false;
  }

  changePointColor(newColor, tweenDuration = DEFAULT_TWEEN_TIME) {
    if (tweenDuration <= 0) {
      this._currentColor = Color(newColor);
    } else {
      this._tweenColorStartValue = this._currentColor.rgb().array();
      this._tweenColorDuration = tweenDuration;
      this._tweenColorTargetValue = Color(newColor).rgb().array();
      this._tweenColorStartTime = performance.now();
    }
  }

  setPosition(xPos, yPos, tweenDuration = DEFAULT_TWEEN_TIME) {
    this._tweenPosStartValue = this.currentPosition;
    this._tweenPosTargetValue = { x: xPos, y: yPos };
    this._tweenPosDuration = tweenDuration;
    this._tweenPosStartTime = performance.now();
  }

  setHighlightState(state) {
    this._highlighted = state;
  }

  resetPointStyle() {
    this._currentColor = Color(DEFAULT_POINT_COLOR);
  }

  tweenStep() {
    const stepTime = performance.now();
    // Step Color
    if (stepTime < this._tweenColorStartTime + this._tweenColorDuration) {
      this._stepColor(stepTime);
    }

    // Step Position
    if (stepTime < this._tweenPosStartTime + this._tweenPosDuration) {
      this._stepPosition(stepTime);
    }
  }

  _stepColor(stepTime) {
    const pos = stepTime - this._tweenColorStartTime;
    const percent = pos / this._tweenColorDuration;

    const frame = this._tweenColorTargetValue.map((val, i) => {
      return (
        this._tweenColorStartValue[i] +
        (val - this._tweenColorStartValue[i]) * this._tweenColorEasing(percent)
      );
    });

    this._currentColor = Color.rgb(frame);
  }

  _stepPosition(stepTime) {
    const pos = stepTime - this._tweenPosStartTime;
    const percent = pos / this._tweenPosDuration;

    this._currentXPosition =
      this._tweenPosStartValue.x +
      (this._tweenPosTargetValue.x - this._tweenPosStartValue.x) * this._tweenPosEasing(percent);
    this._currentYPosition =
      this._tweenPosStartValue.y +
      (this._tweenPosTargetValue.y - this._tweenPosStartValue.y) * this._tweenPosEasing(percent);
  }
}

export default TSnePoint;
