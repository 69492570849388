import { Observable } from 'rxjs/Observable';

// Elmosfire
import * as types from 'Elmosfire/actions/action-types';
import { getTSNEStatus } from 'Elmosfire/providers/tsne-provider';
import { resolveTSNEReady, failTSNEReady } from 'Elmosfire/actions/tsne-actions';
import { POLLING_TIME } from 'Elmosfire/constants/polling-config';

const fireTSNEEpic = (action$) =>
  action$.ofType(types.GET_TSNE_READY_POLL_START).switchMap((action) =>
    Observable.timer(0, POLLING_TIME)
      .takeUntil(action$.ofType(types.GET_TSNE_READY_POLL_STOP))
      .exhaustMap(() =>
        getTSNEStatus(action.datasetId, action.clusterId)
          .map((res) => resolveTSNEReady(res.status, action.datasetId, action.clusterId))
          .catch((error) => Observable.of(failTSNEReady(error, action.datasetId)))
      )
  );

export default fireTSNEEpic;
