export const CLASSIFICATION = 'CLASSIFICATION';
export const CLASSIFICATION_MULTIPLE = 'CLASSIFICATION_MULTIPLE';
export const EXTRACTION = 'ANNOTATION';
export const DEFAULT_LABEL_COLOR = '#191919';

export const MODEL_TYPE = {
  CLASSIFICATION,
  CLASSIFICATION_MULTIPLE,
  EXTRACTION,
} as const;
