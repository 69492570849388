// Fire Groups - DATASETS
export const TOGGLE_FETCHING_ELMOSFIRE_DATASETS = 'TOGGLE_FETCHING_ELMOSFIRE_DATASETS';
export const GET_ELMOSFIRE_DATASETS = 'GET_ELMOSFIRE_DATASETS';
export const GET_ELMOSFIRE_DATASET = 'GET_ELMOSFIRE_DATASET';
export const REMOVE_ELMOSFIRE_DATASET = 'REMOVE_ELMOSFIRE_DATASET';
export const SET_OVERLAY = 'SET_OVERLAY';
export const CLEAR_OVERLAY = 'CLEAR_OVERLAY';
export const SORT_ELMOSFIRE_DATASETS = 'SORT_ELMOSFIRE_DATASETS';
export const CHANGE_ELMOSFIRE_DATASET_NAME = 'CHANGE_ELMOSFIRE_DATASET_NAME';
export const TOGGLE_UPDATING_ELMOSFIRE_DATASET = 'TOGGLE_UPDATING_ELMOSFIRE_DATASET';

// TSNE Actions
export const GET_TSNE_READY_POLL_START = 'GET_TSNE_READY_POLL_START';
export const GET_TSNE_READY_POLL_STOP = 'GET_TSNE_READY_POLL_STOP';
export const GET_TSNE_STATUS = 'GET_TSNE_STATUS';
export const GET_TSNE_CLUSTER = 'GET_TSNE_CLUSTER';
export const CHANGE_QUERY = 'CHANGE_QUERY';
export const CLEAR_TSNE = 'CLEAR_TSNE';
export const CLEAR_TSNE_CLUSTER = 'CLEAR_TSNE_CLUSTER';
export const RESET_TSNE_COLOR = 'RESET_TSNE_COLOR';
export const CLEAR_TSNE_HIGHLIGHTED = 'CLEAR_TSNE_HIGHLIGHTED';
export const CLEAR_TSNE_HIGHLIGHTED_AND_FILTERS = 'CLEAR_TSNE_HIGHLIGHTED_AND_FILTERS';
export const TOGGLE_FETCHING_OVERLAY_DATA = 'TOGGLE_FETCHING_OVERLAY_DATA';
export const GET_QUERIED_SIMILARITY = 'GET_QUERIED_SIMILARITY';
export const ADD_TSNE_OVERLAY_DATA = 'ADD_TSNE_OVERLAY_DATA';
export const COLOR_TSNE_BASED_ON_COLLECTION = 'COLOR_TSNE_BASED_ON_COLLECTION';
export const CLEAR_ITEMS_SELECTED = 'CLEAR_ITEMS_SELECTED';
export const CHANGE_ITEMS_SELECTED = 'CHANGE_ITEMS_SELECTED';
export const TOGGLE_TSNE_LABEL_MENU = 'TOGGLE_TSNE_LABEL_MENU';
export const SET_TSNE_OVERLAY_NAME = 'SET_TSNE_OVERLAY_NAME';

// Word Cloud Actions
export const GET_WORDCLOUD_READY_POLL_START = 'GET_WORDCLOUD_READY_POLL_START';
export const GET_WORDCLOUD_READY_POLL_STOP = 'GET_WORDCLOUD_READY_POLL_STOP';
export const SET_WORDCLOUD_READY = 'SET_WORDCLOUD_READY';
export const SET_WORDCLOUD_STATUS = 'SET_WORDCLOUD_STATUS';
export const TOGGLE_LOADING_WORDCLOUD = 'TOGGLE_LOADING_WORDCLOUD';
export const CLEAR_WORD_CLOUD = 'CLEAR_WORD_CLOUD';
export const SET_WORD_CLOUD = 'SET_WORD_CLOUD';

// Selected Documents Actions
export const CLEAR_ROWS = 'CLEAR_ROWS';
export const TOGGLE_FETCHING_ROWS = 'TOGGLE_FETCHING_ROWS';
export const GET_ROWS = 'GET_ROWS';
export const LABEL_EXAMPLE = 'LABEL_EXAMPLE';
export const REMOVE_EXAMPLE_LABELS = 'REMOVE_EXAMPLE_LABELS';
export const TOGGLE_REJECT_ELMOSFIRE_EXAMPLE = 'TOGGLE_REJECT_ELMOSFIRE_EXAMPLE';
export const CLEAR_ROW_LABELS = 'CLEAR_ROW_LABELS'; // Removes labels from selected rows
export const CLEAR_SD_LABELS = 'CLEAR_SD_LABELS'; // Clears out labels that can be applied (menu of labels)
export const REMOVE_COLOR_FROM_LABEL_COLORS = 'REMOVE_COLOR_FROM_LABEL_COLORS';
export const CLEAR_SELECTED_LABEL = 'CLEAR_SELECTED_LABEL';
export const CHANGE_SELECTED_LABEL = 'CHANGE_SELECTED_LABEL';
export const LOAD_LABELS = 'LOAD_LABELS';
export const CHANGE_NEW_ELMOSFIRE_LABEL = 'CHANGE_NEW_ELMOSFIRE_LABEL';
export const SAVE_NEW_LABEL = 'SAVE_NEW_LABEL';
export const REMOVE_LABEL = 'REMOVE_LABEL';
export const TOGGLE_SD_MENU = 'TOGGLE_SD_MENU';
export const TOGGLE_ADD_LABEL_DISPLAY_INPUT = 'TOGGLE_ADD_LABEL_DISPLAY_INPUT';
export const TOGGLE_LABELED_CONFIRMATION = 'TOGGLE_LABELED_CONFIRMATION';
export const SAVING_SD_LABELS = 'SAVING_SD_LABELS';
export const TOGGLE_HIDE_SWATCH = 'TOGGLE_HIDE_SWATCH';
export const TOGGLE_SAVING_NEW_ELMOSFIRE_LABEL = 'TOGGLE_SAVING_NEW_ELMOSFIRE_LABEL';

// Highlight and Filer Actions
export const ADD_LABEL_HIGHLIGHT = 'ADD_LABEL_HIGHLIGHT';
export const REMOVE_LABEL_HIGHLIGHT = 'REMOVE_LABEL_HIGHLIGHT';
export const ADD_FIRE_FILTER = 'ADD_FIRE_FILTER';
export const CLEAR_FIRE_FILTER = 'CLEAR_FIRE_FILTER';

// Fire Actions
export const SET_FIRE = 'SET_FIRE';
export const LOADING_FIRE = 'LOADING_FIRE';

// Model Group Actions
export const TOGGLE_FETCHING_MODEL_GROUPS = 'TOGGLE_FETCHING_MODEL_GROUPS';
export const GET_MODEL_GROUPS = 'GET_MODEL_GROUPS';
export const ADD_NEW_MODEL_GROUP = 'ADD_NEW_MODEL_GROUP';
export const TOGGLE_FETCHING_MODEL_GROUPS_COUNTS = 'TOGGLE_FETCHING_MODEL_GROUPS_COUNTS';
export const INITIALIZE_TSNE_MENU = 'INITIALIZE_TSNE_MENU';
export const RETRAIN_MODEL_GROUP_POLL_START = 'RETRAIN_MODEL_GROUP_POLL_START';
export const RETRAIN_MODEL_GROUP_POLL_STOP = 'RETRAIN_MODEL_GROUP_POLL_STOP';

// Overlays Actions
export const GET_OUT_OF_BOX_OVERLAYS = 'GET_OUT_OF_BOX_OVERLAYS';
export const GET_OUT_OF_BOX_LABELSET_ID = 'GET_OUT_OF_BOX_LABELSET_ID';
export const CAN_RETRAIN = 'CAN_RETRAIN';
export const CHANGE_OVERLAY_INPUT_FILTER = 'CHANGE_OVERLAY_INPUT_FILTER';
export const TOGGLE_RETRAINING = 'TOGGLE_RETRAINING';
export const INCREMENT_RETRAIN_PROGRESS = 'INCREMENT_RETRAIN_PROGRESS';
export const RESET_RETRAIN_PROGRESS = 'RESET_RETRAIN_PROGRESS';

// Label Counting
export const GET_OVERLAY_LABEL_COUNT_POLL_START = 'GET_OVERLAY_LABEL_COUNT_POLL_START';
export const GET_OVERLAY_LABEL_COUNT_POLL_STOP = 'GET_OVERLAY_LABEL_COUNT_POLL_STOP';
export const GET_OVERLAY_LABEL_COUNTS = 'GET_OVERLAY_LABEL_COUNTS';
export const GET_OVERLAY_PREDICTED_LABEL_COUNT_POLL_START =
    'GET_OVERLAY_PREDICTED_LABEL_COUNT_POLL_START';
export const GET_OVERLAY_PREDICTED_LABEL_COUNT_POLL_STOP =
    'GET_OVERLAY_PREDICTED_LABEL_COUNT_POLL_STOP';
export const GET_OVERLAY_PREDICTED_LABEL_COUNTS = 'GET_OVERLAY_PREDICTED_LABEL_COUNTS';

// Widget Layout Actions
export const ADD_WIDGET = 'ADD_WIDGET';
export const REMOVE_WIDGET = 'REMOVE_WIDGET';
export const MOVE_WIDGET_UP = 'MOVE_WIDGET_UP';
export const MOVE_WIDGET_DOWN = 'MOVE_WIDGET_DOWN';
export const LOAD_USER_LAYOUT = 'LOAD_USER_LAYOUT';

// New Fire Group Actions
export const CHANGE_NEW_FIREGROUP_NAME = 'CHANGE_NEW_FIREGROUP_NAME';
export const CHANGE_SELECTED_ELMOSFIRE_DATASET = 'CHANGE_SELECTED_ELMOSFIRE_DATASET';
export const CHANGE_SELECTED_ELMOSFIRE_COLUMN = 'CHANGE_SELECTED_ELMOSFIRE_COLUMN';
export const RESET_NEW_FIREGROUP_FORM = 'RESET_NEW_FIREGROUP_FORM';

// New Model Actions
export const CHANGE_NEW_MODEL_NAME = 'CHANGE_NEW_MODEL_NAME';
export const CHANGE_NEW_MODEL_LABELING_TYPE = 'CHANGE_NEW_MODEL_LABELING_TYPE';
export const CHANGE_NEW_MODEL_COLUMN_ID = 'CHANGE_NEW_MODEL_COLUMN_ID';
export const CHANGE_NEW_MODEL_SUBSET_ID = 'CHANGE_NEW_MODEL_SUBSET_ID';
export const TOGGLE_SAVING_MODEL = 'TOGGLE_SAVING_MODEL';
export const RESET_NEW_MODEL_FORM = 'RESET_NEW_MODEL_FORM';
export const SAVED_MODEL = 'SAVED_MODEL';
