import { Map } from 'immutable';

// Elmosfire
import * as types from 'Elmosfire/actions/action-types';

export const defaultState = Map({
  vectorizerReady: false,
  status: '',
  loading: false,
  words: [],
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_WORDCLOUD_READY:
      return state.set('vectorizerReady', action.ready);

    case types.SET_WORDCLOUD_STATUS:
      return state.set('status', action.status);

    case types.TOGGLE_LOADING_WORDCLOUD:
      return state.set('loading', action.loading);

    case types.CLEAR_WORD_CLOUD:
      return state.set('words', defaultState.get('words'));

    case types.SET_WORD_CLOUD:
      return state.set('loading', false).set('words', action.words);

    default:
      return state;
  }
};

export const getWordCloudReady = (state) => state.get('vectorizerReady');
export const getWordCloudStatus = (state) => state.get('status');
export const getWordCloudLoading = (state) => state.get('loading');
export const getWordCloud = (state) => state.get('words') || [];
