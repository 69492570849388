import React, { useContext } from 'react';

import UserContext from './store/context';

import { MainRouter } from './MainRouter';

export const AppContainer = () => {
  const dispatch = useContext(UserContext)[1];
  return <MainRouter updateUserContext={dispatch} />;
};
