import * as types from 'Auth/actions/action-types';

type State = {
  loading: boolean;
  confirmToken: string;
  tokenParsed: boolean;
  complete: boolean;
  result: string | null;
};

const defaultState: State = {
  loading: false,
  confirmToken: '',
  tokenParsed: false,
  complete: false,
  result: null,
};

export default (
  state = defaultState,
  action: { type: keyof typeof types; token: string }
): State => {
  switch (action.type) {
    case types.RESET_CONFIRM:
      return defaultState;

    case types.CHANGE_CONFIRM_ACCOUNT_TOKEN:
      return { ...state, confirmToken: action.token, loading: true, tokenParsed: true };

    case types.CONFIRM_ACCOUNT:
      return { ...state, loading: true, complete: false };

    case types.RESOLVE_CONFIRM_ACCOUNT:
      return { ...state, loading: false, complete: true, result: null };

    case types.REJECT_CONFIRM_ACCOUNT:
      return { ...state, loading: false, complete: true };

    default:
      return state;
  }
};

export const getConfirmTokenParsed = (state: State) => state.tokenParsed;
export const getConfirmToken = (state: State) => state.confirmToken;
export const getConfirmLoading = (state: State) => state.loading;
export const getConfirmComplete = (state: State) => state.complete;
export const getConfirmResult = (state: State) => state.result;
