import { Observable } from 'rxjs/Observable';

// Elmosfire
import * as types from 'Elmosfire/actions/action-types';
import { getModelGroupsAPI } from 'Elmosfire/providers/modelgroups-provider';
import {
  completeRetrainModelGroup,
  failRetrainModelGroup,
} from 'Elmosfire/actions/overlays-actions';
import { POLLING_TIME } from 'Elmosfire/constants/polling-config';

const retrainModelGroupEpic = (action$) =>
  action$.ofType(types.RETRAIN_MODEL_GROUP_POLL_START).switchMap((action) =>
    Observable.timer(0, POLLING_TIME)
      .takeUntil(action$.ofType(types.RETRAIN_MODEL_GROUP_POLL_STOP))
      .exhaustMap(() => {
        return getModelGroupsAPI(action.datasetID, action.subsetID, true)
          .map((res) => completeRetrainModelGroup(res, action.datasetID, action.modelID))
          .catch((error) => Observable.of(failRetrainModelGroup(error)));
      })
  );

export default retrainModelGroupEpic;
