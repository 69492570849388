import { Map } from 'immutable';

import * as types from 'Crowdlabel/actions/action-types';
import * as labeling from 'Crowdlabel/constants/labeling-types';
import * as datasetTypes from 'Crowdlabel/constants/dataset-types';

let questionId = 1;
let answerId = 1;

export const defaultQuestion = {
  questionId: `question${questionId}`,
  type: labeling.CLASSIFICATION,
  name: '',
  keywords: [],
  answers: [`answer${answerId}`, `answer${answerId + 1}`],
};

export const defaultExtractionQuestion = {
  questionId: `question${questionId}`,
  type: labeling.EXTRACTION,
  name: '',
  keywords: [],
  answers: [`answer${answerId}`],
};

export const defaultAnswer = '';

export const defaultState = Map({
  displayChangeFirstQuestionDialog: false,
  potentialNewFirstQuestionValue: null,
  selectedDataset: '',
  selectedColumn: '',
  labelersPerTask: 1,
  name: '',
  type: datasetTypes.TEXT,
  questions: {
    [`question${questionId}`]: defaultQuestion,
  },
  answers: {
    [`answer${answerId}`]: defaultAnswer,
    [`answer${++answerId}`]: defaultAnswer,
  },
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.INCREMENT_LABELERS:
      return state.set('labelersPerTask', parseInt(state.get('labelersPerTask'), 10) + 1);

    case types.DECREMENT_LABELERS:
      return state.set('labelersPerTask', parseInt(state.get('labelersPerTask'), 10) - 1);

    case types.CHANGE_LABELERS:
      return state.set('labelersPerTask', action.value);

    case types.CHANGE_NEW_QUESTIONNAIRE_NAME:
      return state.set('name', action.value);

    case types.CHANGE_NEW_QUESTIONNAIRE_TYPE:
      return state.set('type', action.value);

    case types.CHANGE_QUESTION_NAME:
      return state.set(
        'questions',
        Object.assign({}, state.get('questions'), {
          [action.questionId]: Object.assign({}, state.get('questions')[action.questionId], {
            name: action.value,
          }),
        })
      );

    case types.CHANGE_QUESTION_LABELING_TYPE:
      return state.set(
        'questions',
        Object.assign({}, state.get('questions'), {
          [action.questionId]: Object.assign({}, state.get('questions')[action.questionId], {
            type: action.value,
          }),
        })
      );

    case types.CHANGE_OTHER_QUESTIONS_LABELING_TYPE:
      return state.set(
        'questions',
        Object.keys(state.get('questions')).reduce((prev, cur) => {
          if (cur === Object.keys(state.get('questions'))[0]) {
            return Object.assign({}, prev, {
              [cur]: state.get('questions')[cur],
            });
          }
          return Object.assign({}, prev, {
            [cur]: Object.assign({}, state.get('questions')[cur], {
              type: action.labelingType,
            }),
          });
        }, {})
      );

    case types.CHANGE_SELECTED_DATASET:
      return state
        .set('selectedDataset', action.dataset)
        .set('selectedColumn', defaultState.get('selectedColumn'));

    case types.CHANGE_SELECTED_COLUMN:
      return state.set('selectedColumn', action.column);

    case types.SHOW_CHANGE_FIRST_QUESTION_DIALOG:
      return state
        .set('displayChangeFirstQuestionDialog', true)
        .set('potentialNewFirstQuestionValue', action.value);

    case types.HIDE_CHANGE_FIRST_QUESTION_DIALOG:
      return state.set('displayChangeFirstQuestionDialog', false);

    case types.ADD_ANOTHER_QUESTION:
      return state.get('questions')[`${Object.keys(state.get('questions'))[0]}`].type ===
        labeling.EXTRACTION
        ? state
            .set(
              'questions',
              Object.assign({}, state.get('questions'), {
                [`question${++questionId}`]: Object.assign({}, defaultQuestion, {
                  questionId: `question${questionId}`,
                  answers: [`answer${++answerId}`],
                  type: labeling.EXTRACTION,
                }),
              })
            )
            .set(
              'answers',
              Object.assign({}, state.get('answers'), {
                [`answer${answerId}`]: defaultAnswer,
              })
            )
        : state
            .set(
              'questions',
              Object.assign({}, state.get('questions'), {
                [`question${++questionId}`]: Object.assign({}, defaultQuestion, {
                  questionId: `question${questionId}`,
                  answers: [`answer${++answerId}`, `answer${++answerId}`],
                  type: labeling.CLASSIFICATION,
                }),
              })
            )
            .set(
              'answers',
              Object.assign({}, state.get('answers'), {
                [`answer${answerId - 1}`]: defaultAnswer,
                [`answer${answerId}`]: defaultAnswer,
              })
            );
    case types.DELETE_QUESTION:
      return state.set(
        'questions',
        Object.keys(state.get('questions')).reduce((result, key) => {
          if (key !== action.questionId) {
            result[key] = state.get('questions')[key];
          }
          return result;
        }, {})
      );

    case types.ADD_CLASSIFICATION_ANSWER:
      return state
        .set(
          'answers',
          Object.assign({}, state.get('answers'), {
            [`answer${++answerId}`]: defaultAnswer,
          })
        )
        .set(
          'questions',
          Object.assign({}, state.get('questions'), {
            [action.questionId]: Object.assign({}, state.get('questions')[action.questionId], {
              answers: [].concat(
                state.get('questions')[action.questionId].answers,
                `answer${answerId}`
              ),
            }),
          })
        );

    case types.CHANGE_CLASSIFICATION_ANSWER:
      return state.set(
        'answers',
        Object.assign({}, state.get('answers'), {
          [action.answerId]: action.value,
        })
      );

    case types.DELETE_CLASSIFICATION_ANSWER:
      return state
        .set(
          'answers',
          Object.keys(state.get('answers')).reduce((result, key) => {
            if (key !== action.answerId) {
              result[key] = state.get('answers')[key];
            }
            return result;
          }, {})
        )
        .set(
          'questions',
          Object.assign({}, state.get('questions'), {
            [action.questionId]: Object.assign(
              {},
              state.get('questions')[action.questionId],
              // prettier-ignore
              { answers: state
                                .get('questions')[action.questionId].answers.filter(e => e !== action.answerId)
                            }
            ),
          })
        );

    case types.CHANGE_QUESTION_KEYWORDS:
      return state.set(
        'questions',
        Object.assign({}, state.get('questions'), {
          [action.questionId]: Object.assign({}, state.get('questions')[action.questionId], {
            keywords: action.keywords,
          }),
        })
      );

    case types.RESET_NEW_QUESTIONNAIRE_FORM:
      return state.merge(defaultState);

    default:
      return state;
  }
};

export const getNewQuestionnaireName = (state) => state.get('name');
export const getSelectedDataset = (state) => state.get('selectedDataset');
export const getSelectedColumn = (state) => state.get('selectedColumn');
export const getNewQuestionnaireType = (state) => state.get('type');
export const getNewQuestionnaireLabelersPerTask = (state) => state.get('labelersPerTask');
export const getNewQuestionnaireQuestions = (state) => state.get('questions');
export const getNewQuestionnaireAnswers = (state) => state.get('answers');
export const getDisplayChangeFirstQuestionDialog = (state) =>
  state.get('displayChangeFirstQuestionDialog');
export const getPotentialNewFirstQuestionValue = (state) =>
  state.get('potentialNewFirstQuestionValue');
