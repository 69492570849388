import React from 'react';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';

/**
 * Returns true of the start and end offsets of two labels overlap at all.
 * @param label1
 * @param label2
 * @returns {boolean}
 */
export const checkLabelsOverlap = (label1, label2) => {
  return (
    (label1.startOffset > label2.startOffset && label1.startOffset < label2.endOffset) ||
    (label1.endOffset > label2.startOffset && label1.endOffset < label2.endOffset) ||
    (label1.startOffset <= label2.startOffset && label1.endOffset >= label2.endOffset)
  );
};

/**
 * Adds a new selection to the provided list of selections. If the new selection connects to any existing
 * selection by either overlap or having 0 space between, it will be merged with that existing selection.
 * @param selections
 * @param startOffset
 * @param endOffset
 * @param selectedLabel
 * @returns {Array|*} Updated Selections
 */
export const resolveAddExtraction = (selections, startOffset, endOffset, selectedLabel) => {
  let skipAdd = false;
  const newSelections = [];

  for (let i = 0; i < selections.length; i++) {
    const sel = selections[i];

    // Re-add all existing selections not our currentLabel and move on
    if (sel.label !== selectedLabel) {
      newSelections.push({
        label: sel.label,
        startOffset: sel.startOffset,
        endOffset: sel.endOffset,
      });
      continue;
    } else if (startOffset <= sel.startOffset && endOffset >= sel.endOffset) {
      // If the selection is completely contained by the new selection, we won't re-add it
      continue;
    } else if (startOffset > sel.startOffset && endOffset < sel.endOffset) {
      // If the new selection is completely contained by the existing selection
      skipAdd = true;
      newSelections.push({
        label: sel.label,
        startOffset: sel.startOffset,
        endOffset: sel.endOffset,
      });
    } else if (
      startOffset < sel.startOffset &&
      endOffset >= sel.startOffset &&
      endOffset <= sel.endOffset
    ) {
      // New label starts ahead and butts against or intersects existing label
      endOffset = sel.endOffset;
    } else if (
      startOffset > sel.startOffset &&
      startOffset <= sel.endOffset &&
      endOffset > sel.endOffset
    ) {
      // New label starts inside of or butts against end of existing label
      startOffset = sel.startOffset;
    } else if (sel.endOffset < startOffset || sel.startOffset > endOffset) {
      // Existing selection goes completely before or after the new selection
      newSelections.push({
        label: sel.label,
        startOffset: sel.startOffset,
        endOffset: sel.endOffset,
      });
    }
  }

  if (!skipAdd) {
    newSelections.push({
      label: selectedLabel,
      startOffset: startOffset,
      endOffset: endOffset,
    });
  }

  return sortBy(newSelections, [
    function (o) {
      return o.startOffset;
    },
  ]);
};

/**
 * Removes and trims all selections that fit within the provided bounds
 * with no regard given to individual lables.
 * @param selections
 * @param startOffset
 * @param endOffset
 * @returns {Array|*} Updated Selections
 */
export const resolveRemoveExtraction = (selections, startOffset, endOffset) => {
  const newSelections = [];

  for (let i = 0; i < selections.length; i++) {
    const sel = selections[i];

    // Check for overlapping ranges that encompass this selection
    if (startOffset <= sel.startOffset && endOffset >= sel.endOffset) {
      continue;
    } else if (startOffset > sel.startOffset && startOffset < sel.endOffset) {
      // Check for overlapping ranges staring before this selection
      newSelections.push({
        label: sel.label,
        startOffset: sel.startOffset,
        endOffset: startOffset,
      });
      if (endOffset < sel.endOffset) {
        newSelections.push({
          label: sel.label,
          startOffset: endOffset,
          endOffset: sel.endOffset,
        });
      }
    } else if (
      startOffset <= sel.startOffset &&
      endOffset > sel.startOffset &&
      endOffset < sel.endOffset
    ) {
      // Check for overlapping ranges ending in this selection
      newSelections.push({
        label: sel.label,
        startOffset: endOffset,
        endOffset: sel.endOffset,
      });
    } else {
      // Otherwise proceed with all unaffected selections
      newSelections.push(sel);
    }
  }

  return sortBy(newSelections, [
    function (o) {
      return o.startOffset;
    },
  ]);
};

/**
 * Takes a new block with start/end offset and a label, and resolves it against
 * a provided set of previously resolved blocks. Calculating overlaps and breaking items into
 * a list of sequential blocks. This algorithm treats predictions with keywords differently. Keywords that
 * are inside the bounds of a prediction block, get added to an internal list of that block to be rendered at
 * a sub component level. Not at this higher level.
 * @param newBlock
 * @param existingBlocks
 * @returns {Array} Updated set of blocks with new overlap resolved
 */
export const resolveOverlaps = (newBlock, existingBlocks) => {
  const resolvedBlocks = [];

  const resolveAddBlock = (startOffset, endOffset, isKeyword, labels, keywords = []) => {
    const block = { startOffset, endOffset, isKeyword, labels, keywords };
    resolvedBlocks.push(block);
  };

  const resolveBlockLabels = (newLabel, existingLabels = []) => {
    if (newLabel) {
      return existingLabels.find((l) => newLabel.text === l.text)
        ? existingLabels
        : existingLabels.concat(newLabel);
    } else {
      return existingLabels;
    }
  };

  const resolveBlockKeywords = (newKeyword, existingKeywords = []) => {
    if (newKeyword) {
      return existingKeywords.find((k) => newKeyword.startOffset === k.startOffset)
        ? existingKeywords
        : existingKeywords.concat(newKeyword);
    } else {
      return existingKeywords;
    }
  };

  const newBlockIsKeyword = newBlock.hasOwnProperty('keyword') && newBlock.keyword;
  const newBlockLabel = newBlock.label;

  if (!existingBlocks.length) {
    resolveAddBlock(
      newBlock.startOffset,
      newBlock.endOffset,
      newBlockIsKeyword,
      resolveBlockLabels(newBlockLabel)
    );
  } else {
    for (let i = 0; i < existingBlocks.length; i++) {
      const existingBlock = existingBlocks[i];
      const existingBlockLabels = existingBlock.labels;
      const existingBlockIsKeyword = existingBlock.hasOwnProperty('isKeyword')
        ? existingBlock.isKeyword
        : false;
      const existingBlockIsPrediction =
        typeof find(existingBlockLabels, (l) => l.hasOwnProperty('score')) !== 'undefined';

      // Current block is entirely before the new block
      if (newBlock.startOffset >= existingBlock.endOffset) {
        resolveAddBlock(
          existingBlock.startOffset,
          existingBlock.endOffset,
          existingBlockIsKeyword,
          existingBlockLabels,
          existingBlock.keywords
        );
      } else if (
        newBlock.startOffset === existingBlock.startOffset &&
        checkLabelsOverlap(newBlock, existingBlock)
      ) {
        // Extractions start at the same offset
        // Complete Overlap
        if (newBlock.endOffset >= existingBlock.endOffset) {
          if (newBlockIsKeyword && existingBlockIsPrediction) {
            resolveAddBlock(
              existingBlock.startOffset,
              existingBlock.endOffset,
              newBlockIsKeyword,
              resolveBlockLabels(null, existingBlockLabels),
              resolveBlockKeywords(newBlock, existingBlock.keywords)
            );
          } else {
            resolveAddBlock(
              newBlock.startOffset,
              existingBlock.endOffset,
              newBlockIsKeyword,
              resolveBlockLabels(newBlockLabel, existingBlockLabels),
              existingBlock.keywords
            );
          }
        } else if (newBlock.endOffset < existingBlock.endOffset) {
          if (newBlockIsKeyword && existingBlockIsPrediction) {
            resolveAddBlock(
              existingBlock.startOffset,
              existingBlock.endOffset,
              existingBlockIsKeyword,
              resolveBlockLabels(null, existingBlockLabels),
              resolveBlockKeywords(newBlock, existingBlock.keywords)
            );
          } else {
            // Partial Overlap
            resolveAddBlock(
              newBlock.startOffset,
              newBlock.endOffset,
              newBlockIsKeyword,
              resolveBlockLabels(newBlockLabel, existingBlockLabels)
            );
            resolveAddBlock(
              newBlock.endOffset,
              existingBlock.endOffset,
              existingBlockIsKeyword,
              resolveBlockLabels(null, existingBlockLabels, existingBlock.keywords)
            );
          }
        }
      } else if (
        newBlock.startOffset > existingBlock.startOffset &&
        newBlock.startOffset < existingBlock.endOffset
      ) {
        if (newBlockIsKeyword && existingBlockIsPrediction) {
          resolveAddBlock(
            existingBlock.startOffset,
            existingBlock.endOffset,
            existingBlockIsKeyword,
            resolveBlockLabels(null, existingBlockLabels),
            resolveBlockKeywords(newBlock, existingBlock.keywords)
          );
        } else {
          // Start of new extraction intersects with the current
          // Existing Extraction before the new intersecting extraction
          resolveAddBlock(
            existingBlock.startOffset,
            newBlock.startOffset,
            existingBlockIsKeyword,
            resolveBlockLabels(null, existingBlockLabels, existingBlock.keywords)
          );

          // New Extraction overlaps the rest of the existing extraction
          if (newBlock.endOffset >= existingBlock.endOffset) {
            resolveAddBlock(
              newBlock.startOffset,
              existingBlock.endOffset,
              newBlockIsKeyword,
              resolveBlockLabels(newBlockLabel, existingBlockLabels)
            );
          } else {
            // New Extraction overlaps only a portion of the existing extraction
            resolveAddBlock(
              newBlock.startOffset,
              newBlock.endOffset,
              newBlockIsKeyword,
              resolveBlockLabels(newBlockLabel, existingBlockLabels)
            );
            resolveAddBlock(
              newBlock.endOffset,
              existingBlock.endOffset,
              existingBlockIsKeyword,
              resolveBlockLabels(null, existingBlockLabels),
              existingBlock.keywords
            );
          }
        }
      } else if (
        newBlock.endOffset > existingBlock.startOffset &&
        newBlock.endOffset < existingBlock.endOffset
      ) {
        if (newBlockIsKeyword && existingBlockIsPrediction) {
          resolveAddBlock(
            existingBlock.startOffset,
            existingBlock.endOffset,
            existingBlockIsKeyword,
            resolveBlockLabels(null, existingBlockLabels),
            resolveBlockKeywords(newBlock, existingBlock.keywords)
          );
        } else {
          // End of new extraction intersects with the current
          resolveAddBlock(
            existingBlock.startOffset,
            newBlock.endOffset,
            newBlockIsKeyword,
            resolveBlockLabels(newBlockLabel, existingBlockLabels)
          );
          resolveAddBlock(
            newBlock.endOffset,
            existingBlock.endOffset,
            existingBlockIsKeyword,
            resolveBlockLabels(null, existingBlockLabels),
            existingBlock.keywords
          );
        }
      } else if (
        newBlock.startOffset < existingBlock.startOffset &&
        newBlock.endOffset >= existingBlock.endOffset
      ) {
        if (newBlockIsKeyword && existingBlockIsPrediction) {
          resolveAddBlock(
            existingBlock.startOffset,
            existingBlock.endOffset,
            existingBlockIsKeyword,
            resolveBlockLabels(null, existingBlockLabels),
            resolveBlockKeywords(newBlock, existingBlock.keywords)
          );
        } else {
          // New extraction completely encompasses an existing extraction
          resolveAddBlock(
            existingBlock.startOffset,
            existingBlock.endOffset,
            newBlockIsKeyword,
            resolveBlockLabels(newBlockLabel, existingBlockLabels)
          );
        }
      } else if (newBlock.endOffset <= existingBlock.startOffset) {
        // Current extraction is entirely After the new extraction
        resolveAddBlock(
          existingBlock.startOffset,
          existingBlock.endOffset,
          existingBlockIsKeyword,
          resolveBlockLabels(null, existingBlockLabels, existingBlock.keywords)
        );
      }
    }
  }

  return sortBy(resolvedBlocks, [
    function (o) {
      return o.startOffset;
    },
  ]);
};

/**
 * Takes in a bit of text and a list of keyword boundaries. And returns a list of text blocks with the
 * keyword blocks defined by className.
 * @param text
 * @param keywords
 * @param keywordClass
 * @returns {*}
 */
export const buildNonOverlappingKeywords = (text, keywords, keywordClass = 'keyword') => {
  if (keywords.length) {
    keywords = sortBy(keywords, [
      function (kw) {
        return kw.startOffset;
      },
    ]);

    const textBlocks = [];
    let lastIndex = 0;
    for (const kw of keywords) {
      lastIndex !== kw.startOffset &&
        textBlocks.push(<span key={lastIndex}>{text.slice(lastIndex, kw.startOffset)}</span>);
      textBlocks.push(
        <span key={kw.startOffset} className={keywordClass}>
          {text.slice(kw.startOffset, kw.endOffset)}
        </span>
      );
      lastIndex = kw.endOffset;
    }

    if (lastIndex < text.length) {
      textBlocks.push(<span key={lastIndex}>{text.slice(lastIndex, text.length)}</span>);
    }

    return textBlocks;
  } else {
    return <span>{text}</span>;
  }
};
