import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import { captureException } from '@sentry/browser';
import isNull from 'lodash/isNull';

import { Toast } from '@indico-data/permafrost';

// App
import AppCrash from 'root/components/AppCrash/AppCrash';
import { loadIndicoAppSettings } from 'root/actions/app-actions';
import { selectAppToast } from 'root/reducers/index-reducer';
import { ToastsList } from 'root/components/messaging/ToastsList/ToastsList';
import { PageContainer } from 'root/components/PageContainer';
import * as ROUTES from 'root/constants/router';

// Routing
import PrivateRoute from 'root/components/routing/PrivateRoute/PrivateRoute';
import AppRoute from 'root/components/routing/AppRoute/AppRoute';

// Auth
import { verifyUser } from 'Auth/actions/auth-actions';

// Retry Route Import When Initial Attempt Fails
const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

// // Authentication Routes
const LoginView = lazy(() => retry(() => import('Auth/pages/LoginView/LoginView')));
const FourOhFour = lazy(() => retry(() => import('root/pages/FourOhFour/FourOhFour')));
const NoAccess = lazy(() => retry(() => import('root/pages/NoAccess/NoAccess')));

const ProfileView = lazy(() => retry(() => import('Auth/pages/ProfileView/ProfileView')));
const UpdatePasswordView = lazy(() =>
  retry(() => import('Auth/pages/UpdatePasswordView/UpdatePasswordView'))
);
const ConfirmAccountView = lazy(() =>
  retry(() => import('Auth/pages/ConfirmAccountView/ConfirmAccountView'))
);
const ForgotView = lazy(() => retry(() => import('Auth/pages/ForgotView/ForgotView')));
const RegisterView = lazy(() => retry(() => import('Auth/pages/RegisterView/RegisterView')));
const LogoutView = lazy(() => retry(() => import('Auth/pages/LogoutView/LogoutView')));
const AdminView = lazy(() => retry(() => import('Admin/pages/AdminView/AdminView')));

// Datasets Routes
const Datasets = lazy(() => retry(() => import('Sharknado/pages/Datasets/Datasets')));
const DatasetDetails = lazy(() =>
  retry(() => import('Sharknado/pages/DatasetDetails/DatasetDetails'))
);
const NewDataset = lazy(() => retry(() => import('Sharknado/pages/NewDataset/NewDataset')));
const ExamplesList = lazy(() => retry(() => import('Sharknado/pages/ExamplesList/ExamplesList')));

// Elmosfire Routes
const FireGroups = lazy(() => retry(() => import('Elmosfire/pages/FireGroups/FireGroups')));
const NewFireGroup = lazy(() => retry(() => import('Elmosfire/pages/NewFireGroup/NewFireGroup')));
const FireGroupDetails = lazy(() =>
  retry(() => import('Elmosfire/pages/FireGroupDetails/FireGroupDetails'))
);
const Fire = lazy(() => retry(() => import('Elmosfire/pages/Fire/Fire')));

// Crowdlabel Routes
const Questionnaires = lazy(() =>
  retry(() => import('Crowdlabel/pages/QuestionnairesView/QuestionnairesView'))
);
const NewQuestionnaire = lazy(() =>
  retry(() => import('Crowdlabel/pages/CreateNewQuestionnaire/CreateNewQuestionnaire'))
);
const QuestionnaireDetails = lazy(() =>
  retry(() => import('Crowdlabel/pages/QuestionnaireDetails/QuestionnaireDetails'))
);
const Labeling = lazy(() => retry(() => import('Crowdlabel/pages/Labeling/Labeling')));
const SingleExampleLabeling = lazy(() =>
  retry(() => import('Crowdlabel/pages/SingleExampleLabeling/SingleExampleLabeling'))
);
const DocLabeling = lazy(() => retry(() => import('Crowdlabel/pages/DocLabeling/DocLabeling')));

// Moonbow Routes
const DatasetsAndModels = lazy(() =>
  retry(() => import('Moonbow/pages/DatasetsAndModels/DatasetsAndModels'))
);
const NewModel = lazy(() => retry(() => import('Moonbow/pages/NewModel/NewModel')));
const ModelDetails = lazy(() => retry(() => import('Moonbow/pages/ModelDetails/ModelDetails')));

// Sunbow Routes
const SunbowList = lazy(() => retry(() => import('Sunbow/pages/SunbowList/SunbowList')));
const StandardQueue = lazy(() => retry(() => import('Sunbow/pages/StandardQueue/StandardQueue')));
const SingleQueue = lazy(() => retry(() => import('Sunbow/pages/SingleQueue/SingleQueue')));
const SingleQueueSuccess = lazy(() =>
  retry(() => import('Sunbow/pages/SingleQueueSuccess/SingleQueueSuccess'))
);
const ExceptionsQueue = lazy(() =>
  retry(() => import('Sunbow/pages/ExceptionsQueue/ExceptionsQueue'))
);

// Cloudburst Routes
const WorkflowsList = lazy(() =>
  retry(() => import('Cloudburst/pages/WorkflowsList/WorkflowsList'))
);
const WorkflowDetails = lazy(() =>
  retry(() => import('Cloudburst/pages/WorkflowDetails/WorkflowDetails'))
);

// Zephyr Routes
const GalleryPage = lazy(() => retry(() => import('Zephyr/pages/Gallery/Gallery')));

const routeMap = [
  { path: ROUTES.AUTH_ACCOUNT, component: ProfileView, type: 'PRIVATE' },
  { path: ROUTES.AUTH_CHANGE_PASSWORD, component: UpdatePasswordView, type: 'REGULAR' },
  { path: ROUTES.AUTH_CONFIRM_ACCOUNT, component: ConfirmAccountView, type: 'REGULAR' },
  { path: ROUTES.AUTH_FORGOT, component: ForgotView, type: 'REGULAR' },
  { path: ROUTES.AUTH_REGISTER, component: RegisterView, type: 'REGULAR' },
  { path: ROUTES.AUTH_LOGOUT, component: LogoutView, type: 'REGULAR' },

  { path: ROUTES.BASE_ADMIN, component: AdminView, type: 'PRIVATE' },
  { path: ROUTES.ADMIN_REPORTS, component: AdminView, type: 'PRIVATE' },

  { path: ROUTES.BASE_DATASETS, component: Datasets, type: 'PRIVATE' },
  { path: ROUTES.DATASET_NEW, component: NewDataset, type: 'PRIVATE' },
  { path: ROUTES.DATASETS_DETAILS, component: DatasetDetails, type: 'PRIVATE' },
  { path: ROUTES.DATASETS_EXAMPLES_LIST, component: ExamplesList, type: 'PRIVATE' },

  { path: ROUTES.BASE_ELMOSFIRE, component: FireGroups, type: 'PRIVATE' },
  { path: ROUTES.ELMOSFIRE_NEW, component: NewFireGroup, type: 'PRIVATE' },
  { path: ROUTES.ELMOSFIRE_DETAILS, component: FireGroupDetails, type: 'PRIVATE' },
  { path: ROUTES.ELMOSFIRE_FIREGROUP, component: Fire, type: 'PRIVATE' },

  { path: ROUTES.BASE_CROWDLABEL, component: Questionnaires, type: 'PRIVATE' },
  { path: ROUTES.CROWDLABEL_TASKS_NEW, component: NewQuestionnaire, type: 'PRIVATE' },
  { path: ROUTES.CROWDLABEL_TASKS_DETAILS, component: QuestionnaireDetails, type: 'PRIVATE' },
  { path: ROUTES.CROWDLABEL_LABELING, component: Labeling, type: 'PRIVATE' },
  { path: ROUTES.CROWDLABEL_LABELING_SINGLE, component: SingleExampleLabeling, type: 'PRIVATE' },
  { path: ROUTES.CROWDLABEL_DOC_LABELING, component: DocLabeling, type: 'PRIVATE' },

  { path: ROUTES.BASE_MOONBOW, component: DatasetsAndModels, type: 'PRIVATE' },
  { path: ROUTES.MOONBOW_NEW, component: NewModel, type: 'PRIVATE' },
  { path: ROUTES.MOONBOW_DETAILS, component: ModelDetails, type: 'PRIVATE' },

  { path: ROUTES.BASE_SUNBOW, component: SunbowList, type: 'PRIVATE' },
  { path: ROUTES.SUNBOW_QUEUE, component: StandardQueue, type: 'PRIVATE' },
  { path: ROUTES.EXCEPTIONS_QUEUE, component: ExceptionsQueue, type: 'PRIVATE' },
  { path: ROUTES.SINGLE_SUNBOW_QUEUE, component: SingleQueue, type: 'PRIVATE' },
  { path: ROUTES.SINGLE_SUNBOW_QUEUE_SUCCESS, component: SingleQueueSuccess, type: 'PRIVATE' },

  { path: ROUTES.BASE_CLOUDBURST, component: WorkflowsList, type: 'PRIVATE' },
  { path: ROUTES.WORKFLOW_DETAILS, component: WorkflowDetails, type: 'PRIVATE' },
  { path: ROUTES.BASE_ZEPHYR, component: GalleryPage, type: 'PRIVATE' },
  { path: ROUTES.BASE_NO_ACCESS, component: NoAccess, type: 'REGULAR' },

  { path: '*', component: FourOhFour, type: 'REGULAR' },
];

class Router extends Component {
  state = {
    error: null,
    loginError: false,
    redirect: {
      pathname: '',
      search: '',
    },
  };

  componentDidMount() {
    this.props
      .verifyUser()
      .then((user) => {
        if (user) {
          this.props.loadIndicoAppSettings();
          this.props.updateUserContext({ type: 'SET_USER', user });
        }
      })
      .catch((err) => {
        if (err.status !== 401) {
          this.setState({ loginError: true });
        }
      });
  }

  componentDidCatch(error) {
    this.setState({ error });
    captureException(error);
  }

  resetRedirect = (location) => {
    if (location) {
      this.setState({
        redirect: { pathname: location.pathname, search: location.search },
      });
    } else {
      this.setState({ redirect: { pathname: '', search: '' } });
    }
  };

  render() {
    const contents = (
      <div className="MainRouter" style={{ height: '100%' }}>
        <Toast toast={this.props.toast} />
        <ToastsList>
          <div className="main-content" style={{ height: '100%' }}>
            {!isNull(this.state.error) ? (
              <AppCrash />
            ) : (
              <Suspense fallback={<PageContainer />}>
                <Switch>
                  <AppRoute exact path="/">
                    <LoginView redirect={this.state.redirect} />
                  </AppRoute>
                  <AppRoute exact path={ROUTES.BASE_AUTH}>
                    <LoginView redirect={this.state.redirect} />
                  </AppRoute>
                  {routeMap.map((route, i) => {
                    if (route.type === 'PRIVATE') {
                      return (
                        <PrivateRoute
                          exact
                          key={i}
                          path={route.path}
                          component={route.component}
                          loginError={this.state.loginError}
                          redirect={this.state.redirect}
                          resetRedirect={this.resetRedirect}
                        />
                      );
                    } else {
                      return (
                        <AppRoute exact key={i} path={route.path} component={route.component} />
                      );
                    }
                  })}
                </Switch>
              </Suspense>
            )}
          </div>
        </ToastsList>
      </div>
    );

    return <BrowserRouter>{contents}</BrowserRouter>;
  }
}

const mapStateToProps = (state) => {
  return {
    toast: selectAppToast(state),
  };
};

const mapDispatchToProps = {
  verifyUser,
  loadIndicoAppSettings,
};

export const MainRouter = connect(mapStateToProps, mapDispatchToProps)(Router);
