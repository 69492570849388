import keyBy from 'lodash/keyBy';

// App
import { fetchUtils } from '@indico-data/utils';

// Elmosfire
import * as endpoints from 'Elmosfire/constants/endpoints';

export const getDatasetsAPI = () => {
  return fetchUtils
    .getElmosfireJSON(`${endpoints.DATASET_ENDPOINT}`)
    .then((json) => {
      const modifiedDatasets = json.datasets;
      return keyBy(modifiedDatasets, 'id'); // [{id: 123}, {id: 456}] => { 123: obj, 456: obj }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getDatasetAPI = (datasetID) => {
  return fetchUtils
    .getElmosfireJSON(`${endpoints.DATASET_ENDPOINT}/${datasetID}`)
    .then((json) => {
      return json;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const postNewDataset = (newDataset) => {
  const toJSNewDataset = newDataset.toJS();
  const { name, fileHeaders, fileContents, sampleColumn } = toJSNewDataset;

  const headerList = fileHeaders.map((h) => h.header);

  return fetchUtils
    .postElmosfireJSON(`${endpoints.DATASET_ENDPOINT}`, {
      name: name,
      fields: headerList,
      data: fileContents,
      default_column: sampleColumn,
    })
    .then((json) => {
      return json;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const putUpdatedDataset = (dataset) => {
  const { id, name, default_column } = dataset;
  return fetchUtils.putElmosfireJSON(`${endpoints.DATASET_ENDPOINT}/${id}`, {
    changes: { name, default_column },
  });
};

export const deleteDatasetAPI = (datasetID) => {
  return fetchUtils
    .deleteElmosfireJSON(`${endpoints.DATASET_ENDPOINT}/${datasetID}`)
    .then((json) => {
      return json;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
