import React from 'react';
import { render } from 'react-dom';

import 'rxjs/add/observable/timer';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/exhaustMap';

if (module.hot) {
  module.hot.accept();
}

import { Root } from './Root';

render(<Root />, document.getElementById('root'));
