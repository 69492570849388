export const LOADING_USER = 'LOADING_USER';
export const VALIDATE_USER = 'VALIDATE_USER';

export const SET_BLACKLIST_MESSAGE = 'SET_BLACKLIST_MESSAGE';

export const CHANGE_LOGIN_EMAIL = 'CHANGE_LOGIN_EMAIL';
export const CHANGE_LOGIN_PASSWORD = 'CHANGE_LOGIN_PASSWORD';
export const TOGGLE_LOGIN_REMEMBER_ME = 'TOGGLE_LOGIN_REMEMBER_ME';
export const LOAD_SAVED_USER_DETAILS = 'LOAD_SAVED_USER_DETAILS';

export const ATTEMPT_LOGIN = 'ATTEMPT_LOGIN';
export const RESOLVE_LOGIN = 'RESOLVE_LOGIN';
export const REJECT_LOGIN = 'REJECT_LOGIN';
export const ATTEMPT_LOGOUT = 'ATTEMPT_LOGOUT';
export const RESOLVE_LOGOUT = 'RESOLVE_LOGOUT';
export const REJECT_LOGOUT = 'REJECT_LOGOUT';
export const CHECK_CURRENT_USER = 'CHECK_CURRENT_USER';
export const RESOLVE_CURRENT_USER = 'RESOLVE_CURRENT_USER';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';

export const CHANGE_UPDATE_CURRENT_PASSWORD = 'CHANGE_UPDATE_CURRENT_PASSWORD';
export const CHANGE_UPDATE_NEW_PASSWORD = 'CHANGE_UPDATE_NEW_PASSWORD';
export const CHANGE_UPDATE_CONFIRM_PASSWORD = 'CHANGE_UPDATE_CONFIRM_PASSWORD';
export const REQUEST_PASSWORD_UPDATE = 'REQUEST_PASSWORD_UPDATE';
export const RESOLVE_PASSWORD_UPDATE = 'RESOLVE_PASSWORD_UPDATE';
export const REJECT_PASSWORD_UPDATE = 'REJECT_PASSWORD_UPDATE';
export const CHANGE_PASSWORD_RESET_TOKEN = 'CHANGE_PASSWORD_RESET_TOKEN';

// Confirmation Actions
export const RESET_CONFIRM = 'RESET_CONFIRM';
export const CHANGE_CONFIRM_ACCOUNT_TOKEN = 'CHANGE_CONFIRM_ACCOUNT_TOKEN';
export const CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT';
export const RESOLVE_CONFIRM_ACCOUNT = 'RESOLVE_CONFIRM_ACCOUNT';
export const REJECT_CONFIRM_ACCOUNT = 'REJECT_CONFIRM_ACCOUNT';

export const CHANGE_FORGOT_EMAIL = 'CHANGE_FORGOT_EMAIL';
export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';
export const RESOLVE_PASSWORD_RESET = 'RESOLVE_PASSWORD_RESET';
export const REJECT_PASSWORD_RESET = 'REJECT_PASSWORD_RESET';

export const CHANGE_REGISTER_NAME = 'CHANGE_REGISTER_NAME';
export const CHANGE_REGISTER_COMPANY_NAME = 'CHANGE_REGISTER_COMPANY_NAME';
export const CHANGE_REGISTER_EMAIL = 'CHANGE_REGISTER_EMAIL';
export const CHANGE_REGISTER_PASSWORD = 'CHANGE_REGISTER_PASSWORD';
export const TOGGLE_REGISTER_TERMS = 'TOGGLE_REGISTER_TERMS';
export const TOGGLE_REGISTER_SUBSCRIBE = 'TOGGLE_REGISTER_SUBSCRIBE';
export const RESET_REGISTER = 'RESET_REGISTER';
export const ATTEMPT_REGISTER = 'ATTEMPT_REGISTER';
export const RESOLVE_REGISTER = 'RESOLVE_REGISTER';
export const REJECT_REGISTER = 'REJECT_REGISTER';

export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE';
export const EXPIRED_ERROR = 'EXPIRED_ERROR';
