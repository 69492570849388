import { combineEpics } from 'redux-observable';

import overlayLabelCountsEpic from './overlay-label-counts-epic';
import overlayPredictedLabelCountsEpic from './overlay-predicted-label-counts-epic';
import retrainModelGroupEpic from './retrain-model-group-epic';
import fireTSNEEpic from './fire-tsne-epic';
import fireWordCloudEpic from './fire-wordcloud-epic';

const indexEpic = combineEpics(
  overlayLabelCountsEpic,
  overlayPredictedLabelCountsEpic,
  retrainModelGroupEpic,
  fireTSNEEpic,
  fireWordCloudEpic
);

export default indexEpic;
