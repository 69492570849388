import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';

// App
import {
  selectSDSelectedLabel,
  selectCurrentSelectedOverlay,
  selectCanTrainSelectedOverlay,
  selectTSNEUserSelectedOverlayData,
  selectSDCurrentLabels,
  selectFireDataColumnID,
} from 'root/reducers/index-reducer';

// Elmosfire
import * as types from 'Elmosfire/actions/action-types';
import { getRowsDataAPI } from 'Elmosfire/providers/rows-provider';

export const clearRowsData = () => ({
  type: types.CLEAR_ROWS,
});

export const fetchRowsData = (datasetId, rows = []) => (dispatch, getState) => {
  const columnId = selectFireDataColumnID(getState());
  dispatch({ type: types.TOGGLE_FETCHING_ROWS, fetching: true });
  return getRowsDataAPI(datasetId, columnId, rows).then((rowsData) => {
    dispatch({
      type: types.GET_ROWS,
      rows: rowsData,
    });
    dispatch(applyOverlayRowItemLabels(datasetId, rows));
  });
};

export const applyOverlayRowItemLabels = (datasetId, rows = []) => (dispatch, getState) => {
  const labels = selectSDCurrentLabels(getState());
  const userLabeledRows = selectTSNEUserSelectedOverlayData(getState());
  const currentOverlay = selectCurrentSelectedOverlay(getState(), datasetId);

  if (currentOverlay && Object.keys(userLabeledRows).length) {
    for (const rowKey of rows) {
      if (userLabeledRows[rowKey]) {
        for (const label of userLabeledRows[rowKey]) {
          const selectedLabel = find(labels, { text: label });
          dispatch({
            type: types.LABEL_EXAMPLE,
            key: rowKey,
            label: selectedLabel,
            multilabel: currentOverlay.multilabel,
          });
        }
      }
    }
  }
};

export const labelExample = (key, datasetId) => (dispatch, getState) => {
  // Break early if the user can't train/label data
  if (!selectCanTrainSelectedOverlay(getState(), datasetId)) {
    return;
  }

  const selectedLabel = selectSDSelectedLabel(getState());

  if (selectedLabel.removeLabel) {
    dispatch({
      type: types.REMOVE_EXAMPLE_LABELS,
      dirty: true,
      key,
    });
  } else if (!isEmpty(selectedLabel)) {
    dispatch({
      type: types.LABEL_EXAMPLE,
      dirty: true,
      key,
      label: selectedLabel,
      multilabel: selectCurrentSelectedOverlay(getState(), datasetId).multilabel,
    });
  }
};

export const toggleRejectExample = (rowId) => ({
  type: types.TOGGLE_REJECT_ELMOSFIRE_EXAMPLE,
  rowId,
});
export const clearRowItemLabels = () => ({ type: types.CLEAR_ROW_LABELS });
