import React from 'react';
import get from 'lodash/get';

import { Icon } from '@indico-data/permafrost';

import * as ROUTES from 'root/constants/router';

export const APP_CHROME_MENU = [
  [
    {
      id: 'DATASETS_APP',
      text: 'Datasets',
      icon: <Icon name="data-and-access" />,
      destination: ROUTES.BASE_DATASETS,
      anchor: false,
    },
    {
      id: 'ELMOSFIRE_APP',
      text: 'Discover',
      icon: <Icon name="elmos-fire" />,
      destination: ROUTES.BASE_ELMOSFIRE,
      anchor: false,
    },
    {
      id: 'MOONBOW_APP',
      text: 'Explain',
      icon: <Icon name="moonbow" />,
      destination: ROUTES.BASE_MOONBOW,
      anchor: false,
    },
    {
      id: 'CROWDLABEL_APP',
      text: 'Teach',
      icon: <Icon name="crowdlabel" />,
      destination: ROUTES.BASE_CROWDLABEL,
      anchor: false,
    },
    {
      id: 'SUNBOW_APP',
      text: 'Review',
      icon: <Icon name="repair" />,
      destination: ROUTES.BASE_SUNBOW,
      anchor: false,
    },
    {
      id: 'CLOUDBURST_APP',
      text: 'Workflows',
      icon: <Icon name="workflows" />,
      destination: ROUTES.BASE_CLOUDBURST,
      anchor: false,
    },
  ],
  [
    {
      id: 'NOCT_APP',
      text: 'Account',
      icon: <Icon name="account" />,
      destination: ROUTES.AUTH_ACCOUNT,
      anchor: false,
    },
    {
      id: 'API_DOCS_APP',
      text: 'API Documentation',
      icon: <Icon name="api-doc" />,
      destination: get(window, 'indico.appURLs.DocsURL'),
      anchor: true,
    },
    {
      id: 'HELP_APP',
      text: 'Knowledge Base',
      icon: <Icon name="help" />,
      destination: get(window, 'indico.appURLs.HelpURL'),
      anchor: true,
    },
  ],
];
