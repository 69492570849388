import React, { Component, createContext } from 'react';
import { Toast } from '@indico-data/permafrost';
import { TOAST_TIMEOUT } from 'root/constants/app';

import { StyledToastsList } from './ToastsList.styles';

type Props = {
  children: React.ReactChildren;
};

type State = {
  toasts: Toast[];
};

type Toast = {
  display: boolean;
  message: string;
  style: 'success' | 'error';
};

export type AddToast = (message: string, type: 'success' | 'error') => void;

export const ToastsContext = createContext<{
  toasts: Toast[];
  addToast: AddToast;
}>({
  toasts: [],
  addToast: () => {},
});

export class ToastsList extends Component<Props, State> {
  state = {
    toasts: [],
  };

  addToast = (message: string, type: string) => {
    if (this.state.toasts.length === 5) {
      return;
    }

    this.setState(
      (state: State) => ({
        toasts: [...state.toasts, { display: true, message, style: type } as Toast],
      }),
      () => {
        setTimeout(() => {
          this.setState((state: State) => ({
            toasts: state.toasts.slice(1),
          }));
        }, TOAST_TIMEOUT);
      }
    );
  };

  render() {
    return (
      <ToastsContext.Provider value={{ toasts: this.state.toasts, addToast: this.addToast }}>
        <StyledToastsList>
          {this.state.toasts.map((toast, index) => (
            <Toast key={`toast${index}`} toast={toast} />
          ))}
        </StyledToastsList>
        {this.props.children}
      </ToastsContext.Provider>
    );
  }
}
