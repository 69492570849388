import { Observable } from 'rxjs/Observable';

// App
import { fetchUtils } from '@indico-data/utils';

// Elmosfire
import * as endpoints from 'Elmosfire/constants/endpoints';

/**
 * Gets the tSne status for a given dataset and calculated cluster
 * @param {int} datasetID - ID of dataset for target fire
 * @param {int} clusterID - ID of cluster for target fire
 * @returns {observable}
 */
export const getTSNEStatus = (datasetID, clusterID) => {
  return Observable.from(
    fetchUtils
      .getElmosfireJSON(`${endpoints.DATASET_ENDPOINT}/${datasetID}/tsne/${clusterID}/status`)
      .then((json) => {
        return json;
      })
      .catch((err) => {
        return Promise.reject(err);
      })
  );
};

/**
 * Gets the tSne data points for a given dataset and calculated cluster
 * @param {int} datasetID - ID of dataset for target fire
 * @param {int} clusterID - ID of cluster for target fire
 * @returns {promise}
 */
export const getTSNE = (datasetID, clusterID) => {
  return fetchUtils
    .getElmosfireJSON(`${endpoints.DATASET_ENDPOINT}/${datasetID}/tsne/${clusterID}`)
    .then((json) => {
      return json;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
