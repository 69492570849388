import * as types from 'Auth/actions/action-types';

type State = {
  currentPasswordInput: string;
  newPasswordInput: string;
  newPasswordRepeatInput: string;
  resetToken: string;
  tokenParsed: boolean;
  loading: boolean;
  isComplete: boolean;
};

const defaultState: State = {
  currentPasswordInput: '',
  newPasswordInput: '',
  newPasswordRepeatInput: '',
  resetToken: '',
  tokenParsed: false,
  loading: false,
  isComplete: false,
};

export default (
  state = defaultState,
  action: { type: keyof typeof types; value: string; token: string }
): State => {
  switch (action.type) {
    case types.CHANGE_UPDATE_CURRENT_PASSWORD:
      return { ...state, currentPasswordInput: action.value };

    case types.CHANGE_UPDATE_NEW_PASSWORD:
      return { ...state, newPasswordInput: action.value };

    case types.CHANGE_UPDATE_CONFIRM_PASSWORD:
      return { ...state, newPasswordRepeatInput: action.value };

    case types.REQUEST_PASSWORD_UPDATE:
      return { ...state, loading: true, isComplete: defaultState.isComplete };

    case types.RESOLVE_PASSWORD_UPDATE:
      return {
        ...state,
        loading: false,
        isComplete: true,
        currentPasswordInput: defaultState.currentPasswordInput,
        newPasswordInput: defaultState.newPasswordInput,
        newPasswordRepeatInput: defaultState.newPasswordRepeatInput,
      };

    case types.REJECT_PASSWORD_UPDATE:
      return { ...state, loading: false };

    case types.CHANGE_PASSWORD_RESET_TOKEN:
      return { ...state, resetToken: action.token, tokenParsed: true };

    default:
      return state;
  }
};

export const getCurrentPasswordInput = (state: State) => state.currentPasswordInput;
export const getNewPasswordInput = (state: State) => state.newPasswordInput;
export const getNewPasswordRepeatInput = (state: State) => state.newPasswordRepeatInput;
export const getPasswordChangeLoading = (state: State) => state.loading;
export const getPasswordChangeComplete = (state: State) => state.isComplete;
export const getResetToken = (state: State) => state.resetToken;
export const getTokenParsed = (state: State) => state.tokenParsed;
