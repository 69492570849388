export default {
    emotion: {
        name: 'Emotion',
        meta: 'indico Emotion API'
    },
    sentimenthq: {
        name: 'Sentiment',
        meta: 'indico Sentiment API'
    },
    texttags: {
        name: 'Text Tags',
        meta: 'indico TextTags API'
    }
};
