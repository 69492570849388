// App
import {
  selectSDMenu,
  selectSDCurrentLabels,
  selectCurrentSelectedOverlay,
  selectRows,
  selectCanTrainSelectedOverlay,
} from 'root/reducers/index-reducer';

// Elmosfire
import * as types from 'Elmosfire/actions/action-types';
import { saveLabelsAPI } from 'Elmosfire/providers/labeling-provider';

export const changeSelectedLabel = (label) => ({ type: types.CHANGE_SELECTED_LABEL, label });
export const changeNewLabel = (newValue) => ({
  type: types.CHANGE_NEW_ELMOSFIRE_LABEL,
  value: newValue,
});
export const saveNewLabel = () => (dispatch, getState) => {
  dispatch({ type: types.SAVE_NEW_LABEL });
  const currentLabels = selectSDCurrentLabels(getState());
  dispatch(changeSelectedLabel(currentLabels[currentLabels.length - 1].text));
};
export const removeLabel = (label) => ({ type: types.REMOVE_LABEL, label });
export const toggleMenu = () => (dispatch, getState) => {
  dispatch({ type: types.TOGGLE_SD_MENU, open: !selectSDMenu(getState()) });
};
export const openMenu = () => ({ type: types.TOGGLE_SD_MENU, open: true });
export const toggleDisplayInput = (displayInput) => ({
  type: types.TOGGLE_ADD_LABEL_DISPLAY_INPUT,
  displayInput,
});

export const toggleHideSwatch = (hideSwatch) => ({ type: types.TOGGLE_HIDE_SWATCH, hideSwatch });

/**
 * HotKeys Change Label handler
 * @param hotKeyValue - integer 0-9
 */
export const changeSelectedLabelByHotKey = (hotKeyValue) => (dispatch, getState) => {
  const labels = selectSDCurrentLabels(getState());

  const labelIndex = labels[0].removeLabel ? hotKeyValue : hotKeyValue - 1;
  if (labels[labelIndex]) {
    dispatch(changeSelectedLabel(labels[labelIndex].text));
  }
};

export const collectionChangeLabelReset = () => (dispatch, getState) => {
  dispatch({ type: types.CLEAR_SD_LABELS });
  dispatch({
    type: types.INITIALIZE_TSNE_MENU,
    labels: selectSDCurrentLabels(getState()).filter((l, i) => i !== 0),
  }); // Filters out the 'Remove Label'
};

export const displayLabeledConfirmation = (display) => (dispatch) => {
  dispatch({ type: types.TOGGLE_LABELED_CONFIRMATION, display });
  setTimeout(() => dispatch({ type: types.TOGGLE_LABELED_CONFIRMATION, display: false }), 20000);
};

export const saveLabels = (datasetId, addToast) => (dispatch, getState) => {
  // Gate-keep the labeling logic to overlays the user can label/train
  if (
    !selectCurrentSelectedOverlay(getState(), datasetId) ||
    !selectCanTrainSelectedOverlay(getState(), datasetId)
  ) {
    addToast('You do not have permission to modify this overlay.', 'error');
  } else {
    dispatch({ type: types.SAVING_SD_LABELS, saving: true });
    const selectedLocalOverlay = selectCurrentSelectedOverlay(getState(), datasetId);
    return saveLabelsAPI(
      datasetId,
      selectedLocalOverlay.id,
      selectedLocalOverlay.multilabel,
      selectRows(getState())
    )
      .then(() => {
        dispatch({ type: types.CLEAR_ROWS });
        dispatch({ type: types.CLEAR_ITEMS_SELECTED });
        dispatch(displayLabeledConfirmation(true));
        dispatch({ type: types.SAVING_SD_LABELS, saving: false });
        // Check to see if user only labeled using a single label (< 3 to account for "Remove Label" and User added label)
        // Note this is still IMPLICIT because a user can easily add labels - but only assign with a single label
        // Better solution down the road would be to check the assigned labels available on the untrained model
        if (selectSDCurrentLabels(getState()).length < 3) {
          dispatch({
            type: types.CAN_RETRAIN,
            canRetrain: false,
            selectedOverlayId: selectedLocalOverlay.modelId,
          });
        } else {
          dispatch({
            type: types.CAN_RETRAIN,
            canRetrain: true,
            selectedOverlayId: selectedLocalOverlay.modelId,
          });
        }
      })
      .catch(() => {
        addToast('There was an error saving your labels.', 'error');
        dispatch({ type: types.SAVING_SD_LABELS, saving: false });
      });
  }
};
