// App
import { fetchUtils } from '@indico-data/utils';

// Auth
import * as endpoints from 'Auth/constants/endpoints';

export const verifyUserAPI = () => {
  return fetchUtils
    .getCurrentUser()
    .then((json: any) => {
      return json;
    })
    .catch((err: number) => {
      if (err === 401) {
        return Promise.reject(401);
      } else {
        return Promise.reject(err);
      }
    });
};

export const postUserLoginAPI = (
  authModel: { email: string; password: string },
  xsrfToken: string,
  captcha_token?: string
) => {
  return fetchUtils
    .postAuthJSON(`${endpoints.USERS_ENDPOINT}/authenticate`, xsrfToken, {
      ...authModel,
      email: authModel.email.toLowerCase().trim(),
      captcha_token,
    })
    .then((json: any) => {
      return json;
    })
    .catch((err: string) => {
      return Promise.reject(err);
    });
};

export const userLogoutAPI = (xsrfToken: string) => {
  return fetchUtils
    .postAuthJSON(`${endpoints.USERS_ENDPOINT}/logout`, xsrfToken)
    .then((json: any) => {
      return json;
    })
    .catch((err: string) => {
      return Promise.reject(err);
    });
};
