import React, { Component } from 'react';
import { withRouter, Route, RouteComponentProps } from 'react-router-dom';

type Props = {
  component: any;
} & RouteComponentProps;

class AppRoute extends Component<Props> {
  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => {
          return <Component {...props} />;
        }}
      />
    );
  }
}

export default withRouter(AppRoute);
