import { Map } from 'immutable';

import * as types from 'root/actions/action-types';
import * as settings from 'root/constants/user-settings';

export const defaultState = Map({
  toast: {
    display: false,
    message: '',
    style: '',
  },
  settings: Map({
    sidebarOpen: true,
  }),
  xsrfToken: null,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.DISPLAY_TOAST:
      return state.set('toast', {
        display: true,
        message: action.message,
        style: action.style,
      });
    case types.HIDE_TOAST:
      return state.set('toast', {
        display: false,
        message: state.get('toast').message,
        style: state.get('toast').style,
      });
    case types.LOAD_INDICO_APP_SETTINGS:
      return state.setIn(
        ['settings', 'sidebarOpen'],
        action.settings.hasOwnProperty(settings.USER_SETTING_SIDEBAR_OPEN)
          ? action.settings[settings.USER_SETTING_SIDEBAR_OPEN]
          : defaultState.getIn(['settings', 'sidebarOpen'])
      );
    case types.TOGGLE_SIDEBAR:
      return state.setIn(['settings', 'sidebarOpen'], !state.getIn(['settings', 'sidebarOpen']));
    case types.SET_XSRF_TOKEN:
      return state.set('xsrfToken', action.token);
    default:
      return state;
  }
};

export const getAppToast = (state) => state.get('toast');
export const getAppSettings = (state) => state.get('settings');
export const getAppSidebarOpen = (state) => state.getIn(['settings', 'sidebarOpen']);
export const getXSRFToken = (state) => state.get('xsrfToken');
