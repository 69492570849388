import { Map } from 'immutable';
import keyBy from 'lodash/keyBy';

// Elmosfire
import * as types from 'Elmosfire/actions/action-types';
import OUT_OF_BOX_MODELS from 'Elmosfire/constants/out-of-the-box-models';

export const defaultState = Map({
  canRetrain: {},
  retraining: false,
  retrainProgress: 2,
  inputFilter: '',
  outOfTheBox: {},
  categorical: {},
});

export const calculateProgress = (progress) => {
  if (progress < 50) {
    return progress + 10;
  } else if (progress > 90) {
    return progress + (100 - progress) / 10;
  } else {
    return progress + Math.pow((progress % 100) / 100, 0.1) + Math.floor(Math.random() * 5);
  }
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_OUT_OF_BOX_OVERLAYS:
      const overlays = action.overlays
        .filter((overlayId) => OUT_OF_BOX_MODELS[overlayId])
        .map((overlayId) => {
          return {
            id: overlayId,
            name: OUT_OF_BOX_MODELS[overlayId].name,
            labelsetId: null,
          };
        });
      return state.set('outOfTheBox', keyBy(overlays, 'id'));

    case types.GET_OUT_OF_BOX_LABELSET_ID:
      return state.set(
        'outOfTheBox',
        Object.assign({}, state.get('outOfTheBox'), {
          [action.ottoboxId]: Object.assign({}, state.get('outOfTheBox')[action.ottoboxId], {
            labelsetId: action.labelsetId,
          }),
        })
      );

    case types.CAN_RETRAIN:
      return state.set(
        'canRetrain',
        Object.assign({}, state.get('canRetrain'), {
          [action.selectedOverlayId]: action.canRetrain,
        })
      );

    case types.CHANGE_OVERLAY_INPUT_FILTER:
      return state.set('inputFilter', action.value);

    case types.TOGGLE_RETRAINING:
      return state.set('retraining', action.retraining);

    case types.CLEAR_OVERLAY:
      return state.set('inputFilter', '');

    case types.INCREMENT_RETRAIN_PROGRESS:
      return state.set('retrainProgress', calculateProgress(state.get('retrainProgress')));

    case types.RESET_RETRAIN_PROGRESS:
      return state.set('retrainProgress', defaultState.get('retrainProgress'));

    default:
      return state;
  }
};

export const getOutOfTheBoxOverlays = (state) => state.get('outOfTheBox');
export const getCanRetrain = (state) => state.get('canRetrain');
export const getInputFilter = (state) => state.get('inputFilter');
export const getRetraining = (state) => state.get('retraining');
export const getRetrainProgress = (state) => state.get('retrainProgress');
