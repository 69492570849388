export const USER_SETTING_SIDEBAR_OPEN = 'sidebarOpen';

export const USER_SETTING_TEXT_WRAP = 'textWrap';
export const USER_SETTING_SHOW_PREDICTIONS = 'showPredictions';
export const USER_SETTING_SHOW_KEYWORDS = 'showKeywords';
export const USER_SETTING_IMAGE_QUANTITY = 'imageQuantity';
export const USER_SETTING_EXTRACTION_QUANTITY = 'extractionQuantity';
export const USER_SETTING_TEXT_QUANTITY = 'textQuantity';
export const USER_SETTING_TEXT_SIZE = 'textSize';
export const USER_SETTING_EXTRACTION_THEME = 'extractionTheme';

export const USER_TEXT_SIZE_SMALL = 'small';
export const USER_TEXT_SIZE_LARGE = 'large';
export const USER_EXTRACTION_THEME_DARK = 'dark';
export const USER_EXTRACTION_THEME_LIGHT = 'light';
