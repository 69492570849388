import React, { useReducer } from 'react';
import { Provider } from 'react-redux';
import configureStore from 'root/store/configureStore';

import { GlobalStyles } from '@indico-data/permafrost';

import ApolloClient from 'apollo-client';
import { ApolloProvider } from 'react-apollo';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

import UserContext, { UserContextState, UserReducer } from './store/context';

import introspectionQueryResultData from './transformedFragmentTypes.json';

import { AppContainer } from './AppContainer';

const store = configureStore();

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});
const link = ApolloLink.from([
  new RetryLink(),
  new HttpLink({
    uri: `${window.indico.appURLs.FogURL}/api/graphql`,
    credentials: 'include',
  }),
]);
const cache = new InMemoryCache({ fragmentMatcher });

const client = new ApolloClient({
  link,
  cache,
});

export const Root = () => {
  return (
    <>
      <GlobalStyles />
      <Provider store={store}>
        <UserContext.Provider value={useReducer(UserReducer, UserContextState)}>
          <ApolloProvider client={client}>
            <div className="app-root" style={{ height: '100%' }}>
              <AppContainer />
            </div>
          </ApolloProvider>
        </UserContext.Provider>
      </Provider>
    </>
  );
};
