import keyBy from 'lodash/keyBy';

// App
import { fetchUtils } from '@indico-data/utils';

// Elmosfire
import * as endpoints from 'Elmosfire/constants/endpoints';

export const getRowsDataAPI = (id, columnId, rows = []) => {
  const query = `?row_indices=${encodeURIComponent(rows)}&get_datafile_name=true`;
  return fetchUtils
    .getCycloneJSON(`${endpoints.DATASET_ENDPOINT}/${id}/columns/${columnId}/data${query}`)
    .then((json) => {
      const rows = json;
      return keyBy(rows, 'row_index');
    });
};
