import isEqual from 'lodash/isEqual';

import * as types from './action-types';

// App
import { selectCurrentSelectedOverlay } from 'root/reducers/index-reducer';

// Elmosfire
import { clearRowItemLabels } from 'Elmosfire/actions/rows-actions';
import { collectionChangeLabelReset } from 'Elmosfire/actions/selected-documents-actions';
import { resetTSNEColor } from 'Elmosfire/actions/tsne-actions';
import { setFire } from 'Elmosfire/actions/fire-actions';
import * as sorts from 'Elmosfire/constants/sort-directions';
import { getDatasetAPI, getDatasetsAPI } from 'Elmosfire/providers/datasets-provider';
import { getFireGroup } from 'Elmosfire/providers/fire-provider';

export const fetchDatasets = (addToast) => (dispatch) => {
  dispatch({ type: types.TOGGLE_FETCHING_ELMOSFIRE_DATASETS, fetching: true });
  return getDatasetsAPI()
    .then((datasets) => {
      dispatch({ type: types.GET_ELMOSFIRE_DATASETS, datasets });
      dispatch(sortDatasets('Last Modified', sorts.ASCENDING));
      return Promise.resolve(datasets);
    })
    .catch((err) => {
      addToast('There was an error loading your datasets.', 'error');
      return Promise.reject(err);
    });
};

export const fetchDataset = (datasetID, fireGroupId, addToast) => (dispatch) => {
  dispatch({ type: types.TOGGLE_FETCHING_ELMOSFIRE_DATASETS, fetching: true });
  return getDatasetAPI(datasetID)
    .then((dataset) => {
      getFireGroup(fireGroupId).then((fireGroup) => {
        const datasetWithFireGroup = Object.assign({}, dataset, {
          fireGroup,
        });
        dispatch({ type: types.GET_ELMOSFIRE_DATASET, dataset: datasetWithFireGroup });
        dispatch(setFire(datasetWithFireGroup.fireGroup.fires['None']));
        dispatch({ type: types.TOGGLE_FETCHING_ELMOSFIRE_DATASETS, fetching: false });
      });
    })
    .catch(() => {
      addToast('There was an error loading your datasets.', 'error');
      return false;
    });
};

export const updateDatasetName = (datasetID, datasetName) => ({
  type: types.CHANGE_ELMOSFIRE_DATASET_NAME,
  datasetID: datasetID,
  value: datasetName,
});

export const chooseDatasetOverlay = (datasetID, overlayItem) => (dispatch, getState) => {
  const currentOverlay = selectCurrentSelectedOverlay(getState(), datasetID);

  if (!isEqual(overlayItem, currentOverlay)) {
    dispatch(resetTSNEColor());
    dispatch(clearRowItemLabels());
    dispatch({ type: types.SET_OVERLAY, datasetID, overlayItem });
    dispatch(collectionChangeLabelReset());
  }
};

export const clearDatasetOverlay = (datasetId) => ({ type: types.CLEAR_OVERLAY, datasetId });
export const sortDatasets = (header, forceDirection) => ({
  type: types.SORT_ELMOSFIRE_DATASETS,
  header,
  forceDirection,
});
