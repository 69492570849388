import { Map } from 'immutable';

import * as types from 'Crowdlabel/actions/action-types';

export const defaultState = Map({
  questionnaires: Map({}),
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.GET_QUESTIONNAIRE:
      return state.set(
        'questionnaires',
        state.get('questionnaires').merge(Map(action.questionnaire))
      );

    case types.INCREMENT_USER_LABELED_EXAMPLES:
      return state.setIn(
        ['questionnaires', `${action.questionnaireId}`],
        Object.assign({}, state.getIn(['questionnaires', `${action.questionnaireId}`]), {
          num_labeled_by_me:
            state.getIn(['questionnaires', `${action.questionnaireId}`]).num_labeled_by_me +
            action.numberLabeled,
        })
      );

    case types.CHANGE_USER_QUESTION_KEYWORDS_ON_QUESTIONNAIRE:
      return state.setIn(
        ['questionnaires', `${action.questionnaireId}`],
        Object.assign({}, state.getIn(['questionnaires', `${action.questionnaireId}`]), {
          questions: state
            .getIn(['questionnaires', `${action.questionnaireId}`])
            .questions.reduce((prev, cur) => {
              if (cur.id === action.questionId) {
                return [].concat(
                  prev,
                  Object.assign({}, cur, {
                    user_keywords: action.keywords,
                  })
                );
              }
              return [].concat(prev, cur);
            }, []),
        })
      );

    case types.UPDATE_QUESTION_WITH_ADDED_LABEL:
      return state.setIn(
        ['questionnaires', `${action.questionnaireId}`],
        Object.assign({}, state.getIn(['questionnaires', `${action.questionnaireId}`]), {
          questions: state
            .getIn(['questionnaires', `${action.questionnaireId}`])
            .questions.reduce((prev, cur) => {
              if (cur.id === action.question.id) {
                return [].concat(prev, action.question);
              }
              return [].concat(prev, cur);
            }, []),
        })
      );

    default:
      return state;
  }
};

export const getQuestionnaireById = (state, id) => state.getIn(['questionnaires', `${id}`]);
export const getQuestionnaireQuestions = (state, id) => {
  return state.getIn(['questionnaires', `${id}`]).questions;
};
