import { Observable } from 'rxjs/Observable';

// App
import { fetchUtils } from '@indico-data/utils';

// Elmosfire
import * as endpoints from 'Elmosfire/constants/endpoints';

/**
 * Gets the status of a cloud vectorizer
 * @param {int} datasetID - ID of dataset for target fire
 * @param {int} vectorizerID - ID of vectorizer for target fire
 * @returns {observable}
 */
export const getWordCloudStatus = (datasetID, vectorizerID) => {
  return Observable.from(
    fetchUtils
      .getElmosfireJSON(
        `${endpoints.DATASET_ENDPOINT}/${datasetID}/word_cloud/${vectorizerID}/status`
      )
      .then((json) => {
        return json;
      })
      .catch((err) => {
        return Promise.reject(err);
      })
  );
};

/**
 * Fetches the data for populating the word cloud visualization
 * @param {int} datasetID - ID of dataset for target fire
 * @param {int} vectorizerID - ID of vectorizer for target fire
 * @param {int[]} rows - Array of row indices to use in computing word cloud
 * @param {int} topN - Number of words to fetch
 * @returns {promise}
 */
export const getWordCloud = (datasetID, vectorizerID, rows = []) => {
  const query = `?top_n=50&rows=${encodeURIComponent(rows)}`;
  return fetchUtils
    .getElmosfireJSON(
      `${endpoints.DATASET_ENDPOINT}/${datasetID}/word_cloud/${vectorizerID}${query}`
    )
    .then((json) => {
      return json.words;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
