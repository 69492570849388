import { Map } from 'immutable';
import isEqual from 'lodash/isEqual';
import unionBy from 'lodash/unionBy';

import { COLORS } from '@indico-data/permafrost';

// Elmosfire
import * as types from 'Elmosfire/actions/action-types';

const removeLabel = { text: 'Remove Label', color: COLORS.darkGray, removeLabel: true };

export const defaultState = Map({
  hideCursorSwatch: true,
  addLabelDisplayInput: false,
  openMenu: true,
  newLabel: '',
  currentLabels: [removeLabel],
  savingLabels: false,
  displayLabeledConfirmation: false,
  selectedLabel: {},
  currentColorIndex: 0,
  // Commenting out some colors in place to test which
  // label colors work and don't work with the t-SNE
  labelColors: [
    // COLORS.azure,
    // COLORS.orchid,
    COLORS.tangerine,
    COLORS.palm,
    // COLORS.magenta,
    // COLORS.blueRibbon,
    COLORS.darkRed,
    COLORS.amber,
    COLORS.lilac,
    COLORS.pacific,
    COLORS.orange,
    COLORS.carnation,
    COLORS.brown,
    COLORS.khaki,
    // COLORS.zucchini,
    // COLORS.eggplant,
    COLORS.crimson,
    COLORS.cerulean,
    COLORS.harlequin,
    COLORS.akaroa,
    // COLORS.persian,
    COLORS.viking,
    COLORS.mauve,
    // COLORS.espresso,
    COLORS.toast,
    COLORS.salem,
    COLORS.darkPurple,
    COLORS.cornflower,
    COLORS.grannysmith,
    COLORS.lavendar,
  ],
});

const getLabelColorForIndex = (i, colors) => {
  return colors[i % colors.length];
};

const calculateLabelColor = (i, label, colors, filters, highlighted) => {
  if (label.removeLabel) {
    return removeLabel.color;
  }

  const filterColors = filters.map((f) => f.label.color);
  const highlightColors = highlighted.map((h) => h.color);
  const usedColors = [].concat(filterColors, highlightColors);

  // Create new array of usable colors based on already used colors
  const filteredColors = colors.filter((color) => usedColors.indexOf(color) === -1);

  return getLabelColorForIndex(i - 1, filteredColors);
};

const mergeCurrentLabels = (state, labels, filters, highlighted) => {
  return unionBy(
    state.get('currentLabels'),
    Object.keys(labels).map((t) => ({
      text: t,
      notRemovable: true,
    })),
    'text'
  ).map((label, i) => {
    return {
      ...label,
      color: calculateLabelColor(i, label, state.get('labelColors'), filters, highlighted),
    };
  });
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.CHANGE_SELECTED_LABEL:
      return state.set(
        'selectedLabel',
        state.get('currentLabels').filter((o) => o.text === action.label)[0]
      );
    case types.CLEAR_SELECTED_LABEL:
      return state.set('selectedLabel', defaultState.get('selectedLabel'));
    case types.CLEAR_OVERLAY:
    case types.CLEAR_SD_LABELS:
      return state
        .set('currentLabels', defaultState.get('currentLabels'))
        .set('currentColorIndex', defaultState.get('currentColorIndex'))
        .set('labelColors', defaultState.get('labelColors'));
    case types.LOAD_LABELS:
      const mergedCurrentLabels = mergeCurrentLabels(
        state,
        action.labels,
        action.filters,
        action.highlighted
      );
      return state
        .set('currentLabels', mergedCurrentLabels)
        .set('currentColorIndex', mergedCurrentLabels.length - 1);
    case types.CHANGE_NEW_ELMOSFIRE_LABEL:
      return state.set('newLabel', action.value);
    case types.TOGGLE_SAVING_NEW_ELMOSFIRE_LABEL:
      return state.set('savingNewLabel', action.saving);
    case types.TOGGLE_SD_MENU:
      return state.set('openMenu', action.open);
    case types.TOGGLE_ADD_LABEL_DISPLAY_INPUT:
      return state.set('addLabelDisplayInput', action.displayInput);
    case types.TOGGLE_LABELED_CONFIRMATION:
      return state.set('displayLabeledConfirmation', action.display);
    case types.REMOVE_LABEL:
      return state
        .set(
          'currentLabels',
          state.get('currentLabels').reduce((prev, cur) => {
            if (isEqual(action.label, cur)) {
              return prev;
            }
            return [].concat(prev, cur);
          }, [])
        )
        .set(
          'selectedLabel',
          isEqual(action.label, state.get('selectedLabel')) ? {} : state.get('selectedLabel')
        );
    case types.SAVING_SD_LABELS:
      return state.set('savingLabels', action.saving);
    case types.SAVE_NEW_LABEL:
      return state
        .set('currentColorIndex', state.get('currentColorIndex') + 1)
        .set(
          'currentLabels',
          [].concat(state.get('currentLabels'), {
            text: state.get('newLabel'),
            color: getLabelColorForIndex(state.get('currentColorIndex'), state.get('labelColors')),
          })
        )
        .set('newLabel', defaultState.get('newLabel'));
    case types.TOGGLE_HIDE_SWATCH:
      return state.set('hideCursorSwatch', action.hideSwatch);
    case types.REMOVE_COLOR_FROM_LABEL_COLORS:
      return state.set(
        'labelColors',
        state.get('labelColors').filter((c) => c !== action.color)
      );
    default:
      return state;
  }
};

export const getCurrentLabels = (state) => state.get('currentLabels');
export const getNewLabel = (state) => state.get('newLabel');
export const getSavingLabels = (state) => state.get('savingLabels');
export const getSelectedLabel = (state) => state.get('selectedLabel');
export const getMenu = (state) => state.get('openMenu');
export const getHideCursorSwatch = (state) => state.get('hideCursorSwatch');
export const getAddLabelDisplayInput = (state) => state.get('addLabelDisplayInput');
export const getDisplayLabeledConfirmation = (state) => state.get('displayLabeledConfirmation');
