// App
import { fetchUtils } from '@indico-data/utils';

// Elmosfire
import * as endpoints from 'Elmosfire/constants/endpoints';

const convertTargets = (multilabel, labels) => {
  if (multilabel) {
    return labels.map((l) => {
      return l.text ? l.text : '';
    });
  } else {
    return labels[0] ? labels[0].text : '';
  }
};

export const saveLabelsAPI = (datasetId, labelsetId, multilabel, labeledExamples) => {
  const filteredExamples = Object.keys(labeledExamples)
    .filter((key) => labeledExamples[key].dirty || labeledExamples[key].rejected)
    .map((key) => labeledExamples[key])
    .map((example) => {
      let deleted;
      let targets = convertTargets(multilabel, example.labels);

      if (
        (!multilabel && targets === '') ||
        (multilabel && targets.filter((t) => t === '').length > 0)
      ) {
        deleted = true;
      }

      if (deleted) {
        targets = null;
      }

      return {
        deleted,
        target: targets,
        rejected: example.rejected,
        row_index: example.row_index,
      };
    });

  return fetchUtils
    .postCycloneJSON(`${endpoints.DATASET_ENDPOINT}/${datasetId}/labelsets/${labelsetId}/labels`, {
      labels: filteredExamples,
    })
    .then((json) => {
      return json;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
