import { Observable } from 'rxjs/Observable';

// App
import { fetchUtils } from '@indico-data/utils';

// Elmosfire
import * as endpoints from 'Elmosfire/constants/endpoints';

export const postStartOutOfTheBoxPredictions = (datasetID, columnID, ottoboxID, subsetId) => {
  return fetchUtils
    .postMoonbowJSON(
      `${endpoints.DATASET_ENDPOINT}/${datasetID}/column/${columnID}/ottobox/${ottoboxID}/predictions`,
      {
        subset_id: subsetId,
      }
    )
    .then((json) => {
      return json.prediction_labelset_id;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getOverlayLabelCounts = (datasetID, subsetID, labelsetID) => {
  const api = fetchUtils
    .getCycloneJSON(
      `${endpoints.DATASET_ENDPOINT}/${datasetID}/labelsets/${labelsetID}/labels?subset_id=${subsetID}`
    )
    .then((json) => {
      return json;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
  return Observable.from(api);
};
