import { Map } from 'immutable';
import find from 'lodash/find';
import mapValues from 'lodash/mapValues';
import isEqual from 'lodash/isEqual';

// App
import * as types from 'Elmosfire/actions/action-types';

const calculateLabels = (multilabel, label, currentLabels) => {
  if (multilabel) {
    // If label is in current labels - remove it
    if (find(currentLabels, (cLabel) => cLabel.text === label.text)) {
      return currentLabels.reduce((prev, cur) => {
        if (cur.text === label.text) {
          return prev;
        }
        return [].concat(prev, cur);
      }, []);
      // Else add it to existing labels
    } else {
      return [].concat(currentLabels, label);
    }
  } else {
    if (find(currentLabels, (cLabel) => cLabel.text === label.text)) {
      return [];
    } else {
      return [].concat(label);
    }
  }
};

const clearRowLabels = (currentRows) => {
  const clearedRows = {};
  for (const rowKey of Object.keys(currentRows)) {
    clearedRows[rowKey] = Object.assign({}, currentRows[rowKey], { labels: [] });
  }
  return clearedRows;
};

const defaultRow = {
  labels: [],
  rejected: false,
};

export const defaultState = Map({
  fetching: false,
  rows: {},
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.TOGGLE_FETCHING_ROWS:
      return state.set('fetching', action.fetching).set('rows', defaultState.get('rows'));
    case types.CLEAR_ROWS:
      return state.merge(defaultState);
    case types.GET_ROWS:
      return state.set('fetching', false).set(
        'rows',
        mapValues(action.rows, (row) => Object.assign({}, row, defaultRow))
      );
    case types.TOGGLE_REJECT_ELMOSFIRE_EXAMPLE:
      return state.set(
        'rows',
        Object.assign({}, state.get('rows'), {
          [action.rowId]: Object.assign({}, state.get('rows')[action.rowId], {
            rejected: !state.get('rows')[action.rowId].rejected,
          }),
        })
      );
    case types.LABEL_EXAMPLE:
      return state.set(
        'rows',
        Object.assign({}, state.get('rows'), {
          [action.key]: Object.assign({}, state.get('rows')[action.key], {
            labels: calculateLabels(
              action.multilabel,
              action.label,
              state.get('rows')[action.key].labels
            ),
            dirty: action.dirty,
          }),
        })
      );
    case types.CLEAR_OVERLAY:
      return state.set(
        'rows',
        Object.assign({}, state.get('rows'), clearRowLabels(state.get('rows')))
      );
    case types.REMOVE_EXAMPLE_LABELS:
      return state.set(
        'rows',
        Object.assign({}, state.get('rows'), {
          [action.key]: Object.assign({}, state.get('rows')[action.key], defaultRow, {
            dirty: action.dirty,
          }),
        })
      );
    case types.CLEAR_ROW_LABELS:
      return state.set(
        'rows',
        mapValues(state.get('rows'), (row) => Object.assign({}, row, defaultRow))
      );
    case types.REMOVE_LABEL:
      return state.set(
        'rows',
        mapValues(state.get('rows'), (row) => {
          return Object.assign({}, row, {
            labels: row.labels.filter((label) => !isEqual(label, action.label)),
          });
        })
      );
    default:
      return state;
  }
};

export const getRowsFetching = (state) => state.get('fetching');
export const getRows = (state) => state.get('rows');
