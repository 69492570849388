import { Observable } from 'rxjs/Observable';

// Elmosfire
import * as types from 'Elmosfire/actions/action-types';
import { getWordCloudStatus } from 'Elmosfire/providers/wordcloud-provider';
import { resolveWordCloudReady, failWordCloudReady } from 'Elmosfire/actions/wordcloud-actions';
import { POLLING_TIME } from 'Elmosfire/constants/polling-config';

const fireWordCloudEpic = (action$) =>
  action$.ofType(types.GET_WORDCLOUD_READY_POLL_START).switchMap((action) =>
    Observable.timer(0, POLLING_TIME)
      .takeUntil(action$.ofType(types.GET_WORDCLOUD_READY_POLL_STOP))
      .exhaustMap(() =>
        getWordCloudStatus(action.datasetId, action.vectorizerId)
          .map((res) => resolveWordCloudReady(res.status))
          .catch((error) => Observable.of(failWordCloudReady(error)))
      )
  );

export default fireWordCloudEpic;
