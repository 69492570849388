import { Observable } from 'rxjs/Observable';

// Elmosfire
import * as types from 'Elmosfire/actions/action-types';
import { getOverlayLabelCounts } from 'Elmosfire/providers/overlays-provider';
import {
  fetchOverlayLabelCounts,
  failOverlayLabelCounts,
} from 'Elmosfire/actions/overlays-actions';
import { POLLING_TIME } from 'Elmosfire/constants/polling-config';

const overlayLabelCountsEpic = (action$) =>
  action$.ofType(types.GET_OVERLAY_LABEL_COUNT_POLL_START).switchMap((action) =>
    Observable.timer(0, POLLING_TIME)
      .takeUntil(action$.ofType(types.GET_OVERLAY_LABEL_COUNT_POLL_STOP))
      .exhaustMap(() =>
        getOverlayLabelCounts(action.datasetId, action.subsetId, action.labelsetId)
          .map((res) => fetchOverlayLabelCounts(res, action.datasetId))
          .catch((error) => Observable.of(failOverlayLabelCounts(error, action.datasetId)))
      )
  );

export default overlayLabelCountsEpic;
