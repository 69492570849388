import { Map } from 'immutable';

import * as types from 'Elmosfire/actions/action-types';

export const defaultState = Map({
  fireLoading: false,
  fireID: null,
  fireDefinition: {
    id: null,
    dataset_id: null,
    cluster_id: null,
    vectorizer_id: null,
    subset_id: null,
  },
  filters: [],
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.LOADING_FIRE:
      return state.set('fireLoading', action.loading || false);

    case types.SET_FIRE:
      return state.set('fireDefinition', action.fire);

    case types.ADD_FIRE_FILTER:
      return state.set('filters', [...state.get('filters'), action.filter]);

    case types.CLEAR_FIRE_FILTER:
      return state.set(
        'filters',
        state.get('filters').filter((f) => f !== action.filter)
      );

    case types.CLEAR_TSNE_HIGHLIGHTED_AND_FILTERS:
      return state.set('filters', defaultState.get('filters'));

    default:
      return state;
  }
};

export const getFireID = (state) => state.get('fireDefinition').id;
export const getFireLoading = (state) => state.get('fireLoading');
export const getClusterID = (state) => state.get('fireDefinition').cluster_id;
export const getVectorizerID = (state) => state.get('fireDefinition').vectorizer_id;
export const getSubsetID = (state) => state.get('fireDefinition').subset_id;
export const getDatasetID = (state) => state.get('fireDefinition').dataset_id;
export const getDataColumnID = (state) => state.get('fireDefinition').datacolumn_id;
export const getFilters = (state) => state.get('filters');
