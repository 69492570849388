// Base
export const BASE_AUTH = '/auth';
export const BASE_CROWDLABEL = '/teach';
export const BASE_DATASETS = '/datasets';
export const BASE_ELMOSFIRE = '/discover';
export const BASE_MOONBOW = '/explain';
export const BASE_SUNBOW = '/review';
export const BASE_CLOUDBURST = '/workflows';
export const BASE_ADMIN = '/admin';
export const BASE_NO_ACCESS = '/no_access';
export const BASE_ZEPHYR = '/gallery';

// Auth
export const AUTH_CHANGE_PASSWORD = `${BASE_AUTH}/change_password`;
export const AUTH_CONFIRM_ACCOUNT = `${BASE_AUTH}/confirm_account`;
export const AUTH_FORGOT = `${BASE_AUTH}/forgot`;
export const AUTH_REGISTER = `${BASE_AUTH}/register`;
export const AUTH_ACCOUNT = `${BASE_AUTH}/account`;
export const AUTH_LOGOUT = `${BASE_AUTH}/logout`;
export const AUTH_SSO_LOGIN = `${BASE_AUTH}/users/saml/sso`;

// Admin
export const ADMIN_REPORTS = `/reports`;

// Sharknado - "Datasets"
export const DATASETS_DETAILS = `${BASE_DATASETS}/:datasetId/:detail`;
export const DATASET_NEW = `${BASE_DATASETS}/new`;
export const DATASETS_EXAMPLES_LIST = `${BASE_DATASETS}/:datasetId/models/:modelGroupId/examples`;
export const GET_DATASET_TEAM = (datasetId: number) => `${BASE_DATASETS}/${datasetId}/team`;
export const GET_DATASET_FILES = (datasetId: number) => `${BASE_DATASETS}/${datasetId}/files`;
export const GET_DATASET_SETTINGS = (datasetId: number) => `${BASE_DATASETS}/${datasetId}/settings`;
export const GET_DATASET_EXAMPLES = (datasetId: number, modelGroupId: number) =>
  `${BASE_DATASETS}/${datasetId}/models/${modelGroupId}/examples`;

// Elmosfire - "Discover"
export const ELMOSFIRE_NEW = `${BASE_ELMOSFIRE}/new`;
export const ELMOSFIRE_DETAILS = `${BASE_ELMOSFIRE}/:fireGroupId`;
export const ELMOSFIRE_FIREGROUP = `${BASE_ELMOSFIRE}/fire/:datasetId/fire_group/:fireGroupId`;
export const GET_ELMOSFIRE_DETAILS = ({ fireGroupId }: { fireGroupId: number }) =>
  `${BASE_ELMOSFIRE}/${fireGroupId}`;
export const GET_ELMOSFIRE_FIREGROUP = ({
  datasetId,
  fireGroupId,
}: {
  datasetId: number;
  fireGroupId: number;
}) => `${BASE_ELMOSFIRE}/fire/${datasetId}/fire_group/${fireGroupId}`;

// Crowdlabel - "Teach"
export const CROWDLABEL_TASKS_NEW = `${BASE_CROWDLABEL}/new`;
export const CROWDLABEL_TASKS_DETAILS = `${BASE_CROWDLABEL}/:questionnaireId`;
export const CROWDLABEL_LABELING = `${BASE_CROWDLABEL}/labeling/:questionnaireId`;
export const CROWDLABEL_DOC_LABELING = `${BASE_CROWDLABEL}/doc-labeling/:questionnaireId`;
export const CROWDLABEL_LABELING_SINGLE = `${BASE_CROWDLABEL}/example/:modelId/:rowIndex`;
export const GET_CROWDLABEL_DETAILS = ({ questionnaireId }: { questionnaireId: number }) =>
  `${BASE_CROWDLABEL}/${questionnaireId}`;
export const GET_CROWDLABEL_LABELING = ({ questionnaireId }: { questionnaireId: number }) =>
  `${BASE_CROWDLABEL}/labeling/${questionnaireId}`;
export const GET_CROWDLABEL_DOC_LABELING = ({ questionnaireId }: { questionnaireId: number }) =>
  `${BASE_CROWDLABEL}/doc-labeling/${questionnaireId}`;
export const GET_CROWDLABEL_LABELING_SINGLE = ({
  modelId,
  rowIndex,
}: {
  modelId: number;
  rowIndex: number;
}) => `${BASE_CROWDLABEL}/example/${modelId}/${rowIndex}`;

// Moonbow - "Explain"
export const MOONBOW_NEW = `${BASE_MOONBOW}/datasets/:datasetId/models/new`;
export const MOONBOW_DETAILS = `${BASE_MOONBOW}/datasets/:datasetId/models/:modelGroupId/:detail`;
export const GET_MOONBOW_NEW = ({ datasetId }: { datasetId: number }) =>
  `${BASE_MOONBOW}/datasets/${datasetId}/models/new`;
export const GET_MOONBOW_DETAILS = (
  { datasetId, modelGroupId, detail }: { datasetId: number; modelGroupId: number; detail: string } //enum
) => `${BASE_MOONBOW}/datasets/${datasetId}/models/${modelGroupId}/${detail}`;
export const GET_MOONBOW_PERFORMANCE = ({
  datasetId,
  modelGroupId,
}: {
  datasetId: number;
  modelGroupId: number;
}) => `${BASE_MOONBOW}/datasets/${datasetId}/models/${modelGroupId}/performance`;
export const GET_MOONBOW_BALANCE = ({
  datasetId,
  modelGroupId,
}: {
  datasetId: number;
  modelGroupId: number;
}) => `${BASE_MOONBOW}/datasets/${datasetId}/models/${modelGroupId}/balance`;
export const GET_MOONBOW_TRAINING = ({
  datasetId,
  modelGroupId,
}: {
  datasetId: number;
  modelGroupId: number;
}) => `${BASE_MOONBOW}/datasets/${datasetId}/models/${modelGroupId}/training`;

// Sunbow - "Review"
export const SUNBOW_QUEUE = `${BASE_SUNBOW}/queues/:workflowId`;

export const SINGLE_SUNBOW_QUEUE = `${BASE_SUNBOW}/queues/:workflowId/submission/:submissionId`;

export const SINGLE_SUNBOW_QUEUE_SUCCESS = `${BASE_SUNBOW}/queues/:workflowId/submission/:submissionId/success`;
export const EXCEPTIONS_QUEUE = `${BASE_SUNBOW}/exceptions/:workflowId`;
export const GET_SUNBOW_QUEUE = ({ workflowId }: { workflowId: number }) =>
  `${BASE_SUNBOW}/queues/${workflowId}`;
export const GET_SINGLE_SUNBOW_QUEUE = ({
  workflowId,
  submissionId,
}: {
  workflowId: number;
  submissionId: number;
}) => `${BASE_SUNBOW}/queues/${workflowId}/submission/${submissionId}`;
export const GET_SINGLE_SUNBOW_QUEUE_SUCCESS = ({
  workflowId,
  submissionId,
}: {
  workflowId: number;
  submissionId: number;
}) => `${BASE_SUNBOW}/queues/${workflowId}/submission/${submissionId}/success`;
export const GET_EXCEPTIONS_QUEUE = ({ workflowId }: { workflowId: number }) =>
  `${BASE_SUNBOW}/exceptions/${workflowId}`;

// Cloudburst - "Workflows"
export const WORKFLOW_DETAILS = `${BASE_CLOUDBURST}/:workflowId/:detail`;
export const GET_WORKFLOW_CANVAS = ({ workflowId }: { workflowId: number }) =>
  `${BASE_CLOUDBURST}/${workflowId}/canvas`;
export const GET_WORKFLOW_METRICS = ({ workflowId }: { workflowId: number }) =>
  `${BASE_CLOUDBURST}/${workflowId}/analytics`;

export const GET_WORKFLOW_SETTINGS = ({ workflowId }: { workflowId: number }) =>
  `${BASE_CLOUDBURST}/${workflowId}/settings`;
