import isNull from 'lodash/isNull';
import find from 'lodash/find';

// App
import { toast } from 'root/actions/app-actions';
import {
  selectCurrentSelectedOverlay,
  selectOverlayCountsLabels,
  selectSDCurrentLabels,
  selectFireSubsetID,
  selectTSNESelectedRows,
  selectSDSelectedLabel,
  selectFireFilters,
  selectOverlayCountsHighlighted,
  // selectFireGroupById,
} from 'root/reducers/index-reducer';

// Elmosfire
import * as types from 'Elmosfire/actions/action-types';
import { applyOverlayRowItemLabels } from 'Elmosfire/actions/rows-actions';
import { clearDatasetOverlay } from 'Elmosfire/actions/datasets-actions';
import * as OVERLAY_STATUS from 'Elmosfire/constants/overlay-status';
import * as OVERLAY_TYPES from 'Elmosfire/constants/overlay-types';
import { getModelGroupsAPI, putRetrainModel } from 'Elmosfire/providers/modelgroups-provider';

export const formatModelOverlay = (mg) => {
  return {
    name: mg.name,
    id: mg.labelset_column_id,
    modelId: mg.id,
    subsetId: mg.subset_id,
    predictionLabelsetId: mg.selected_model.prediction_labelset_id,
    meta: `Collection: ${mg.multilabel ? 'Multiple Labels' : 'Single Label'}`,
    multilabel: mg.multilabel,
    type: OVERLAY_TYPES.LOCAL,
  };
};

export const fetchModelGroups = (datasetID) => (dispatch, getState) => {
  dispatch({ type: types.TOGGLE_FETCHING_MODEL_GROUPS, datasetID, fetching: true });
  return getModelGroupsAPI(datasetID, selectFireSubsetID(getState()))
    .then((modelGroups) => {
      dispatch({ type: types.GET_MODEL_GROUPS, datasetID, modelGroups });
      dispatch({ type: types.TOGGLE_FETCHING_MODEL_GROUPS, datasetID, fetching: false });
    })
    .catch(() => {
      dispatch(toast('There was an error loading your overlays.', 'error'));
      return false;
    });
};

export const changeInputFilter = (value) => ({ type: types.CHANGE_OVERLAY_INPUT_FILTER, value });

/** Model Group Retrain Flow **/

export const retrainModelGroup = (datasetID, modelID, subsetID) => (dispatch) => {
  return putRetrainModel(datasetID, modelID).then(() => {
    dispatch({ type: types.RETRAIN_MODEL_GROUP_POLL_START, datasetID, modelID, subsetID }); // Poll for retrain status
    dispatch({ type: types.TOGGLE_RETRAINING, retraining: true });
  });
};

export const completeRetrainModelGroup = (modelGroups, datasetID, modelID) => (dispatch) => {
  const status = modelGroups[modelID].models.reduce(
    (prev, cur) => (cur.id > prev.id ? cur : prev),
    { id: -1 }
  ).status;

  if (status !== OVERLAY_STATUS.CREATING && status !== 'training') {
    dispatch({ type: types.RETRAIN_MODEL_GROUP_POLL_STOP });
    dispatch({ type: types.CAN_RETRAIN, canRetrain: false, selectedOverlayId: modelID });
    dispatch({ type: types.GET_MODEL_GROUPS, datasetID, modelGroups });
    dispatch({
      type: types.SET_OVERLAY,
      datasetID,
      overlayItem: formatModelOverlay(modelGroups[modelID]),
    });
    dispatch({ type: types.TOGGLE_RETRAINING, retraining: false });
    dispatch({ type: types.RESET_RETRAIN_PROGRESS });
    dispatch(toast('Your overlay is done retraining!', 'success', 5000));
  } else {
    dispatch({ type: types.INCREMENT_RETRAIN_PROGRESS });
  }
};

export const incrementProgress = () => ({ type: types.INCREMENT_RETRAIN_PROGRESS });

export const failRetrainModelGroup = () => (dispatch) => {
  dispatch({ type: types.RETRAIN_MODEL_GROUP_POLL_STOP });
  dispatch(toast('Overlay Model Retrain failed.', 'error')); // Notify the user
};

export const cancelRetrainModelPolling = () => ({ type: types.RETRAIN_MODEL_GROUP_POLL_STOP });

/** Label Counts **/

export const pollLabelCounts = (datasetId, labelsetId) => (dispatch, getState) => {
  dispatch({
    type: types.GET_OVERLAY_LABEL_COUNT_POLL_START,
    datasetId,
    labelsetId,
    subsetId: selectFireSubsetID(getState()),
  });
  dispatch({ type: types.CLEAR_SELECTED_LABEL });
};

export const fetchOverlayLabelCounts = (labelCounts, datasetId) => (dispatch, getState) => {
  if (labelCounts.status === OVERLAY_STATUS.COMPLETE) {
    dispatch({ type: types.GET_OVERLAY_LABEL_COUNT_POLL_STOP });
    dispatch({ type: types.GET_OVERLAY_LABEL_COUNTS, labelCounts: labelCounts.counts });
    dispatch({ type: types.ADD_TSNE_OVERLAY_DATA, labelData: labelCounts.labels });
    dispatch(loadLabels(labelCounts));
    dispatch(applyOverlayRowItemLabels(datasetId, selectTSNESelectedRows(getState())));

    dispatch(activateDefaultLabelSelection());

    if (selectCurrentSelectedOverlay(getState(), datasetId).type !== OVERLAY_TYPES.BUILT_IN) {
      dispatch(colorTSNEBasedOnCollection(labelCounts, datasetId));
    }
  }
};

export const failOverlayLabelCounts = (error, datasetId) => (dispatch) => {
  dispatch({ type: types.GET_OVERLAY_LABEL_COUNT_POLL_STOP }); // Stop Polling
  dispatch(clearDatasetOverlay(datasetId)); // Clear the selected overlay
  dispatch(toast('There was an error getting the label count for this overlay', 'error')); // Notify the user
};

export const cancelOverlayLabelCountsPolling = () => ({
  type: types.GET_OVERLAY_LABEL_COUNT_POLL_STOP,
});

/** Predicted Label Counts **/

export const pollPredictedLabelCounts = (datasetId, labelsetId) => (dispatch, getState) => {
  if (isNull(labelsetId)) return;
  dispatch({
    type: types.GET_OVERLAY_PREDICTED_LABEL_COUNT_POLL_START,
    datasetId,
    subsetId: selectFireSubsetID(getState()),
    labelsetId,
  });
  dispatch({ type: types.CLEAR_SELECTED_LABEL });
};

export const fetchOverlayPredictedLabelCounts = (labelCounts, datasetId) => (dispatch) => {
  if (
    labelCounts.status === OVERLAY_STATUS.CREATING_LABELS ||
    labelCounts.status === OVERLAY_STATUS.COMPLETE
  ) {
    dispatch({
      type: types.GET_OVERLAY_PREDICTED_LABEL_COUNTS,
      labelCounts: labelCounts.counts,
    });
    dispatch(loadLabels(labelCounts));
    dispatch(colorTSNEBasedOnCollection(labelCounts, datasetId));
  }

  dispatch(activateDefaultLabelSelection());

  if (labelCounts.status === OVERLAY_STATUS.COMPLETE) {
    dispatch({ type: types.GET_OVERLAY_PREDICTED_LABEL_COUNT_POLL_STOP });
  }
};

export const failPredictedOverlayLabelCounts = (error, datasetId) => (dispatch) => {
  dispatch({ type: types.GET_OVERLAY_PREDICTED_LABEL_COUNT_POLL_STOP }); // Stop Polling
  dispatch(clearDatasetOverlay(datasetId)); // Clear the selected overlay
  dispatch(toast('There was an error getting the predicted label count for this overlay', 'error')); // Notify the user
};

export const cancelPredictedLabelCountsPolling = () => ({
  type: types.GET_OVERLAY_PREDICTED_LABEL_COUNT_POLL_STOP,
});

export const activateDefaultLabelSelection = () => (dispatch, getState) => {
  if (!selectSDSelectedLabel(getState()).hasOwnProperty('text')) {
    const currentLabels = selectSDCurrentLabels(getState());
    if (currentLabels.length > 1) {
      dispatch({ type: types.CHANGE_SELECTED_LABEL, label: currentLabels[1].text });
    }
  }
};

export const updateVizHighlightLabel = (label) => (dispatch, getState) => {
  const overlayLabels = selectOverlayCountsLabels(getState());
  dispatch({ type: types.ADD_LABEL_HIGHLIGHT, label: find(overlayLabels, { text: label }) });
};

export const updateVizRemoveHighlightLabel = (label) => ({
  type: types.REMOVE_LABEL_HIGHLIGHT,
  label: label.text,
});

/** TSNE UPDATES **/

export const callTSNEDefaultColors = (datasetId) => (dispatch, getState) => {
  const columnName = selectCurrentSelectedOverlay(getState(), datasetId).name;
  dispatch({ type: types.COLOR_TSNE_BASED_ON_COLLECTION, labelData: {}, columnName });
};

export const colorTSNEBasedOnCollection = (labelCounts, datasetId) => (dispatch, getState) => {
  const columnName = selectCurrentSelectedOverlay(getState(), datasetId).name;
  dispatch({
    type: types.COLOR_TSNE_BASED_ON_COLLECTION,
    labelData: labelCounts.labels,
    columnName,
  });
};

export const loadLabels = (labelCounts) => (dispatch, getState) => {
  dispatch({
    type: types.LOAD_LABELS,
    labels: labelCounts.counts,
    filters: selectFireFilters(getState()),
    highlighted: selectOverlayCountsHighlighted(getState()),
  });
  dispatch({
    type: types.INITIALIZE_TSNE_MENU,
    labels: selectSDCurrentLabels(getState()).filter((l, i) => i !== 0),
  }); // Filters out the 'Remove Label'
};
