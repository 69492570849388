import { AuthorizedUser } from 'Auth/types';
// App Deps
import * as StorageAPI from 'root/providers/storage-provider';
import { STORAGE_VALUE_EMAIL } from 'root/constants/app';
import { selectAuthLoginModel, selectXSRFToken } from 'root/reducers/index-reducer';

// Auth Deps
import * as types from './action-types';
import * as ERROR_CODES from 'Auth/constants/error-codes';
import * as MESSAGES from 'Auth/constants/messages';
import { postUserLoginAPI, userLogoutAPI, verifyUserAPI } from 'Auth/providers/auth-provider';
import { Dispatch } from 'redux';
import { AddToast } from 'root/components/messaging/ToastsList/ToastsList';

export const verifyUser = () => (dispatch: Dispatch) => {
  dispatch({ type: types.CHECK_CURRENT_USER });
  return verifyUserAPI()
    .then((user: AuthorizedUser) => {
      dispatch({ type: types.RESOLVE_CURRENT_USER, user });
      return user;
    })
    .catch((err: any) => {
      dispatch({ type: types.CLEAR_CURRENT_USER });
      return Promise.reject(err);
    });
};

export const changeUserEmail = (e: React.ChangeEvent<HTMLInputElement>) => ({
  type: types.CHANGE_LOGIN_EMAIL,
  value: e.target.value,
});

export const changeUserPassword = (e: React.ChangeEvent<HTMLInputElement>) => ({
  type: types.CHANGE_LOGIN_PASSWORD,
  value: e.target.value,
});

export const toggleRememberMe = () => ({ type: types.TOGGLE_LOGIN_REMEMBER_ME });

export const loadSavedUserDetails = () => (dispatch: Dispatch) => {
  StorageAPI.retrieveValue(STORAGE_VALUE_EMAIL).then(
    (userEmail) => {
      dispatch({ type: types.LOAD_SAVED_USER_DETAILS, userEmail });
    },
    () => {
      dispatch({ type: types.LOAD_SAVED_USER_DETAILS, userEmail: '' });
    }
  );
};

export const authenticateUser = (
  email: string,
  password: string,
  rememberMe: boolean,
  addToast: AddToast,
  captchaToken?: string
) => (dispatch: any, getState: any) => {
  dispatch({ type: types.ATTEMPT_LOGIN });

  if (rememberMe) {
    StorageAPI.storeValue(STORAGE_VALUE_EMAIL, email);
  } else {
    StorageAPI.clearValue(STORAGE_VALUE_EMAIL);
  }
  const authModel = selectAuthLoginModel(getState());
  const xsrfToken = selectXSRFToken(getState());

  return postUserLoginAPI(authModel, xsrfToken, captchaToken)
    .then((user: AuthorizedUser) => {
      dispatch({ type: types.RESOLVE_LOGIN, user });
      addToast(MESSAGES.LOGIN_SUCCESS, 'success');
      return dispatch(verifyUser());
    })
    .catch((err: any) => {
      dispatch({ type: types.REJECT_LOGIN });
      if (err.error_type === ERROR_CODES.LOGIN_REQUIRED) {
        addToast(MESSAGES.LOGIN_FAILED, 'error');
      } else if (err.error_type === ERROR_CODES.ACCOUNT_LOCKED) {
        addToast(MESSAGES.ACCOUNT_LOCKED, 'error');
      } else if (err.error_type === ERROR_CODES.SESSION_LIMIT) {
        addToast(MESSAGES.SESSION_LIMIT, 'error');
      } else if (err.error_type === ERROR_CODES.EXPIRED_ACCOUNT) {
        dispatch({ type: types.EXPIRED_ERROR, expired: true });
      } else {
        if (err.error_type === ERROR_CODES.CONFIRMATION_REQUIRED) {
          addToast(MESSAGES.LOGIN_ERROR_CONFIRMATION_REQUIRED, 'error');
        } else {
          addToast(MESSAGES.LOGIN_ERROR, 'error');
        }
      }
    });
};

export const logoutUser = (addToast: AddToast) => (dispatch: Dispatch, getState: () => {}) => {
  dispatch({ type: types.ATTEMPT_LOGOUT });
  const xsrfToken = selectXSRFToken(getState());

  return userLogoutAPI(xsrfToken)
    .then(() => {
      dispatch({ type: types.RESOLVE_LOGOUT });
      addToast(MESSAGES.LOGOUT_SUCCESS, 'success');
    })
    .catch(() => {
      dispatch({ type: types.REJECT_LOGOUT });
      addToast(MESSAGES.LOGOUT_FAILED, 'error');
    });
};
