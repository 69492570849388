// App
import { toast } from 'root/actions/app-actions';
import { selectWordCloudReady } from 'root/reducers/index-reducer';

// Elmosfire
import * as types from 'Elmosfire/actions/action-types';
import * as WORDCLOUD_STATUS from 'Elmosfire/constants/wordcloud-status';
import { getWordCloud } from 'Elmosfire/providers/wordcloud-provider';

/**** Wordcloud Status Polling and Data Fetching  Actions****/

export const pollWordCloudReady = (datasetId, vectorizerId) => (dispatch) => {
  dispatch(cancelWordCloudReadyPolling());
  dispatch({ type: types.SET_WORDCLOUD_READY, ready: false });
  dispatch({ type: types.GET_WORDCLOUD_READY_POLL_START, datasetId, vectorizerId });
};

export const cancelWordCloudReadyPolling = () => ({ type: types.GET_WORDCLOUD_READY_POLL_STOP });

export const resolveWordCloudReady = (status) => (dispatch) => {
  if (status === WORDCLOUD_STATUS.COMPLETE) {
    dispatch(cancelWordCloudReadyPolling());
    dispatch({ type: types.SET_WORDCLOUD_READY, ready: true });
  } else if (status === WORDCLOUD_STATUS.FAILED) {
    dispatch(cancelWordCloudReadyPolling());
  }
  return dispatch({ type: types.SET_WORDCLOUD_STATUS, status });
};

export const failWordCloudReady = () => (dispatch) => {
  dispatch(cancelWordCloudReadyPolling());
  dispatch(
    toast('There was an error loading the word cloud vectorizer. Please try again.', 'error')
  ); // Notify the user
};

export const fetchWordCloud = (datasetId, vectorizerId, rows = []) => (dispatch, getState) => {
  if (!selectWordCloudReady(getState())) {
    return dispatch(toast('Word cloud not yet ready.', 'error'));
  }

  dispatch({ type: types.TOGGLE_LOADING_WORDCLOUD, loading: true });
  return getWordCloud(datasetId, vectorizerId, rows)
    .then((words) => {
      dispatch({
        type: types.SET_WORD_CLOUD,
        id: datasetId,
        words,
      });
    })
    .catch(() => {
      dispatch({ type: types.TOGGLE_LOADING_WORDCLOUD, loading: false });
      dispatch(toast('There was an error loading the word cloud.', 'error')); // Notify the user
    });
};
