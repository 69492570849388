import * as types from './action-types';
import {
  getUserIndicoAppSettingsAPI,
  saveUserSidebarOpenSettingAPI,
  getXSRFTokenAPI,
} from 'root/providers/app-provider';
import { selectAppSidebarOpen } from 'root/reducers/index-reducer';
import { TOAST_TIMEOUT } from 'root/constants/app';

export const toast = (message, style, time = TOAST_TIMEOUT) => (dispatch) => {
  dispatch({ type: types.DISPLAY_TOAST, message, style });
  setTimeout(() => dispatch({ type: types.HIDE_TOAST }), time);
};

export const loadIndicoAppSettings = () => (dispatch) => {
  return getUserIndicoAppSettingsAPI().then((settings) => {
    dispatch({ type: types.LOAD_INDICO_APP_SETTINGS, settings });
  });
};

export const toggleSidebar = () => (dispatch) => {
  dispatch({ type: types.TOGGLE_SIDEBAR });
  dispatch(saveUserSidebarOpenSetting());
};

export const saveUserSidebarOpenSetting = () => (dispatch, getState) => {
  return saveUserSidebarOpenSettingAPI(selectAppSidebarOpen(getState())).catch(() => {
    return false;
  });
};

export const getXSRFToken = () => (dispatch) => {
  return getXSRFTokenAPI().then((token) => {
    dispatch({ type: types.SET_XSRF_TOKEN, token });
  });
};
